import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { PublicInvoiceModel } from '@/models';

export const fetchPublicInvoiceByIdEpic: PayloadEpic<EntityId, PublicInvoiceModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.publicInvoices.fetchInvoiceById.match),
        switchMap(({ payload }) => concat(
            of(actions.publicInvoices.setIsLoadingInvoice(true)),
            fetchPublicInvoiceById(payload).pipe(
                map(({ data }) => actions.publicInvoices.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchPublicInvoiceByIdEpic', error);
                    return of(actions.publicInvoices.setLoadInvoiceErrors(error));
                }),
            ),
            of(actions.publicInvoices.setIsLoadingInvoice(false)),
        )),
    );
};

const fetchPublicInvoiceById = (invoiceId: EntityId) => {
    return axios.get<PublicInvoiceModel>(apiEndpoints.publicInvoice(invoiceId));
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { AssembleAssetPayload, AssembleAssetPlan } from '@/store/slices/payload-types/warehouse-payload-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ASSET_RESOURCE_TYPES } from '@/shared/constants/enums/AssetResourceTypes';

export const assembleAssetEpic: PayloadEpic<AssembleAssetPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.assembleAsset.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouses.setIsSaving(true)),
                assembleAsset(payload).pipe(
                    switchMap(() => [actions.warehouses.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('AssembleAssetEpic', error?.response?.data);

                        return of(actions.warehouses.setSavingErrors(error?.response?.data || {}));
                    })
                ),
                of(actions.warehouses.setIsSaving(false))
            )
        )
    );
};

const assembleAsset = ({ facilityUid, plan, ...payload }: AssembleAssetPayload) => {
    const assembleAssetEndpoint = payload.planType === ASSET_RESOURCE_TYPES.meal ? apiEndpoints.assemblePlanMeal : apiEndpoints.assemblePlanPackage;

    return axios.post<unknown>(assembleAssetEndpoint(facilityUid), {
        ...payload,
        plan: getPlanPayload(plan),
    });
};

const getPlanPayload = (plan: AssembleAssetPlan[]) => {
    const result = {};

    for (const record of plan) {
        result[record.asset] = result[record.asset] ?? {};
        result[record.asset][record.asset_lot] = { units: record.units };
    }

    return result;
};

//// HERE WE ARE

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { RouteStopModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';

export const deleteRouteStopEpic: PayloadEpic<EntityId, RouteStopModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.routeStops.deleteRouteStop.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.routeStops.setIsSaving(true)),
                deleteRouteStop(payload.routeStopId).pipe(
                    map(() => actions.routeStops.removeRouteStop(payload.routeStopId)),
                    catchError((error) => {
                        console.error('deleteRouteStopEpic', error);

                        return of(actions.routeStops.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.routeStops.setIsSaving(false))
            )
        )
    );
};

const deleteRouteStop = (routeStopId: EntityId) => {
    return axios.delete<void>(apiEndpoints.routeStop(routeStopId));
};

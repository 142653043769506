import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetLotModel } from '@/models';
import { MarkAssetLotReceivedPayload } from '@/store/slices/payload-types/asset-payload-types';
import { objectToFormData } from '@/shared/utils/objectToFormData';

export const markAssetLotReceivedEpic: PayloadEpic<MarkAssetLotReceivedPayload, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.markAssetLotReceived.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.assetLots.setIsSaving(true)),
                markAssetLotReceived(payload).pipe(
                    switchMap(({ data }) => [actions.assetLots.upsertOne(data), actions.assetLots.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('markAssetLotReceivedEpic', error);

                        return of(actions.assetLots.setSavingErrors(error));
                    })
                ),
                of(actions.assetLots.setIsSaving(false))
            )
        )
    );
};

const markAssetLotReceived = (payload: MarkAssetLotReceivedPayload) => {
    const formData = objectToFormData({
        ...payload,
        expiration_date: payload.expiration_date?.toISOString()?.split('T')[0],
        last_sell_date: payload.last_sell_date?.toISOString()?.split('T')[0],
        last_ship_date: payload.last_ship_date?.toISOString()?.split('T')[0],
    });
    return axios.post<AssetLotModel>(apiEndpoints.markAssetLotReceived(payload.assetLotUid), formData);
};

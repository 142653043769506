import duckBuilder from './duck'
import { useSelector, useDispatch } from 'react-redux'
import useFetch from 'use-http'
import React from 'react'

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const DEFAULT_TARGET_STATUS = { ready: false, loading: false, loaded: false }

export const DEFAULT_TARGET = {_status: DEFAULT_TARGET_STATUS}

export const DEFAULT_STATE = { '_': DEFAULT_TARGET }

/* APIDuck allows you to manage useFetch() lifecycle state in a redux duck

Each API Request lives under a "target" name, which is like a cache key for the API call

If you don't sepcify a target, APIDuck will use a shared target (named "_") for all calls

For example, if we made a userDuck based on apiDuck, we could use the duck to:
    + userDuck.get('/users/friends', {_target: 'friends'}) 
    + userDuck.get('/users/my_profile', {_target: 'profile'})
    + userDuck.post('/users/update_profile', {_target: 'profile'}) -- will update the 'profile' target cache with the response
    + (pk) => userDuck.post(`/users/${pk}`, {_target: pk}) -- use the duck to store many User results, each in a seperate cache

    
Each target has it's own `_status` key, which contains ready/loading/loaded indicators
*/
export const apiDuck = (name, BASE, ducklings, _defaultState) => {

    const duck = duckBuilder(
        name,
        {
            LOADING: (state, action) => apiTargetReducer(state, action, {loading: true, error: false}),
            READY: (state, action) => apiTargetReducer(state, action, {ready: true, loading: false, loaded: true, error: false}, action.data),
            ERROR: (state, action) => apiTargetReducer(state, action, {ready: false, loading: false, loaded: true, error: true}, {error: action.data}),
            RESET: () => ({...DEFAULT_STATE, ..._defaultState}),
            ...ducklings,
        },
        {...DEFAULT_STATE, ..._defaultState}
    )

    function parseFetchArgs(...args) {
        let url, options, dependencies
        const last = args[args.length - 1]
        options = {}
        if (args.length === 3) [url, options, dependencies] = args
        else if (Array.isArray(last)) {
            if (args.length === 2) [url, dependencies] = args
            if (args.length === 1) dependencies = args
        } else {
            if (args.length === 2) [url, options] = args
            if (args.length === 1) url = args
        }
        return [url, options, dependencies]
    }

    function useAPI(...args) {
        let [url, options, dependencies] = parseFetchArgs(...args)

        url = (BASE || '') + (url || '')

        // Default to Content-Type: application/json unless formData option parameter
        // is provided. When formData is true, we will let the browser to set the Content-Type header.
        if (!options?.formData) {
            options.headers = {
                ...options.headers,
                'Content-Type': 'application/json',
            }
        }

        options.cachePolicy = options.cachePolicy || 'no-cache'
        const _target = options.target = options.target || '_'

        function useResponse(selector) {
            return useAPITargetSelector(options.target, selector)
        }

        const dispatch = useDispatch()
        const fetch = useFetch(url, options)

        const status = useResponse((responseState) => responseState?._status || {})

        const formatResponse = response => {
            return Array.isArray(response)
                ? { results: response }
                : response
        }

        const get = async (...args) => {
            console.info('API [GET] :', name, ...args)
            dispatch(duck.actions.LOADING({_target}))
            try {
                const response = await fetch.get(...args)
                if (fetch.response.status === 200) {
                    const action = duck.actions.READY({...formatResponse(response), _target})
                    console.log('API[REPLY]:', name, action)
                    dispatch(action)
                    return response
                } else {
                    dispatch(duck.actions.ERROR({code: fetch.response.status, response, _target}))
                }
            } catch (e) {
                dispatch(duck.actions.ERROR({_target}))
            }
            if (options.artificialDelay) await sleep(options.artificialDelay)
        }

        const put = async (...args) => {
            console.info('API [PUT]:', ...args)
            dispatch(duck.actions.LOADING({_target}))
            const response = await fetch.put(...args)
            if (response) {
                const action = duck.actions.READY({...formatResponse(response), _target})
                console.log('API[REPLY]:', name, action)
                dispatch(action)
            }
            return response
        }

        const post = async (...args) => {
            console.info('API [POST]:', ...args)
            dispatch(duck.actions.LOADING({_target}))
            const response = await fetch.post(...args)
            if (response) {
                const action = duck.actions.READY({...formatResponse(response), _target})
                console.log('API[REPLY]:', name, action)
                dispatch(action)
            }
            return response
        }

        const patch = async (...args) => {
            console.info('API [PATCH]:', ...args)
            dispatch(duck.actions.LOADING({_target}))
            const response = await fetch.patch(...args)
            if (response) {
                const action = duck.actions.READY({...formatResponse(response), _target})
                console.log('API[REPLY]:', name, action)
                dispatch(action)
            }
            return response
        }

        const _delete = async (...args) => {
            console.info('API [DELETE]:', ...args)
            dispatch(duck.actions.LOADING({_target}))
            const response = await fetch.delete(...args)
            const action = duck.actions.READY({...formatResponse(response), _target})
            console.log('API[REPLY]:', name, action)
            dispatch(action)
            return response
        }

        /* trigger GET if dependencies is specified */
        /* we add a small random delay to prevent race conditions */
        React.useEffect(() => {
            if (typeof dependencies !== 'undefined' && !status.loading && !status.loaded) {
                console.log(
                    'First time loading this component, dependencies say to automatically GET', status
                )
                get()
            }
        // eslint-disable-next-line
        }, [dependencies, status.loading, status.ready, status.loaded])

        return { dispatch, fetch, get, post, put, patch, delete: _delete, useResponse, status }
    }

    const useAPITargetSelector = (target, selector) => {
        return useSelector(() => {
            const targetState = duck.useDuckSelector((s) => s[target])
            if (selector) return selector(targetState)
            return targetState
        })
    }

    const apiTargetReducer = (state, action, newStatus, newValue) => {
        const target = action.data?._target || '_'
        const oldStatus = state[target]?._status

        if(typeof(newValue) === 'undefined')
            newValue = state[target]

        const newState = { ...newValue, _status: {...oldStatus, ...newStatus}}

        return {
            ...state,
            [target]: newState,
        }
    }

    return { ...duck, useAPI, parseFetchArgs }
}
export default apiDuck

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { FetchManufacturersPayload } from '@/store/slices/payload-types/asset-payload-types';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { ManufacturerModel } from '@/models/manufacturer.model';

export const fetchAllManufacturersEpic: PayloadEpic<FetchManufacturersPayload, ManufacturerModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.manufacturers.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.manufacturers.setIsLoading(true)),
                fetchManufacturers(payload).pipe(
                    switchMap(({ data }) => of(actions.manufacturers.setAll(data.results))),
                    catchError((error) => {
                        console.error('fetchAllManufacturersEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.manufacturers.setIsLoading(false))
            )
        )
    );
};

const fetchManufacturers = (payload: FetchManufacturersPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<ManufacturerModel>>(`${apiEndpoints.manufacturers()}?${searchParams}`);
};

import React from 'react'
import { viewsetDuck } from '@/shared/ducks/viewsetDuck'

export const DEFAULT_STATE = {}

export const duck = viewsetDuck('staffProducts', '/mgmt/api/products/', {
    TOGGLE_PUBLISHED: (state, action) => {
        const newState = {...state}

        const i = newState.list.results.findIndex(p => p.uid === action.data.product.uid);
        newState.list.results[i].published = !newState.list.results[i].published

        return newState;
    },
    TOGGLE_INTERNAL_ONLY: (state, action) => {
        const newState = {...state}

        const item = newState.list.results.find(p => p.uid === action.data.product.uid);
        item.internal_only = !item.internal_only;

        return newState;
    },
})

export const useProduct = uid => {
    return duck.useObject(uid, '', { suspense: false })
}

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

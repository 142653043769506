import { filter, map, switchMap, tap } from 'rxjs/operators';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { LogoutPayload } from '@/store/slices/public/profile.slice';
import { ProfileModel } from '@/models';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';

export const logoutUserEpic: PayloadEpic<LogoutPayload, ProfileModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.profile.logout.match),
        map(({ payload }) => payload ?? {} as LogoutPayload),
        switchMap(
            ({ redirectUrl = '/' }) => logoutUserRequest().pipe(
                map(() => actions.profile.setData(null)),
                // todo: get rid of this later
                tap(() => window.location = redirectUrl as any),
            ),
        ),
    );
};

const logoutUserRequest = () => {
    return axios.post<LogoutPayload>(apiEndpoints.logout());
};

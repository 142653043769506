export const objectToFormData = (data) => {
    const formData = new FormData();

    for (const [key, value] of getIteratorOverObject(data)) {
        formData.append(key, value);
    }

    return formData;
};

function* getIteratorOverObject(data) {
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'function') {
            throw new Error(`A data object shouldn't have a iterable function on it. (key=${key})`);
        }

        if (value === undefined) {
            continue;
        }

        if (typeof value !== 'object' || !value || value instanceof File) {
            yield [key, value ?? ''];
            continue;
        }

        if (value instanceof FileList) {
            for (let file of value) {
                yield [key, file];
            }
            continue;
        }

        if (Array.isArray(value)) {
            for (let item of value) {
                yield [key, item];
            }
            continue;
        }

        throw new Error(`Object values are not supported yet`);
    }
}

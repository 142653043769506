import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { PublicInvoiceModel, StatisticsModel } from '@/models';
import { FetchPublicInvoicesPayload } from '@/store/slices/payload-types/FetchPublicInvoicesPayload';

export interface UpdateInvoiceContractPayload {
    invoice: EntityId;
    contract: EntityId;
}

const staffInvoicesAdapter = createEntityAdapter<PublicInvoiceModel>({
    selectId: (invoice) => invoice.uid,
});

const initialState = staffInvoicesAdapter.getInitialState({
    statistics: null as Optional<StatisticsModel>,
    isLoading: null as Nullable<boolean>,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
    updateExpiredOrdersErrors: null as Nullable<unknown>,
    totalCount: 0,
});

export type StaffInvoicesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchPublicInvoicesPayload>('staff/invoices/async/fetchAll');
    export const fetchStatisticsByInvoiceId = createAction<EntityId>('staff/invoices/async/fetchStatisticsByInvoiceId');
    export const runUpdateForExpiredOrders = createAction<EntityId>('staff/invoices/async/runUpdateForExpiredOrders');
    export const updateContract = createAction<UpdateInvoiceContractPayload>('staff/invoices/async/updateContract');
}

const slice = createSlice({
    name: 'staffInvoices' as const,
    initialState,
    reducers: {
        setStatistics: (state, action: PayloadAction<StatisticsModel>) => {
            state.statistics = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUpdateExpiredOrdersErrors: (state, action: PayloadAction<unknown>) => {
            state.updateExpiredOrdersErrors = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
    },
});

const invoicesAdapterSelectors = staffInvoicesAdapter.getSelectors((state: RootState) => state.staffInvoicesWithSlice);

namespace sliceSelectors {
    export const statistics = (state: RootState) => state.staffInvoicesWithSlice.statistics;
    export const isSaving = (state: RootState) => state.staffInvoicesWithSlice.isSaving;
    export const savingErrors = (state: RootState) => state.staffInvoicesWithSlice.savingErrors;
}

export const staffInvoicesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...invoicesAdapterSelectors,
        ...sliceSelectors,
    },
};

import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'use-http';

import '@/shared/assets/fonts/Univers/UniversBlack.ttf';
import { FullPageSpinner } from '@/Loading';
import { useDispatch } from 'react-redux';
import { asyncActions } from '@/store/slices';
import { loadGtmScript } from '@/shared/utils/GoogleTagManager';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import lazyWithRetry from '@/utils/router-utils/lazyWithRetry';

const Public = lazyWithRetry(() => import('@/public'));
const Staff = lazyWithRetry(() => import('@/staff'));
const Drivers = lazyWithRetry(() => import('@/drivers'));

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => void dispatch(asyncActions.profile.fetchData()), [dispatch]);
    useEffect(() => void loadGtmScript(), []);

    const fetchOptions = {
        suspense: true,
        headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
    };

    // We'll try to load the currently authenticated user only here once
    // and render the rest of the applications when we get the response from the server.
    return (

            <DndProvider backend={HTML5Backend}>
                <Provider url="/" options={fetchOptions}>
                    <Suspense fallback={<FullPageSpinner />}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="App">
                                <Switch>
                                    <Route path="/staff" component={Staff} />
                                    <Route path="/drivers" component={Drivers} />
                                    <Route path="/" component={Public} />
                                </Switch>
                            </div>
                        </LocalizationProvider>
                    </Suspense>
                </Provider>
            </DndProvider>
    );
};

export default App;

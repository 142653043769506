import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { MealSlotModel } from '@/models';
import { RemoveMealSlotPayload } from '@/store/slices/staff/meal-slots.slice';

export const removeMealSlotEpic: PayloadEpic<RemoveMealSlotPayload, MealSlotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.mealSlots.remove.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.mealSlots.setIsSaving(true)),
                removeMealSlot(payload).pipe(
                    switchMap(({ data }) => [actions.mealSlots.removeOne(payload.id), actions.mealSlots.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('removeMealSlotEpic', error);

                        return of(actions.mealSlots.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.mealSlots.setIsSaving(false))
            )
        )
    );
};

const removeMealSlot = (payload: RemoveMealSlotPayload) => {
    return axios.delete<MealSlotModel>(apiEndpoints.mealSlot(payload.id));
};

import { apiDuck } from '@/shared/ducks/api';
import {useFetch} from "use-http";

export const duck = apiDuck('shippingAddress', '/api/shipping-addresses/');

export function useAddressAPI(uid) {
    return useFetch(`/api/shipping-addresses/${uid}/`)
}

duck.useAddressAPI = useAddressAPI;

export const { actionTypes, actions, reducer, useAPI } = duck

export default duck;
import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { AssetLotModel } from '@/models';

export const requestAssetInventoryEpic: PayloadEpic<EntityId, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.requestAssetInventory.match),
        switchMap(({ payload }) => concat(
            of(actions.assetLots.setIsRequestingAssetLotInventory(true)),
            requestAssetInventory(payload).pipe(
                switchMap(() => EMPTY),
                catchError((error) => {
                    console.error('requestAssetInventoryEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.assetLots.setIsRequestingAssetLotInventory(false)),
        )),
    );
};

const requestAssetInventory = (payload: EntityId) => {
    return axios.post<AssetLotModel>(apiEndpoints.requestAssetInventory(payload));
};

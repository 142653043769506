import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { UserModel } from '@/models';

const driversAdapter = createEntityAdapter<UserModel>();

const initialState = driversAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type DriversState = typeof initialState;

export interface FetchDriverByIdPayload {
    driverId: EntityId;
}

export interface FetchAllDriversPayload {
    facility__uid?: EntityId;
    page?: number;
    page_size?: number;
}

export interface CreateDriverPayload {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    facility: string;
}

export interface UpdateDriverPayload {
    id: EntityId;
    facility: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
}

namespace asyncActions {
    export const fetchAll = createAction<FetchAllDriversPayload>('drivers/async/fetchAll');
    export const createDriver = createAction<CreateDriverPayload>('drivers/async/createDriver');
    export const updateDriver = createAction<UpdateDriverPayload>('drivers/async/updateDriver');
    export const fetchById = createAction<FetchDriverByIdPayload>('drivers/async/fetchById');
}

const slice = createSlice({
    name: 'drivers' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<UserModel[]>) => {
            driversAdapter.setAll(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        updateDriver: (state, action: PayloadAction<UserModel>) => {
            driversAdapter.upsertOne(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<UserModel>) => {
            driversAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<UserModel>) => {
            driversAdapter.upsertOne(state, action.payload);
        },
    },
});

const driversAdapterSelectors = driversAdapter.getSelectors((state: RootState) => state.drivers);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.drivers.isLoading;
    export const totalCount = (state: RootState) => state.drivers.totalCount;
    export const isSaving = (state: RootState) => state.drivers.isSaving;
    export const savingErrors = (state: RootState) => state.drivers.savingErrors;
}

export const driversSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...driversAdapterSelectors,
        ...sliceSelectors,
    },
};

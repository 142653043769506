import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SubscriptionModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';

export const deleteSubscriptionEpic: PayloadEpic<EntityId, SubscriptionModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.deleteSubscription.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsLoading(true)),
            deleteSubscription(payload).pipe(
                map(() => actions.contracts.deleteSubscription(payload)),
                catchError((error) => {
                    console.error('deleteSubscriptionEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoading(false)),
        )),
    );
};

const deleteSubscription = (subscriptionUid: EntityId) => {
    return axios.delete<void>(apiEndpoints.deleteSubscription(subscriptionUid));
};

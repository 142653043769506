import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DeliverySiteModel } from '@/models';
import { CreateDeliverySitePayload } from '@/store/slices/staff/delivery-sites.slice';

export const createDeliverySiteEpic: PayloadEpic<CreateDeliverySitePayload, DeliverySiteModel[]> = (
    action$
) => {
    return action$.pipe(
        filter(asyncActions.deliverySites.create.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.deliverySites.setIsSaving(true)),
                createDeliverySite(payload).pipe(
                    switchMap(({ data }) =>
                        from([
                            actions.deliverySites.addOne(data),
                            actions.deliverySites.setSavingErrors(null),
                        ])
                    ),
                    catchError((error) => {
                        console.error('createDeliverySiteEpic', error);

                        return of(actions.deliverySites.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.deliverySites.setIsSaving(false))
            )
        )
    );
};

const createDeliverySite = (data: CreateDeliverySitePayload) => {
    return axios.post<DeliverySiteModel>(apiEndpoints.deliverySites(), data);
};

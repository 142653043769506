import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { AuditCommentModel } from '@/models';

export const CONTRACT_AUDIT_ACTIONS = {
    created: 'created',
    modify_pricing_profile: 'modify_pricing_profile',
    upload_pdf: 'upload_pdf',
    comment: 'comment',
    updated: 'updated',
    submit_bid: 'submit_bid',
    approved: 'approved',
    extended: 'extended',
    deactivated: 'deactivated',
};

export const fetchCommentsByContractIdEpic: PayloadEpic<EntityId, AuditCommentModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.fetchCommentsById.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsLoadingComments(true)),
            fetchComment(payload).pipe(
                map(({ data }) => actions.contracts.setCommentsByContractId({
                    contractId: payload,
                    comments: data.results.reverse(),
                })),
                catchError((error) => {
                    console.error('fetchCommentsByContractIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoadingComments(false)),
        )),
    );
};


const fetchComment = (contractId: EntityId) => {
    const urlParams = objectToUrlSearchParams({
        contract__uid: contractId,
        action: CONTRACT_AUDIT_ACTIONS.comment,
    });

    return axios.get<ListResponse<AuditCommentModel>>(`${apiEndpoints.auditActionComments()}?${urlParams}`);
};

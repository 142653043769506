import { combineEpics } from 'redux-observable';
import { fetchAllAssetsEpic } from './fetch-all-assets.epic';
import { combinedInventoryAllergenEpics } from '@/store/epics/staff/assets/allergens';
import { combinedAssetLotsEpics } from '@/store/epics/staff/assets/asset-lots';
import { deleteInventoryAssetEpic } from '@/store/epics/staff/assets/delete-inventory-asset.epic';
import { combinedInventoryComponentEpics } from '@/store/epics/staff/assets/component';

export const combinedAssetsCatalogEpics = combineEpics(
    fetchAllAssetsEpic,
    deleteInventoryAssetEpic,
    combinedInventoryAllergenEpics,
    combinedInventoryComponentEpics,
    combinedAssetLotsEpics,
);

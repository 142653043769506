import React from 'react';
import { viewsetDuck } from '@/shared/ducks/viewsetDuck';

export const DEFAULT_STATE = {};

export const duck = viewsetDuck('staffAssets', '/mgmt/api/inventory/assets/');

export const useContainerPackages = (availableOnly = true) => {
    return duck.useAPI(`?type=containerpackage&page_size=999&available_only=${availableOnly ? 'true' : 'false'}`, {
        suspense: false,
    });
};

export const { actionTypes, actions, reducer, useAPI } = duck;

export default duck;

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ReorderPricingRulesPayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const reorderPricingRulesEpic: PayloadEpic<ReorderPricingRulesPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.reorderPricingRules.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.pricingProfiles.setIsReorderingPricingRules(true)),
                reorderPricingRules(payload).pipe(
                    switchMap(() => EMPTY),
                    // map(({ data }) => actions.pricingProfiles.setPricingRules(data.results)),
                    catchError((error) => {
                        console.error('reorderPricingRulesEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.pricingProfiles.setIsReorderingPricingRules(false))
            )
        )
    );
};

const reorderPricingRules = ({ pricingProfileId, rule_order }: ReorderPricingRulesPayload) => {
    return axios.post<unknown>(apiEndpoints.pricingRulesOrder(pricingProfileId), { rule_order });
};

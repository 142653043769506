import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, forkJoin, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SetShippingOptionsPayload } from '@/store/slices/public/carts.slice';
import {withSelector} from "@/utils/rxjs-utils";

export const setShippingOptionsEpic: PayloadEpic<SetShippingOptionsPayload, string> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.setShippingOptions.match),
        switchMap((action) => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
            of(action),
        ])),
        switchMap(([selectedOrgUnit, selectedContract, { payload }]) => concat(
            of(actions.carts.setIsLoading(true)),
            setShippingOptions(selectedOrgUnit, selectedContract, payload).pipe(
                map(() => asyncActions.carts.fetchCartFinalCheckout()),
                catchError((error) => {
                    console.error('setShippingOptionsEpic', error);
                    return of(actions.carts.setSetShippingOptionErrors(error));
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    );
};

const setShippingOptions = (selectedOrgUnit, selectedContract, payload) => {
    return axios.post(
        apiEndpoints.setShippingOptions(selectedOrgUnit!.uid),
        payload,
        { params: { sales_contract: selectedContract?.uid } }
    );
};

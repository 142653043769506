import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';

export const fetchAllGroupsEpic: PayloadEpic<void, string[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.fetchGroups.match),
        switchMap(() => concat(
            of(actions.users.setIsLoading(true)),
            of(groups()).pipe(
                map((data) => actions.users.setGroups(data.groups)),
                catchError((error) => {
                    console.error('fetchAllGroupsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.users.setIsLoading(false)),
        )),
    );
};

export const groups = () => {
    return {
        groups: [
            'Sales',
            'Finance',
            'Operations',
            'Operations-Admin',
            'Admin',
        ],
    };
};

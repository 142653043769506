import { combineEpics } from 'redux-observable';
import { fetchAllServingMenus } from '@/store/epics/staff/serving-menus/fetch-all-serving-menus.epic';
import { createServingMenuEpic } from '@/store/epics/staff/serving-menus/create-serving-menu.epic';
import { updateServingMenuEpic } from '@/store/epics/staff/serving-menus/update-serving-menu.epic';
import { fetchServingMenuByIdEpic } from '@/store/epics/staff/serving-menus/fetch-serving-menu-by-id.epic';
import { removeServingMenuEpic } from './remove-serving-menu.epic';
import { refreshMenuComplianceEpic } from './refresh-menu-compliance.epic';

export const combinedServingMenusEpics = combineEpics(
    fetchAllServingMenus,
    createServingMenuEpic,
    updateServingMenuEpic,
    fetchServingMenuByIdEpic,
    removeServingMenuEpic,
    refreshMenuComplianceEpic
);

import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ASSET_RESOURCE_TYPES } from '@/shared/constants/enums/AssetResourceTypes';
import { FetchAssetAssemblyPlanPayload } from '@/store/slices/payload-types/warehouse-payload-types';
import { AssemblyPlanResponseModel } from '@/models';

export const fetchAssetAssemblyPlanEpic: PayloadEpic<FetchAssetAssemblyPlanPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.fetchAssetAssemblyPlan.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouses.setIsFetchingAssetAssemblyPlan(true)),
                fetchPlanAssembly(payload).pipe(
                    map(({ data }) =>
                        actions.warehouses.setAssemblyPlan({
                            ...data,
                            planInfo: payload,
                        })
                    ),
                    catchError((error) => {
                        console.error('fetchPlanAssetAssemblyEpic', error);

                        return of(actions.warehouses.setFetchAssetAssemblyPlanErrors(error?.response?.data || {}));
                    })
                ),
                of(actions.warehouses.setIsFetchingAssetAssemblyPlan(false))
            )
        )
    );
};

const fetchPlanAssembly = ({ type, warehouseId, ...data }: FetchAssetAssemblyPlanPayload) => {
    const url = type === ASSET_RESOURCE_TYPES.meal ? apiEndpoints.mealAssemblyPlan(warehouseId) : apiEndpoints.packageAssemblyPlan(warehouseId);

    return axios.post<AssemblyPlanResponseModel>(url, data);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ProcessPaymentPayload } from '@/store/slices/payload-types/public-invlices-payload-types';

export const processPaymentEpic: PayloadEpic<ProcessPaymentPayload> = (action$) => {
    return action$.pipe(
        filter(asyncActions.publicInvoices.processPayment.match),
        switchMap(({ payload }) => concat(
            of(actions.publicInvoices.setIsProcessingPayment(true)),
            processPayment(payload).pipe(
                map(({ data }) => actions.publicInvoices.setProcessPaymentRedirectUrl(`/thank-you/${data.uid}`)),
                catchError((error) => {
                    console.error('processPaymentEpic', error);
                    return of(actions.publicInvoices.setProcessPaymentErrors(error));
                }),
            ),
            of(actions.publicInvoices.setIsProcessingPayment(false)),
        )),
    );
};

const processPayment = (payload: ProcessPaymentPayload) => {
    const nonce = payload.nonce;
    return axios.post<Record<'uid', string>>(apiEndpoints.processPayment(payload.invoiceId), { nonce });
};

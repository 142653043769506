import { combineEpics } from 'redux-observable';

import { combinedPublicEpics } from './public';
import { combinedStaffEpics } from './staff';
import { combinedDriverEpics } from './driver';

import configureAxios from './configure-axios';

configureAxios();

export const rootEpic = combineEpics(combinedPublicEpics, combinedStaffEpics, combinedDriverEpics);

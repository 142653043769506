import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { DispatchRouteModel } from '@/models/dispatch-route.model';
import { FetchAllDispatchRoutesPayload } from '@/store/slices/staff/dispatch-routes.slice';
import { DispatchRouteItem } from '@/models';

export const fetchAllDispatchRoutes: PayloadEpic<FetchAllDispatchRoutesPayload, DispatchRouteModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.dispatchRoutes.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.dispatchRoutes.setIsLoading(true)),
                fetchDispatchRoutes(payload).pipe(
                    switchMap(({ data }) =>
                        from([actions.dispatchRoutes.setAll(data), actions.dispatchRoutes.setTotalCount(data.length)])
                    ),
                    catchError((error) => {
                        console.error('fetchAllDispatchRoutes', error);

                        return EMPTY;
                    })
                ),
                of(actions.dispatchRoutes.setIsLoading(false))
            )
        )
    );
};

export const fetchDispatchRoutes = (payload: FetchAllDispatchRoutesPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<DispatchRouteItem[]>(`${apiEndpoints.routeSheets()}?${searchParams}`);
};

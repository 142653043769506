import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { concat, EMPTY } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { StatisticsModel } from '@/models';

export const fetchStatisticsByPublicInvoiceIdEpic: PayloadEpic<EntityId> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.publicInvoices.fetchStatisticsByInvoiceId.match),
        map(({ payload }) => payload),
        distinctUntilChanged(),
        switchMap((invoiceId) => concat(
            fetchStatisticsByInvoiceId(invoiceId).pipe(
                switchMap(({ data }) => state$.pipe(
                    map((state) => selectors.publicInvoices.selectById(state, invoiceId)),
                    filter((invoice) => !!invoice),
                    take(1),
                    map(() => actions.publicInvoices.setInvoiceStatistics({
                        invoiceId,
                        statistics: data,
                    })),
                )),
                catchError((error) => {
                    console.error('fetchStatisticsByPublicInvoiceIdEpic', error);

                    return EMPTY;
                }),
            ),
            // of(actions.publicInvoices.setIsLoading(false)),
        )),
    );
};

const fetchStatisticsByInvoiceId = (invoiceUid: EntityId) => {
    return axios.get<StatisticsModel>(apiEndpoints.publicInvoiceStatistics(invoiceUid));
};

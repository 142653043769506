import { combineEpics } from 'redux-observable';
import { fetchAllUsersEpic } from './fetch-all-users.epic';
import { resetPasswordEpic } from './reset-password.epic';
import { updateUserEmailVerifiedEpic } from './update-user-email-varified.epic';
import { combinedUsersGroupsEpics } from './groups';
import { combinedUsersPermissionEpics } from './permissions';
import { createUserEpic } from './create-user.epic';
import { updateUserEpic } from './update-user.epic';
import { fetchUserByIdEpic } from './fetch-user-by-id.epic';
import { archiveUserEpic } from './archive-user.epic';

export const combinedUsersEpics = combineEpics(
    fetchAllUsersEpic,
    resetPasswordEpic,
    createUserEpic,
    archiveUserEpic,
    updateUserEpic,
    fetchUserByIdEpic,
    updateUserEmailVerifiedEpic,
    combinedUsersGroupsEpics,
    combinedUsersPermissionEpics
);

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { UpdateWarehouseRoutePayload } from '@/store/slices/staff/warehouse-routes.slice';
import { WarehouseRouteModel } from '@/models';

export const updateWarehouseRouteEpic: PayloadEpic<UpdateWarehouseRoutePayload, WarehouseRouteModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouseRoutes.update.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouseRoutes.setIsSaving(true)),
                updateWarehouseRoute(payload).pipe(
                    switchMap(({ data }) => from([actions.warehouseRoutes.upsertOne(data)])),
                    catchError((error) => {
                        console.error('updatingWarehouseRouteEpic', error);

                        return of(actions.warehouseRoutes.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.warehouseRoutes.setIsSaving(false))
            )
        )
    );
};

const updateWarehouseRoute = (data: UpdateWarehouseRoutePayload) => {
    return axios.put<WarehouseRouteModel>(apiEndpoints.warehouseRoute(data.id), data);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { MenuTemplateModel } from '@/models/menu-template.model';
import { FetchAllMenuTemplatesPayload } from '@/store/slices/staff/menu-template.slice';

export const fetchAllMenuTemplatesEpic: PayloadEpic<FetchAllMenuTemplatesPayload, MenuTemplateModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.menuTemplates.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.menuTemplates.setIsLoading(true)),
                fetchMenuTemplates(payload).pipe(
                    switchMap(({ data }) => from([actions.menuTemplates.setAll(data.results), actions.menuTemplates.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllMenuTemplates', error);

                        return EMPTY;
                    })
                ),
                of(actions.menuTemplates.setIsLoading(false))
            )
        )
    );
};

export const fetchMenuTemplates = (payload: FetchAllMenuTemplatesPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<MenuTemplateModel>>(`${apiEndpoints.menuTemplates()}?${searchParams}`);
};

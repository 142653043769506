import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { MoveAssetLotToWarehousePayload } from '@/store/slices/payload-types/asset-payload-types';
import { AssetLotModel } from '@/models';
import { objectToFormData } from '@/shared/utils/objectToFormData';

export const moveAssetLotToWarehouseEpic: PayloadEpic<MoveAssetLotToWarehousePayload, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.moveToWarehouse.match),
        switchMap(({ payload }) => concat(
            of(actions.assetLots.setIsSaving(true)),
            moveAssetLotToWarehouse(payload).pipe(
                switchMap(({ data }) => [
                    actions.assetLots.upsertOne(data),
                    actions.assetLots.setSavingErrors(null),
                ]),
                catchError((error) => {
                    console.error('purchaseComponentAssetLotEpic', error);

                    return of(actions.assetLots.setSavingErrors(error));
                }),
            ),
            of(actions.assetLots.setIsSaving(false)),
        )),
    );
};

const moveAssetLotToWarehouse = (payload: MoveAssetLotToWarehousePayload) => {
    const data = objectToFormData(payload);

    return axios.post<AssetLotModel>(apiEndpoints.transferAssetLot(payload.assetLotUid), data);
};

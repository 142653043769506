import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ContractModel } from '@/models';
import { objectToFormData } from '@/shared/utils/objectToFormData';
import { CreateContractPayload } from '@/store/slices/payload-types/CreateContractPayload';

export const createSalesContractEpic: PayloadEpic<CreateContractPayload, ContractModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.createSalesContract.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsCreatingContract(true)),
            createSalesContract(payload).pipe(
                switchMap(({ data }) => from([
                    actions.contracts.addOne(data),
                    actions.contracts.setSelectedContract(data),
                ])),
                catchError((error) => {
                    console.error('createSalesContractEpic', error);

                    return of(actions.contracts.setSaveContractErrors(error?.response?.data));
                }),
            ),
            of(actions.contracts.setIsCreatingContract(false)),
        )),
    );
};

const createSalesContract = (data: CreateContractPayload) => {
    return axios.post<ContractModel>(apiEndpoints.contracts(), objectToFormData(data));
};

import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { ContractModel } from '@/models';

export const SELECTED_CONTRACT_TOKEN = 'SELECTED_CONTRACT_TOKEN';

export interface FetchAllContractsPayload {
    page?: number;
    page_size?: number;
    status?: string,
    org_unit__uid?: string,
}

export interface FetchContractByIdPayload {
    contractId: EntityId;
}

const contractsAdapter = createEntityAdapter<ContractModel>({
    selectId: (contract) => contract.uid,
});

const initialState = contractsAdapter.getInitialState({
    isLoading: false,
    isLoadingSelectedContract: false,
    selectedContract: null as Nullable<ContractModel>,
});

export type PublicContractsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllContractsPayload>('public/contracts/async/fetchAll');
    export const fetchSelectedContract = createAction<EntityId>('public/contracts/async/fetchSelectedContract');
}

const slice = createSlice({
    name: 'publicContracts' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSelectedContract: (state, action: PayloadAction<boolean>) => {
            state.isLoadingSelectedContract = action.payload;
        },
        setSelectedContract: (state, action: PayloadAction<ContractModel | null>) => {
            state.selectedContract = action.payload;
            localStorage.setItem(SELECTED_CONTRACT_TOKEN, action.payload?.uid ?? '');
        },
        setAll: (state, action: PayloadAction<any[]>) => {
            contractsAdapter.setAll(state, action.payload);
        },
    },
});

const contractsAdapterSelectors = contractsAdapter.getSelectors((state: RootState) => state.publicContracts);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.publicContracts.isLoading;
    export const isLoadingSelectedContract = (state: RootState) => state.publicContracts.isLoadingSelectedContract;
    export const selectedContract = (state: RootState) => state.publicContracts.selectedContract;
}

export const publicContractsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...contractsAdapterSelectors,
        ...sliceSelectors,
    },
};

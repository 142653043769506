import apiDuck from './api';
import React from 'react';

export const viewsetDuck = (name, BASE, ducklings, defaultState) => {
    const duck = apiDuck(name, BASE, ducklings, defaultState);

    function useList(...args) {
        let [url, options, dependencies] = duck.parseFetchArgs(...args);
        console.log('URL', url);
        console.log('options', options);
        console.log('dependencies', dependencies);
        options.target = options.target || 'list';

        return {
            ...duck.useAPI(url, options, dependencies),
            response: duck.useDuckSelector((state) => state?.list || { results: [] }),
        };
    }

    function useObject(pk, ...args) {
        let [url, options, dependencies] = duck.parseFetchArgs(...args);
        options.target = options.target || pk;
        return {
            ...duck.useAPI(`${url || ''}${pk}/`, options, dependencies),
            ...duck.useDuckSelector((state) => (state && state[options.target]) || {}),
        };
    }

    function useObjectMultipart(pk) {
        // We'll use this duck only to prefetch the data
        // when page is loaded and to utilize React's suspense feature
        useObject(pk, null, { suspense: true }, []);

        // This duck will be used to communicate with the server
        // without triggering React's suspense.
        return useObject(pk, null, { formData: true, suspense: false });
    }

    function Demo() {
        const List = useList([]);

        const [search, setSearch] = React.useState('');
        return (
            <div style={{ border: '1px solid blue' }}>
                <h3>{name} Duck Demo</h3>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        List.get(`?title=${search}`);
                    }}
                >
                    <input onChange={(e) => setSearch(e.target.value)} />
                    <button type="submit">Search</button>
                </form>
                <h4>{List.response.results.length} Results</h4>
                {List.response.results.map((data) => (
                    <li>{JSON.stringify(data)}</li>
                ))}
            </div>
        );
    }

    return { ...duck, useList, useObject, useObjectMultipart, Demo };
};

export default viewsetDuck;

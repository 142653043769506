import { isInList } from './common';

export const pickProps = <T extends {}, F extends keyof T>(object: T, ...fields: F[]): Pick<T, F> => {
    const slice = {} as any;

    for (const [key, value] of Object.entries(object)) {
        if (isInList(key, fields)) {
            slice[key] = value;
        }
    }

    return slice;
};

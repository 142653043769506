import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { CreateCommentPayload } from '@/store/slices/payload-types/CreateCommentPayload';
import { AuditCommentModel } from '@/models';

export const createCommentEpic: PayloadEpic<CreateCommentPayload> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.createComment.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsCreatingComment(true)),
            createComment(payload).pipe(
                map(({ data }) => actions.contracts.addCommentToContract({
                    contractId: payload.contractId,
                    comment: data,
                })),
                catchError((error) => {
                    console.error('createCommentEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsCreatingComment(false)),
        )),
    );
};

const createComment = (payload: CreateCommentPayload) => {
    return axios.post<AuditCommentModel>(apiEndpoints.contractComment(payload.contractId), {
        comment: payload.comment,
    });
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SubscriptionModel } from '@/models';
import { FetchAllContractsPayload, FetchAllSubscriptionsPayload } from '@/store/slices/staff/contracts.slice';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllSubscriptionsEpic: PayloadEpic<FetchAllContractsPayload, SubscriptionModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.fetchAllSubscriptions.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsLoading(true)),
            fetchSubscriptions(payload).pipe(
                switchMap(({ data }) => from([
                    actions.contracts.setAllSubscriptions(data.results),
                    actions.contracts.setSubscriptionsTotalCount(data.count),
                ])),
                catchError((error) => {
                    console.error('fetchAllSubscriptionsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoading(false)),
        )),
    );
};

const fetchSubscriptions = (payload: FetchAllSubscriptionsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<SubscriptionModel>>(`${apiEndpoints.subscriptions()}?${searchParams}`);
};

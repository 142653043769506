import { combineEpics } from 'redux-observable';
import { addPermissionEpic } from './add-permission.epic';
import { fetchAllGroupsEpic } from './fetch-all-groups.epic';
import { fetchAllPermissionsEpic } from './fetch-all-permissions.epic';
import { revokePermissionEpic } from './revoke-permission.epic';

export const combinedUsersPermissionEpics = combineEpics(
    addPermissionEpic,
    fetchAllGroupsEpic,
    fetchAllPermissionsEpic,
    revokePermissionEpic,
);

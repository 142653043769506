import { combineEpics } from 'redux-observable';
import { fetchProfileDataEpic } from './fetch-profile-data.epic';
import { logoutUserEpic } from './logout-user.epic';
import { patchProfileDataEpic } from './patch-profile-data.epic';

export const combinedProfileEpics = combineEpics(
    fetchProfileDataEpic,
    logoutUserEpic,
    patchProfileDataEpic,
);

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { CartMetaInfoModel, CartModel, CartOrderModel } from '@/models';
import { withSelector } from '@/utils/rxjs-utils';

export type FetchCartEpicOutput = CartOrderModel[] | CartMetaInfoModel;

export const fetchCurrentCartEpic: PayloadEpic<void, FetchCartEpicOutput> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.fetchCurrent.match),
        switchMap(() => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
        ])),
        map(([selectedOrgUnit, selectedContract]) => [selectedOrgUnit?.uid, selectedContract?.uid]),
        filter(([selectedOrgUnitUid]) => !!selectedOrgUnitUid),
        switchMap(([selectedOrgUnitUid, selectedContractUid]) => concat(
            of(actions.carts.setIsLoading(true)),
            fetchCart(selectedOrgUnitUid!, selectedContractUid || null).pipe(
                switchMap(({ data }) => from([
                    actions.carts.setCurrentCartOrders(data.orders),
                    actions.carts.setCurrentCartMetaInfo(data),
                ])),
                catchError((error) => {
                    console.error('fetchCartEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    );
};

const fetchCart = (selectedOrgUnit: string, selectedContract: Nullable<string>) => {
    return axios.get<CartModel>(apiEndpoints.cart(selectedOrgUnit), {
        params: { sales_contract: selectedContract },
    });
};

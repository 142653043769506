import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { AssetModel } from '@/models';
import { FetchAllAssetsPayload } from '@/store/slices/staff/assets.slice';

export const fetchAllAssetsEpic: PayloadEpic<FetchAllAssetsPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assets.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.assets.setIsLoading(true)),
            fetchContracts(payload).pipe(
                switchMap(({ data }) => from([
                    actions.assets.setAll(data.results),
                    actions.assets.setTotalCount(data.count),
                ])),
                catchError((error) => {
                    console.error('fetchAllAssetsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.assets.setIsLoading(false)),
        )),
    );
};

const fetchContracts = (payload: FetchAllAssetsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<AssetModel>>(`${apiEndpoints.inventoryAssets()}?${searchParams}`);
};

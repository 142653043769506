import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { WarehouseModel } from '@/models';
import { CreateWarehousePayload } from '@/store/slices/payload-types/warehouse-payload-types';

export const createWarehouseEpic: PayloadEpic<CreateWarehousePayload, WarehouseModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.create.match),
        switchMap(({ payload }) => concat(
            of(actions.warehouses.setIsSaving(true)),
            createWarehouse(payload).pipe(
                map(({ data }) => actions.warehouses.addOne(data)),
                catchError((error) => {
                    console.error('createWarehouseEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.warehouses.setIsSaving(false)),
        )),
    );
};

const createWarehouse = (payload: CreateWarehousePayload) => {
    return axios.post<WarehouseModel>(apiEndpoints.warehouses(), payload);
};

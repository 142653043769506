import { ResourceType } from '@/models';

export const ASSET_RESOURCE_TYPES = {
    meal: 'Meal',
    package: 'ContainerPackage',
    component: 'Component',
} as const;

export const ASSET_RESOURCE_TYPE_TEXTS = {
    meal: 'Meal',
    package: 'Package',
    component: 'Component',
} as const;

export const ASSET_RESOURCE_TYPE_FILTER_VALUES: Record<ResourceType, string> = {
    ContainerPackage: 'containerpackage',
    Component: 'component',
    Meal: 'meal',
};

import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { DispatchRouteItem } from '@/models';

export interface FetchAllDispatchRoutesPayload {
    warehouse?: EntityId;
    date?: string;
}

export interface MoveStopAndDispatchRoutesPayload {
    date: string;
    route: EntityId;
    stops: EntityId[];
    warehouse?: EntityId;
}

export interface ChangeDriverPayload {
    driver: EntityId;
    route: EntityId;
    warehouse?: EntityId;
    date?: string;
}

export interface ChangeStopTimePayload {
    route_stop_id: EntityId;
    stopTime: string;
    dispatchRouteId: EntityId;
    warehouse?: EntityId;
    date?: string;
}

const dispatchRoutesAdapter = createEntityAdapter<DispatchRouteItem>({
    selectId: (dispatchRoute) => dispatchRoute?.route?.id,
});

const initialState = dispatchRoutesAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
    stopRouteChangeError: null as Nullable<string>,
});

export type DispatchRoutesState = typeof initialState;

// Will change routes when API is done
namespace asyncActions {
    export const fetchAll = createAction<FetchAllDispatchRoutesPayload>('dispatchRoutes/async/fetchAll');
    export const moveStopAndFetchAll = createAction<MoveStopAndDispatchRoutesPayload>(
        'dispatchRoutes/async/moveStopAndFetchAll'
    );
    export const changeDriver = createAction<ChangeDriverPayload>('dispatchRoutes/async/changeDriver');
    export const changeStopTime = createAction<ChangeStopTimePayload>('dispatchRoutes/async/changeStopTime');
    export const create = createAction<any>('dispatchRoutes/async/create');
    export const update = createAction<any>('dispatchRoutes/async/update');
    export const fetchById = createAction<any>('dispatchRoutes/async/fetchById');
}

const slice = createSlice({
    name: 'dispatchRoutes' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<DispatchRouteItem[]>) => {
            dispatchRoutesAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<DispatchRouteItem>) => {
            dispatchRoutesAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<DispatchRouteItem>) => {
            dispatchRoutesAdapter.upsertOne(state, action.payload);
        },
        // updateStopTime: (state, action: PayloadAction<{ routeStop: RouteStopModel; dispatchId: EntityId }>) => {
        //     const stopIndex = state.entities[action.payload.dispatchId]?.stops.findIndex(
        //         (stop) => stop.id === action.payload.routeStop.id
        //     );
        //     (state.entities[action.payload.dispatchId] as DispatchRouteItem).stops[stopIndex as number] =
        //         action.payload.routeStop;
        // },
        setChangeRouteStopTimeErrors: (state, action: PayloadAction<string>) => {
            state.stopRouteChangeError = action.payload;
        },
    },
});

const dispatchRoutesAdapterSelectors = dispatchRoutesAdapter.getSelectors((state: RootState) => state.dispatchRoutes);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.dispatchRoutes.isLoading;
    export const isSaving = (state: RootState) => state.dispatchRoutes.isSaving;
    export const savingErrors = (state: RootState) => state.dispatchRoutes.savingErrors;
    export const totalCount = (state: RootState) => state.dispatchRoutes.totalCount;
    export const stopRouteChangeError = (state: RootState) => state.dispatchRoutes.stopRouteChangeError;
}

export const dispatchRoutesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...dispatchRoutesAdapterSelectors,
        ...sliceSelectors,
    },
};

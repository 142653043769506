import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';

export const runUpdateForExpiredOrdersEpic: PayloadEpic<EntityId, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.staffInvoices.runUpdateForExpiredOrders.match),
        switchMap(({ payload }) => concat(
            of(actions.staffInvoices.setIsLoading(true)),
            runUpdateForExpiredOrders(payload).pipe(
                switchMap(({ data }) => [
                    // actions.staffInvoices.setSavingErrors(null),
                ]),
                catchError((error) => {
                    console.error('runUpdateForExpiredOrdersEpic', error);

                    return of(actions.staffInvoices.setUpdateExpiredOrdersErrors(error));
                }),
            ),
            of(actions.staffInvoices.setIsLoading(false)),
        )),
    );
};

const runUpdateForExpiredOrders = (payload: EntityId) => {
    return axios.post<unknown>(apiEndpoints.expiredOrdersUpdate(payload));
};

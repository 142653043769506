import { viewsetDuck } from '@/shared/ducks/viewsetDuck'

export const DEFAULT_STATE = {}

export const duck = viewsetDuck('staffInvoices', '/mgmt/api/invoices/')

export const useMarkApprovedAPI = uid => {
    return duck.useAPI(`${uid}/mark-approved/`, { suspense: false })
}

export const useMarkInvoiceCompletedAPI = uid => {
    return duck.useAPI(`${uid}/mark-completed/`, { suspense: false })
}

export const useMarkCanceledAPI = uid => {
    return duck.useAPI(`${uid}/mark-declined/`, { suspense: false })
}

export const useMarkDeclinedAPI = uid => {
    return duck.useAPI(`${uid}/mark-declined/`, { suspense: false })
}

export const useMarkPaidAPI = uid => {
    return duck.useAPI(`${uid}/mark-paid/`, { suspense: false })
}

export const useMarkPreApprovedAPI = uid => {
    return duck.useAPI(`${uid}/mark-preapproved/`, { suspense: false })
}

export const useRecalculateShipping = uid => {
    return duck.useAPI(`${uid}/recalculate-shipping/`, { suspense: false })
}

export const useReissueManyOrders = uid => {
    return duck.useAPI(`${uid}/reissue-many-orders/`, { suspense: false })
}

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

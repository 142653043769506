import { combineEpics } from 'redux-observable';
import { fetchInvoicePaymentTokenInfoEpic } from './fetch-invoice-payment-token.epic';
import { fetchPublicInvoiceByIdEpic } from './fetch-public-invoice-by-id.epic';
import { fetchPublicInvoicesEpic } from './fetch-public-invoices.epic';
import { fetchStatisticsByPublicInvoiceIdEpic } from './fetch-statistics-by-invoice-id.epic';
import { processPaymentEpic } from './process-payment.epic';

export const combinedInvoicesEpics = combineEpics(
    fetchInvoicePaymentTokenInfoEpic,
    fetchPublicInvoiceByIdEpic,
    fetchPublicInvoicesEpic,
    fetchStatisticsByPublicInvoiceIdEpic,
    processPaymentEpic,
);

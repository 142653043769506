import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetLotModel } from '@/models';
import { UpdateUnitCostPayload } from '@/store/slices/payload-types/asset-payload-types';

export const changeUnitCostEpic: PayloadEpic<UpdateUnitCostPayload, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.changeUnitCost.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.assetLots.setIsSaving(true)),
                changeUnitCost(payload).pipe(
                    switchMap(({ data }) => [
                        actions.assetLots.upsertOne(data),
                        actions.assetLots.setSavingErrors(null),
                    ]),
                    catchError((error) => {
                        console.error('changeUnitCostEpic', error);
                        return of(actions.assetLots.setSavingErrors(error));
                    })
                ),
                of(actions.assetLots.setIsSaving(false))
            )
        )
    );
};

const changeUnitCost = (payload: UpdateUnitCostPayload) => {
    return axios.post<AssetLotModel>(apiEndpoints.changeUnitCost(payload.assetLotUid), payload);
};

import { combineEpics } from 'redux-observable';
import { fetchAllAssetLotsEpic } from '@/store/epics/staff/assets/asset-lots/fetch-all-asset-lots.epic';
import { purchaseComponentAssetLotEpic } from '@/store/epics/staff/assets/asset-lots/purchase-component-asset-lot.epic';
import { markAssetLotReceivedEpic } from '@/store/epics/staff/assets/asset-lots/actions/mark-asset-lot-received.epic';
import { makeStockAdjustmentsEpic } from '@/store/epics/staff/assets/asset-lots/actions/make-stock-adjustments.epic';
import { moveAssetLotToWarehouseEpic } from '@/store/epics/staff/assets/asset-lots/move-asset-lot-to-warehouse.epic';
import { requestAssetInventoryEpic } from '@/store/epics/staff/assets/asset-lots/actions/request-asset-inventory.epic';
import { changeUnitCostEpic } from '@/store/epics/staff/assets/asset-lots/actions/change-unit-cost.epic';

export const combinedAssetLotsEpics = combineEpics(
    purchaseComponentAssetLotEpic,
    fetchAllAssetLotsEpic,
    markAssetLotReceivedEpic,
    makeStockAdjustmentsEpic,
    moveAssetLotToWarehouseEpic,
    requestAssetInventoryEpic,
    changeUnitCostEpic
);

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ProductModel } from '@/models';
import { FetchAllProductsPayload } from '@/store/slices/staff/products.slice';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllProductsEpic: PayloadEpic<FetchAllProductsPayload, ProductModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.products.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.products.setIsLoading(true)),
            fetchAllProducts(payload).pipe(
                map(({ data }) => actions.products.setAll(data.results)),
                catchError((error) => {
                    console.error('fetchAllProductsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.products.setIsLoading(false)),
        )),
    );
};

const fetchAllProducts = (filters) => {
    const searchParams = objectToUrlSearchParams(filters);
    return axios.get<ListResponse<ProductModel>>(`${apiEndpoints.products()}?${searchParams}`);
};

import React, { createContext, useCallback, useContext, useState } from 'react';
import { Dialog } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';

type ModalState = Record<EntityId, [boolean, Optional<any>]>;
type SetModalStateFn = (modalId: EntityId, showModal: boolean, data?: Optional<any>) => void;

const ModalContext = createContext<Optional<[ModalState, SetModalStateFn]>>(null);

const defaultState = [false, null];

export const ModalContainer = ({ children }) => {
    const [modalState, setState] = useState({} as ModalState);

    const setModalState = useCallback((modalId: EntityId, showModal: boolean, data: any = null) => {
        setState((state) => Object.assign({}, state, {
            [modalId]: [showModal, showModal ? data : null],
        }));
    }, []);

    return (
        <ModalContext.Provider value={[modalState, setModalState]}>
            {children}
        </ModalContext.Provider>
    );
};

const useModalContext = () => {
    const state = useContext(ModalContext);

    if (!state) {
        throw new Error('Using ModalContainer is required!');
    }

    return state;
};

export const useDispatchModalState = (modalId) => {
    const [, setState] = useModalContext();
    return useCallback((showModal: boolean, data?: Optional<any>) => {
        setState(modalId, showModal, data);
    }, [modalId, setState]);
};

export const useModalState = (modalId) => {
    const [state] = useModalContext();

    return state[modalId] ?? defaultState;
};

export const useIsModalOpen = (modalId) => {
    const [isOpen] = useModalState(modalId);
    return isOpen;
};

export const useModalData = (modalId) => {
    const [, data] = useModalState(modalId);
    return data;
};


export const FfgDialog = ({ modalId, children, onClose, ...props }) => {
    const isOpen = useIsModalOpen(modalId);
    const setShowModal = useDispatchModalState(modalId);

    const onDialogClose = () => {
        onClose?.();
        setShowModal(false);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog {...props} open={isOpen} onClose={onDialogClose}>
            {children}
        </Dialog>
    );
};

export const withFfgDialog = (Component, dialogProps) => {
    return ({ modalId, ...componentProps }) => (
        <FfgDialog {...dialogProps} modalId={modalId ?? dialogProps.modalId}>
            <Component {...componentProps} modalId={modalId ?? dialogProps.modalId} />
        </FfgDialog>
    );
};


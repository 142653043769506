import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { concat, forkJoin, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { FetchShippingOptionsPayload } from '@/store/slices/public/carts.slice';
import { DeliverToModel, RateModel } from '@/models';
import { withSelector } from '@/utils/rxjs-utils';

interface FetchShippingOptionsResponse {
    deliver_to: DeliverToModel;
    rates: RateModel[];
}

export const fetchShippingOptionsEpic: PayloadEpic<FetchShippingOptionsPayload, DeliverToModel> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.fetchShippingOptions.match),
        distinctUntilChanged((left, right) => {
            return left.payload.shipping_address === right.payload.shipping_address;
        }),
        mergeMap((action) => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
            of(action),
        ])),
        mergeMap(([selectedOrgUnit, selectedContract, { payload }]) => concat(
            of(actions.carts.setIsLoading(true)),
            fetchShippingOptions({ selectedOrgUnit, selectedContract, payload }).pipe(
                map(({ data }) => actions.carts.setCurrentCartShippingOptions({
                    ...data.deliver_to,
                    rates: data.rates,
                })),
                catchError((error) => {
                    console.error('fetchShippingOptionsEpic', error);
                    return of(actions.carts.setFetchShippingOptionsErrors(error));
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    );
};

const fetchShippingOptions = ({ payload, selectedOrgUnit, selectedContract }) => {
    return axios.post<FetchShippingOptionsResponse>(
        apiEndpoints.getShippingOptions(selectedOrgUnit!.uid),
        payload,
        { params: { sales_contract: selectedContract?.uid } },
    );
};

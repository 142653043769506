import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { VendorModel } from '@/models';
import { CreateVendorPayload } from '@/store/slices/payload-types/asset-payload-types';

export const createVendorEpic: PayloadEpic<CreateVendorPayload, VendorModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.vendors.createVendor.match),
        switchMap(({ payload }) => concat(
            of(actions.vendors.setIsCreatingVendor(true)),
            createVendor(payload).pipe(
                map(({ data }) => actions.vendors.addVendor(data)),
                catchError((error) => {
                    console.error('createVendorEpic', error);

                    return of(actions.vendors.setCreateVendorErrors(error));
                }),
            ),
            of(actions.vendors.setIsCreatingVendor(false)),
        )),
    );
};

const createVendor = (data: CreateVendorPayload) => {
    return axios.post<VendorModel>(apiEndpoints.inventoryVendors(), data);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { UserPermissions } from '@/models';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';

export const fetchAllPermissionsEpic: PayloadEpic<void, UserPermissions> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.fetchAllPermissions.match),
        switchMap(() => concat(
            of(actions.users.setIsLoading(true)),
            handleFetchAllPermissions(),
            of(actions.users.setIsLoading(false)),
        )),
    );
};

export const handleFetchAllPermissions = () => {
    return fetchAllPermissions().pipe(
        map(({ data }) => actions.users.setUserPermissions(data)),
        catchError((error) => {
            console.error('fetchAllPermissionLabelsEpic', error);

            return EMPTY;
        }),
    );
};

const fetchAllPermissions = () => {
    return axios.get<UserPermissions>(apiEndpoints.allPermissions());
};

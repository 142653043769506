import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/root-state';
import { ManufacturerModel } from '@/models/manufacturer.model';
import { FetchManufacturersPayload, CreateManufacturerPayload } from '../payload-types/asset-payload-types';

const manufacturersAdapter = createEntityAdapter<ManufacturerModel>({
    selectId: (item) => item.id,
});

const initialState = manufacturersAdapter.getInitialState({
    isLoading: false,
    isCreatingManufacturer: false,
    createManufacturerErrors: null as unknown,
});

export type ManufacturersState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchManufacturersPayload>('manufacturers/async/fetchAll');
    export const createManufacturer = createAction<CreateManufacturerPayload>('manufacturers/async/createManufacturer');
}

const slice = createSlice({
    name: 'manufacturer' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsCreatingManufacturer: (state, action: PayloadAction<boolean>) => {
            state.isCreatingManufacturer = action.payload;
        },
        setCreateManufacturerErrors: (state, action: PayloadAction<unknown>) => {
            state.createManufacturerErrors = action.payload;
        },
        setAll: (state, action: PayloadAction<ManufacturerModel[]>) => {
            manufacturersAdapter.setAll(state, action.payload);
        },
        addManufacturer: (state, action: PayloadAction<ManufacturerModel>) => {
            manufacturersAdapter.addOne(state, action.payload);
        },
    },
});

const manufacturerAdapterSelectors = manufacturersAdapter.getSelectors((state: RootState) => state.manufacturers);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.manufacturers.isLoading;
    export const isCreatingManufacturer = (state: RootState) => state.manufacturers.isCreatingManufacturer;
    export const createManufacturerErrors = (state: RootState) => state.manufacturers.createManufacturerErrors;
}

export const manufacturersSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...manufacturerAdapterSelectors,
        ...sliceSelectors,
    },
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { CreateWarehouseRoutePayload } from '@/store/slices/staff/warehouse-routes.slice';
import { WarehouseRouteModel } from '@/models';

export const createWarehouseRouteEpic: PayloadEpic<CreateWarehouseRoutePayload, WarehouseRouteModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouseRoutes.create.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouseRoutes.setIsSaving(true)),
                createWarehouseRoute(payload).pipe(
                    switchMap(({ data }) => [actions.warehouseRoutes.addOne(data), actions.warehouseRoutes.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createWarehouseRouteEpic', error);

                        return of(actions.warehouseRoutes.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.warehouseRoutes.setIsSaving(false))
            )
        )
    );
};

const createWarehouseRoute = (payload: CreateWarehouseRoutePayload) => {
    return axios.post<WarehouseRouteModel>(apiEndpoints.warehouseRoutes(), payload);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { CreatePricingRulePayload } from '@/store/slices/payload-types/pricing-profile-payload-types';
import { PricingRuleModel } from '@/models';

export const createPricingRuleEpic: PayloadEpic<CreatePricingRulePayload, PricingRuleModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.createPricingRule.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.pricingProfiles.setIsSavingPricingRule(true)),
                of(actions.pricingProfiles.setSavingPricingRuleErrors(null)),
                createPricingRule(payload).pipe(
                    map(({ data }) => actions.pricingProfiles.addPricingRule(data)),
                    catchError((error) => {
                        console.error('createPricingRuleEpic', error);

                        return of(actions.pricingProfiles.setSavingPricingRuleErrors(error.response.data));
                    })
                ),
                of(actions.pricingProfiles.setIsSavingPricingRule(false))
            )
        )
    );
};

const createPricingRule = (data: CreatePricingRulePayload) => {
    return axios.post<PricingRuleModel>(apiEndpoints.pricingRules(), data);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';

export const fetchInvoicePaymentTokenInfoEpic: PayloadEpic<EntityId, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.publicInvoices.fetchPaymentTokenInfo.match),
        switchMap(({ payload }) => concat(
            of(actions.publicInvoices.setIsLoadingPaymentToken(true)),
            fetchInvoiceTokenInfo(payload).pipe(
                map(({ data }) => actions.publicInvoices.setPaymentTokenInfo(data)),
                catchError((error) => {
                    console.error('fetchPublicInvoiceByIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.publicInvoices.setIsLoadingPaymentToken(false)),
        )),
    );
};

const fetchInvoiceTokenInfo = (invoiceId: EntityId) => {
    return axios.get<unknown>(apiEndpoints.publicInvoiceToken(invoiceId));
};

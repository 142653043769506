import { getEntries, isInList } from './common';

export const omitProps = <T extends {}, F extends keyof T>(object: T, ...fieldsToExclude: F[]): Omit<T, F> => {
    const partial = {} as any;

    for (const [key, value] of getEntries(object)) {
        if (!isInList(key, fieldsToExclude)) {
            partial[key] = value;
        }
    }

    return partial as Omit<T, F>;
};

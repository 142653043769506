import { viewsetDuck } from '@/shared/ducks/viewsetDuck';

export const DEFAULT_STATE = {};

export const duck = viewsetDuck('bulkAddresses', '/api/bulk-addresses');

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { AuditLogModel } from '@/models';
import { FetchAllAuditLogsPayload } from '@/store/slices/payload-types/audit-log-payload-types';

export const fetchAndSetAllAuditLogsEpic: PayloadEpic<FetchAllAuditLogsPayload, AuditLogModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.auditLogs.fetchAndSetAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.auditLogs.setIsLoading(true)),
                fetchAllAuditLogs(payload).pipe(
                    map(({ data }) => actions.auditLogs.setAll(data.results)),
                    catchError((error) => {
                        console.error('fetchAllAuditLogsEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.auditLogs.setIsLoading(false))
            )
        )
    );
};

const fetchAllAuditLogs = (filters) => {
    const params = objectToUrlSearchParams(filters);

    return axios.get<ListResponse<AuditLogModel>>(`${apiEndpoints.auditLogs()}`, { params });
};

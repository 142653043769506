import React from 'react'
import { viewsetDuck } from '@/shared/ducks/viewsetDuck'

export const DEFAULT_STATE = {}

export const duck = viewsetDuck('staffAllergens', '/mgmt/api/allergens/')

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { FetchWarehouseRouteByIdPayload } from '@/store/slices/staff/warehouse-routes.slice';
import { WarehouseRouteModel } from '@/models';

export const fetchWarehouseRouteByIdEpic: PayloadEpic<FetchWarehouseRouteByIdPayload, WarehouseRouteModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouseRoutes.fetchById.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouseRoutes.setIsLoading(true)),
                fetchWarehouseRouteById(payload.warehouseRouteId).pipe(
                    map(({ data }) => actions.warehouseRoutes.upsertOne(data)),
                    catchError((error) => {
                        console.error('fetchWarehouseRouteByIdEpic', error);
                        return EMPTY;
                    })
                ),
                of(actions.warehouseRoutes.setIsLoading(false))
            )
        )
    );
};

const fetchWarehouseRouteById = (warehouseRouteId: EntityId) => {
    return axios.get<WarehouseRouteModel>(apiEndpoints.warehouseRoute(warehouseRouteId));
};

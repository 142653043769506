import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { RemoveMenuTemplatePayload } from '@/store/slices/staff/menu-template.slice';
import { MenuTemplateModel } from '@/models/menu-template.model';

export const removeMenuTemplateEpic: PayloadEpic<RemoveMenuTemplatePayload, MenuTemplateModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.menuTemplates.remove.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.menuTemplates.setIsSaving(true)),
                removeMenuTemplate(payload).pipe(
                    switchMap(({ data }) => [actions.menuTemplates.removeOne(payload.id), actions.menuTemplates.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('removeMenuTemplateEpic', error);

                        return of(actions.menuTemplates.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.menuTemplates.setIsSaving(false))
            )
        )
    );
};

const removeMenuTemplate = (payload: RemoveMenuTemplatePayload) => {
    return axios.delete<MenuTemplateModel>(apiEndpoints.menuTemplate(payload.id));
};

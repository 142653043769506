import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { DeliverySiteModel, SlotRestriction } from '@/models';
import { VOUCHER_PROGRAMS } from '@/shared/constants/enums/VoucherPrograms';

export interface FetchAllDeliverySitesPayload {
    page?: number;
    page_size?: number;
    has_totes_at_site?: boolean;
    warehouse__uid?: EntityId;
    meal_times?: string;
    delivery_days?: string;
    org_unit__organization__uid?: string;
    voucher_program?: string;
}

export interface FetchDeliverySiteByIdPayload {
    deliverySiteId: EntityId;
}

export interface CreateDeliverySitePayload {
    title: string;
    line_one: string;
    line_two: Nullable<string>;
    city: string;
    state: string;
    zip_code: string;
    delivery_contact_name: string;
    delivery_contact_phone: string;
    voucher_program: VOUCHER_PROGRAMS.SFSP | VOUCHER_PROGRAMS.CACFP;
    meal_times: Array<string>;
    delivery_days: Array<number>;
    meal_availability: string;
    slot_restrictions: Array<SlotRestriction>;
    contract: Nullable<number>;
    meal_time: string;
    delivery_window_start: string;
    delivery_window_end: string;
}

export interface UpdateDeliverySitePayload extends CreateDeliverySitePayload {
    id: DeliverySiteModel['id'];
}

const deliverySitesAdapter = createEntityAdapter<DeliverySiteModel>({
    selectId: (deliverySite) => deliverySite.id,
});

const initialState = deliverySitesAdapter.getInitialState({
    isLoading: false,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
    totalCount: 0,
});

export type DeliverySitesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllDeliverySitesPayload>('deliverySites/async/fetchAll');
    export const fetchDeliverySiteById = createAction<FetchDeliverySiteByIdPayload>('public/deliverySites/async/fetchDeliverySiteById');
    export const create = createAction<CreateDeliverySitePayload>('public/deliverySites/async/create');
    export const update = createAction<UpdateDeliverySitePayload>('public/deliverySites/async/update');
}

const slice = createSlice({
    name: 'deliverySites' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<DeliverySiteModel[]>) => {
            deliverySitesAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<DeliverySiteModel>) => {
            deliverySitesAdapter.addOne(state, action.payload);
        },
        upsertOne: (state, action: PayloadAction<DeliverySiteModel>) => {
            deliverySitesAdapter.upsertOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
    },
});

const deliverySitesAdapterSelectors = deliverySitesAdapter.getSelectors((state: RootState) => state.deliverySites);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.deliverySites.isLoading;
    export const isSaving = (state: RootState) => state.deliverySites.isSaving;
    export const totalCount = (state: RootState) => state.deliverySites.totalCount;
    export const savingErrors = (state: RootState) => state.deliverySites.savingErrors;
}

export const deliverySitesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...deliverySitesAdapterSelectors,
        ...sliceSelectors,
    },
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import {
    FetchInvoiceOrdersByIdPayload,
    SetInvoiceOrdersByIdPayload,
} from '@/store/slices/payload-types/public-invlices-payload-types';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { OrderModel } from '@/models';

export const fetchOrdersEpic: PayloadEpic<FetchInvoiceOrdersByIdPayload, SetInvoiceOrdersByIdPayload> = (action$) => {
    return action$.pipe(
        filter(asyncActions.orders.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.orders.setIsLoadingOrders(true)),
            of(actions.orders.setAll([])),
            fetchInvoiceOrders(payload.filter).pipe(
                switchMap(({ data }) => from([
                    actions.orders.setAll(data.results),
                    actions.orders.setTotalCount(data.count),
                ])),
                catchError((error) => {
                    console.error('fetchOrdersEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.orders.setIsLoadingOrders(false)),
        )),
    );
};

const fetchInvoiceOrders = (filter: unknown) => {
    const searchParams = objectToUrlSearchParams(filter);
    return axios.get<ListResponse<OrderModel>>(`${apiEndpoints.orders()}?${searchParams}`);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetModel } from '@/models';
import { CreateComponentAssetPayload } from '@/store/slices/payload-types/asset-payload-types';

export const createComponentAssetEpic: PayloadEpic<CreateComponentAssetPayload, AssetModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assets.createComponent.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.assets.setIsCreating(true)),
                createComponent(payload).pipe(
                    switchMap(({ data }) => [actions.assets.addOne(data), actions.assets.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createComponentAssetEpic', error?.response?.data);

                        return of(actions.assets.setSavingErrors(error?.response?.data || {}));
                    })
                ),
                of(actions.assets.setIsCreating(false))
            )
        )
    );
};

const createComponent = (payload: CreateComponentAssetPayload) => {
    return axios.post<AssetModel>(apiEndpoints.componentAssets(), payload);
};

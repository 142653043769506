import { viewsetDuck } from '@/shared/ducks/viewsetDuck';

export const DEFAULT_STATE = {};

export const duck = viewsetDuck('bulkAddressGroups', '/api/bulk-address-groups');

export const useBulkAddressesDisableAPI = uid => {
    return duck.useAPI(`/${uid}/disable-addresses/`, { suspense: false })
}

export const useBulkForwardErrorsToDefaultLocationApi = uid => {
    return duck.useAPI(`/${uid}/set-default-shipping-location/`, { suspense: false });
};

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { OrganizationUnit, ProfileModel } from '@/models';

export const SELECTED_ORG_UNIT_TOKEN = 'SELECTED_ORG_UNIT_TOKEN';

export interface ProfileState {
    data: ProfileModel | null,
    isLoading: boolean | null,
    error: null,
    selectedOrgUnit: OrganizationUnit | null,
}

const initialState: ProfileState = {
    data: null,
    isLoading: null,
    error: null,
    selectedOrgUnit: null,
};

export interface LogoutPayload {
    redirectUrl?: string;
}

namespace asyncActions {
    export const fetchData = createAction<void>('profile/async/fetchData');
    export const patchData = createAction<Partial<ProfileModel>>('profile/async/patchData');
    export const logout = createAction<LogoutPayload | void>('profile/async/logout');
}

const slice = createSlice({
    name: 'profile' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
        // todo: fix later when we'll have error model
        setError: (state, action: PayloadAction<null>) => {
            state.error = action.payload;
        },
        setSelectedOrgUnit: (state, action: PayloadAction<OrganizationUnit | null>) => {
            state.selectedOrgUnit = action.payload;
            localStorage.setItem(SELECTED_ORG_UNIT_TOKEN, action.payload?.uid ?? '');
        },
        setData: (state, action: PayloadAction<ProfileModel | null>) => {
            state.data = action.payload;
        },
        reset: () => initialState,
    },
});

export namespace sliceSelectors {
    export const data = (state: RootState) => state.profile.data;
    export const isLoading = (state: RootState) => state.profile.isLoading;
    export const error = (state: RootState) => state.profile.error;
    export const selectedOrgUnit = (state: RootState) => state.profile.selectedOrgUnit;
    export const orgUnits = (state: RootState) => getProfileOrgUnits(data(state));

    export const isLoggedIn = (state: RootState) => isLoading(state) === false && !!data(state);

    export const isEmailVerified = (state: RootState) => {
        const profile = data(state);

        return isLoading(state) === false && !!profile?.email && !!profile?.is_email_verified;
    };
}

const getProfileOrgUnits = (profileData: ProfileModel | null) => profileData?.organization?.organization_units ?? [];

export const profileSlice = {
    ...slice,
    asyncActions,
    selectors: sliceSelectors,
};

import { combineEpics } from 'redux-observable';
import { fetchAllWarehouseRoutes } from '@/store/epics/staff/warehouse-routes/fetch-all-warehouse-routes.epic';
import { createWarehouseRouteEpic } from '@/store/epics/staff/warehouse-routes/create-warehouse-route.epic';
import { updateWarehouseRouteEpic } from '@/store/epics/staff/warehouse-routes/update-warehouse-route.epic';
import { fetchWarehouseRouteByIdEpic } from '@/store/epics/staff/warehouse-routes/fetch-warehouse-route-by-id.epic';

export const combinedWarehouseRouteEpics = combineEpics(
    fetchAllWarehouseRoutes,
    createWarehouseRouteEpic,
    updateWarehouseRouteEpic,
    fetchWarehouseRouteByIdEpic
);

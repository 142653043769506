import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetLotModel } from '@/models';
import { MakeStockAdjustmentsPayload } from '@/store/slices/payload-types/asset-payload-types';
import { objectToFormData } from '@/shared/utils/objectToFormData';

export const makeStockAdjustmentsEpic: PayloadEpic<MakeStockAdjustmentsPayload, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.makeStockAdjustments.match),
        switchMap(({ payload }) => concat(
            of(actions.assetLots.setIsSaving(true)),
            makeStockAdjustments(payload).pipe(
                switchMap(({ data }) => [
                    actions.assetLots.upsertOne(data),
                    actions.assetLots.setSavingErrors(null),
                ]),
                catchError((error) => {
                    console.error('makeStockAdjustments', error);

                    return of(actions.assetLots.setSavingErrors(error));
                }),
            ),
            of(actions.assetLots.setIsSaving(false)),
        )),
    );
};

const makeStockAdjustments = (payload: MakeStockAdjustmentsPayload) => {
    const data = objectToFormData(payload);

    return axios.post<AssetLotModel>(apiEndpoints.makeStockAdjustment(payload.assetLotUid), data);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { UserModel } from '@/models';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { FetchAllDriversPayload } from '@/store/slices/staff/drivers.slice';

export const fetchAllDriversEpic: PayloadEpic<FetchAllDriversPayload, UserModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.drivers.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.drivers.setIsLoading(true)),
                fetchDriversRequest(payload).pipe(
                    switchMap(({ data }) => from([actions.drivers.setAll(data.results), actions.drivers.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllDriversEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.drivers.setIsLoading(false))
            )
        )
    );
};

const fetchDriversRequest = (payload: FetchAllDriversPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<UserModel>>(`${apiEndpoints.drivers()}?${searchParams}`);
};

import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VendorModel } from '@/models';
import { RootState } from '@/store/root-state';
import { CreateVendorPayload, FetchVendorsPayload } from '@/store/slices/payload-types/asset-payload-types';

const vendorsAdapter = createEntityAdapter<VendorModel>({
    selectId: (item) => item.uid,
});

const initialState = vendorsAdapter.getInitialState({
    isLoading: false,
    isCreatingVendor: false,
    createVendorErrors: null as unknown,
});

export type VendorsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchVendorsPayload>('vendors/async/fetchAll');
    export const createVendor = createAction<CreateVendorPayload>('vendors/async/createVendor');
}

const slice = createSlice({
    name: 'vendors' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsCreatingVendor: (state, action: PayloadAction<boolean>) => {
            state.isCreatingVendor = action.payload;
        },
        setCreateVendorErrors: (state, action: PayloadAction<unknown>) => {
            state.createVendorErrors = action.payload;
        },
        setAll: (state, action: PayloadAction<VendorModel[]>) => {
            vendorsAdapter.setAll(state, action.payload);
        },
        addVendor: (state, action: PayloadAction<VendorModel>) => {
            vendorsAdapter.addOne(state, action.payload);
        },
    },
});

const vendorAdapterSelectors = vendorsAdapter.getSelectors((state: RootState) => state.vendors);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.vendors.isLoading;
    export const isCreatingVendor = (state: RootState) => state.vendors.isCreatingVendor;
    export const createVendorErrors = (state: RootState) => state.vendors.createVendorErrors;
}

export const vendorsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...vendorAdapterSelectors,
        ...sliceSelectors,
    },
};

import { combineEpics } from 'redux-observable';
import { fetchAllWarehousesEpic } from '@/store/epics/staff/warehouses/fetch-all-warehouses.epic';
import { createWarehouseEpic } from '@/store/epics/staff/warehouses/create-warehouse.epic';
import { fetchAssetAssemblyPlanEpic } from '@/store/epics/staff/warehouses/fetch-asset-assembly-plan-epic';
import { assembleAssetEpic } from '@/store/epics/staff/warehouses/assemble-asset.epic';
import { combinedMealSlotsEpics } from '@/store/epics/staff/warehouses/meal-slots'
import { procureAssetLotEpic } from '@/store/epics/staff/warehouses/procure-asset-lot.epic';
import { editWarehouseEpic } from './edit-warehouse.epic';
import { fetchWarehouseByIdEpic } from './fetch-warehouse-by-id.epic';

export const combineWarehousesEpics = combineEpics(
    fetchAllWarehousesEpic,
    createWarehouseEpic,
    editWarehouseEpic,
    fetchWarehouseByIdEpic,
    fetchAssetAssemblyPlanEpic,
    assembleAssetEpic,
    procureAssetLotEpic,
    combinedMealSlotsEpics,
);

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { OrganizationUnit, ProfileModel, UserPermissions } from '@/models';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';

import { SELECTED_ORG_UNIT_TOKEN } from '@/store/slices/public/profile.slice';
import { withSelector } from '@/utils/rxjs-utils';
import { handleFetchAllPermissions } from '@/store/epics/staff/users/permissions/fetch-all-permissions.epic';

type FetchProfileDataEpicOutput = ProfileModel | OrganizationUnit | UserPermissions;

export const fetchProfileDataEpic: PayloadEpic<void, FetchProfileDataEpicOutput> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.profile.fetchData.match),
        switchMap(() => concat(
            of(actions.profile.setIsLoading(true)),
            fetchProfileRequest().pipe(
                switchMap(({ data }) => concat(
                    of(actions.profile.setData(data)),
                    data.is_staff ? handleFetchAllPermissions() : EMPTY,
                )),
                catchError((error) => {
                    console.error('fetchProfileDataEpic', error);
                    return EMPTY;
                }),
            ),
            withSelector(state$, selectors.profile.orgUnits).pipe(
                map((units) => actions.profile.setSelectedOrgUnit(
                    getPreviouslySelectedOrgUnit(units),
                )),
            ),
            of(actions.profile.setIsLoading(false)),
        )),
    );
};

const getPreviouslySelectedOrgUnit = (units: OrganizationUnit[]) => {
    const selectedOrgUnitUid = localStorage.getItem(SELECTED_ORG_UNIT_TOKEN);

    if (!units?.length) {
        return null;
    }

    return units.find(unit => unit.uid === selectedOrgUnitUid) ?? units[0];
};

const fetchProfileRequest = () => {
    return axios.get<ProfileModel>(apiEndpoints.profile());
};

import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LotAdjustmentLogModel } from '@/models/audit-log.model';
import { FetchAllAuditLogsPayload } from '@/store/slices/payload-types/audit-log-payload-types';

const lotAdjustmentsAdapter = createEntityAdapter<LotAdjustmentLogModel>({
    selectId: (auditLog) => auditLog.uid,
});

const initialState = lotAdjustmentsAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
});

export type LotAdjustmentInfoState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllAuditLogsPayload>('lotAdjustmentsInfo/async/fetchAll');
}

const slice = createSlice({
    name: 'lotAdjustmentsInfo' as const,
    initialState,
    reducers: {
        setAll: (state, action: PayloadAction<LotAdjustmentLogModel[]>) => {
            lotAdjustmentsAdapter.setAll(state, action.payload);
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
    },
});

const lotAdjustmentsAdapterSelectors = lotAdjustmentsAdapter.getSelectors((state: RootState) => state.lotAdjustmentsInfo);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.auditLogs.isLoading;
    export const totalCount = (state: RootState) => state.auditLogs.totalCount;
}

export const lotAdjustmentSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...lotAdjustmentsAdapterSelectors,
        ...sliceSelectors,
    },
};

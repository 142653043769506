import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { RouteStopModel } from '@/models';
import { FetchAllRouteStopsByRoutePayload } from '@/store/slices/staff/route-stops.slice';

export const fetchAllRouteStopsByRouteId: PayloadEpic<FetchAllRouteStopsByRoutePayload, RouteStopModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.routeStops.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.routeStops.setIsLoading(true)),
                fetchRouteStops(payload).pipe(
                    switchMap(({ data }) => from([actions.routeStops.setAll(data.results), actions.routeStops.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllRouteStops', error);

                        return EMPTY;
                    })
                ),
                of(actions.routeStops.setIsLoading(false))
            )
        )
    );
};

const fetchRouteStops = (payload: FetchAllRouteStopsByRoutePayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<RouteStopModel>>(`${apiEndpoints.routeStops()}?${searchParams}`);
};

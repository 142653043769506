import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AuditLogModel, LotAdjustmentLogModel } from '@/models/audit-log.model';
import { FetchAllAuditLogsPayload } from '@/store/slices/payload-types/audit-log-payload-types';

export const fetchLotAdjustmentLogsEpic: PayloadEpic<FetchAllAuditLogsPayload, AuditLogModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.lotAdjustments.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.lotAdjustments.setIsLoading(true)),
            fetchLotAdjustmentLogs(payload).pipe(
                map(({ data }) => actions.lotAdjustments.setAll(data.results)),
                catchError((error) => {
                    console.error('fetchLotAdjustmentLogsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.lotAdjustments.setIsLoading(false)),
        )),
    );
};

const fetchLotAdjustmentLogs = (params) => {
    return axios.get<ListResponse<LotAdjustmentLogModel>>(`${apiEndpoints.lotAdjustments()}`, { params });
};

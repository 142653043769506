import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DispatchRouteModel } from '@/models/dispatch-route.model';
import { MoveStopAndDispatchRoutesPayload } from '@/store/slices/staff/dispatch-routes.slice';
import { fetchDispatchRoutes } from './fetch-all-dispatch-routes.epic';

export const moveStopAndDispatchRoutes: PayloadEpic<MoveStopAndDispatchRoutesPayload, DispatchRouteModel[]> = (
    action$
) => {
    return action$.pipe(
        filter(asyncActions.dispatchRoutes.moveStopAndFetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.dispatchRoutes.setIsLoading(true)),
                moveStopAndFetchDispatchRoutes(payload).pipe(
                    // switchMap(({ data }) =>
                    //     from([actions.dispatchRoutes.setAll(data), actions.dispatchRoutes.setTotalCount(data?.length)])
                    // ),

                    switchMap(() =>
                        fetchDispatchRoutes({ warehouse: payload.warehouse, date: payload.date }).pipe(
                            switchMap(({ data }) => from([actions.dispatchRoutes.setAll(data)]))
                        )
                    ),

                    catchError((error) => {
                        console.error('moveStopAndDispatchRoutes', error);

                        return EMPTY;
                    })
                ),
                of(actions.dispatchRoutes.setIsLoading(false))
            )
        )
    );
};

const moveStopAndFetchDispatchRoutes = (payload: MoveStopAndDispatchRoutesPayload) => {
    return axios.post<DispatchRouteModel[]>(`${apiEndpoints.reorderDispatchRoutes()}`, payload);
};

import { apiDuck } from '@/shared/ducks/api'

const API_URL = '/api/accounts/resend-email-verification/'

export const duck = apiDuck('emailVerification', API_URL)

export const { actionTypes, actions, reducer, useAPI } = duck

export default {
    ...duck,
}

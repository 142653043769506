import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { UserModel, UserPermissions } from '@/models';

const usersAdapter = createEntityAdapter<UserModel>();

const initialState = usersAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    userPermissions: null as Nullable<UserPermissions>,
    groups: [] as string[],
    isSaving: false,
    savingErrors: null as Nullable<unknown>,

    // todo: add a general type for errors
    resetPasswordErrors: null as Optional<ErrorResponse<ResetPasswordPayload>>,
    isResettingPassword: false,
    isMarkingVerified: false,
});

export type UsersState = typeof initialState;

export interface FetchUserByIdPayload {
    userId: EntityId;
}

export interface FetchAllUsersPayload {
    is_staff?: boolean;
    is_driver?: boolean;
    warehouse__uid?: EntityId;
    page?: number;
    page_size?: number;
}

export interface ChangeUserPermissionPayload {
    userId: EntityId;
    permission: string;
}

export interface ResetPasswordPayload {
    new_password: string;
    userId: EntityId;
}

export interface SetUserGroupPayload {
    userId: EntityId;
    groups: string[];
}

export interface ArchiveUserPayload {
    userId: EntityId;
}

export interface SetUserEmailVerifiedPayload {
    userId: EntityId;
}

export interface CreateUserPayload {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    organization: EntityId;
    is_staff: boolean;
    is_driver: boolean;
}

export interface UpdateUserPayload {
    id: EntityId;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    organization: EntityId;
    is_staff: boolean;
    is_driver: boolean;
}

namespace asyncActions {
    export const fetchAll = createAction<FetchAllUsersPayload>('users/async/fetchAll');
    export const fetchAllPermissions = createAction<void>('users/async/fetchAllPermissions');
    export const fetchGroups = createAction<void>('users/async/fetchGroups');
    export const addPermission = createAction<ChangeUserPermissionPayload>('users/async/addPermission');
    export const revokePermission = createAction<ChangeUserPermissionPayload>('users/async/revokePermission');
    export const setUserGroups = createAction<SetUserGroupPayload>('users/async/setUserGroups');
    export const setUserEmailVerified = createAction<SetUserEmailVerifiedPayload>('users/async/setUserEmailVerified');
    export const resetPassword = createAction<ResetPasswordPayload>('users/async/resetPassword');
    export const createUser = createAction<CreateUserPayload>('users/async/createUser');
    export const updateUser = createAction<UpdateUserPayload>('users/async/updateUser');
    export const fetchById = createAction<FetchUserByIdPayload>('users/async/fetchById');
    export const archiveUser = createAction<ArchiveUserPayload>('users/async/archiveUser')
}

const slice = createSlice({
    name: 'users' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<UserModel[]>) => {
            usersAdapter.setAll(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setUserPermissions: (state, action: PayloadAction<UserPermissions>) => {
            state.userPermissions = action.payload;
        },
        setGroups: (state, action: PayloadAction<string[]>) => {
            state.groups = action.payload;
        },
        updateUser: (state, action: PayloadAction<UserModel>) => {
            usersAdapter.upsertOne(state, action.payload);
        },
        setResetPasswordErrors: (state, action: PayloadAction<Nullable<ErrorResponse<ResetPasswordPayload>>>) => {
            state.resetPasswordErrors = action.payload;
        },
        setIsResettingPassword: (state, action: PayloadAction<boolean>) => {
            state.isResettingPassword = action.payload;
        },
        setIsMarkingVerified: (state, action: PayloadAction<boolean>) => {
            state.isMarkingVerified = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<UserModel>) => {
            usersAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        removeOne: (state, action: PayloadAction<EntityId>) => {
            usersAdapter.removeOne(state, action.payload);
        },
        upsertOne: (state, action: PayloadAction<UserModel>) => {
            usersAdapter.upsertOne(state, action.payload);
        },
    },
});

const usersAdapterSelectors = usersAdapter.getSelectors((state: RootState) => state.users);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.users.isLoading;
    export const permissionLabels = (state: RootState) => state.users.userPermissions?.labels ?? {};
    export const userPermissions = (state: RootState) => state.users.userPermissions;
    export const groups = (state: RootState) => state.users.groups;
    export const resetPasswordErrors = (state: RootState) => state.users.resetPasswordErrors;
    export const isResettingPassword = (state: RootState) => state.users.isResettingPassword;
    export const totalCount = (state: RootState) => state.users.totalCount;
    export const isMarkingVerified = (state: RootState) => state.users.isMarkingVerified;
    export const isSaving = (state: RootState) => state.users.isSaving;
    export const savingErrors = (state: RootState) => state.users.savingErrors;
}

export const usersSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...usersAdapterSelectors,
        ...sliceSelectors,
    },
};

import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { AssetModel } from '@/models';
import {
    CreateComponentAssetPayload,
    CreateMealAssetPayload,
    CreatePackageAssetPayload,
    PatchAssetPayload,
    PatchComponentAssetPayload,
} from '@/store/slices/payload-types/asset-payload-types';

export interface FetchAllAssetsPayload {
    page?: number;
    page_size?: number;
    type?: string;
}

const assetsAdapter = createEntityAdapter<AssetModel>({
    selectId: (assets) => assets.uid,
});

const initialState = assetsAdapter.getInitialState({
    isLoading: false,
    isCreating: false,
    savingErrors: null as Nullable<unknown>,
    isLoadingAsset: false,
    totalCount: 0,
    isDeletingAsset: false,
    isUpdatingAsset: false,
    assetUpdateErrors: null as Nullable<unknown>,
});

export type AssetsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllAssetsPayload>('assets/async/fetchAll');
    export const fetchAssetById = createAction<EntityId>('assets/async/fetchAssetById');
    export const createComponent = createAction<CreateComponentAssetPayload>('assets/async/createComponent');
    export const createMeal = createAction<CreateMealAssetPayload>('assets/async/createMeal');
    export const createPackageAsset = createAction<CreatePackageAssetPayload>('assets/async/createPackageAsset');
    export const patchAsset = createAction<PatchComponentAssetPayload>('assets/async/patchAsset');
    export const patchMeal = createAction<PatchAssetPayload>('assets/async/patchMeal');
    export const deleteAsset = createAction<EntityId>('assets/async/deleteAsset');
}

const slice = createSlice({
    name: 'assets' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsCreating: (state, action: PayloadAction<boolean>) => {
            state.isCreating = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        setAll: (state, action: PayloadAction<AssetModel[]>) => {
            assetsAdapter.setAll(state, action.payload);
        },
        addOne: (state, action: PayloadAction<AssetModel>) => {
            assetsAdapter.addOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setIsLoadingAsset: (state, action: PayloadAction<boolean>) => {
            state.isLoadingAsset = action.payload;
        },
        upsertOne: (state, action: PayloadAction<AssetModel>) => {
            assetsAdapter.upsertOne(state, action.payload);
        },
        deleteComponent: (state, action: PayloadAction<EntityId>) => {
            assetsAdapter.removeOne(state, action.payload);
        },
        setIsDeletingAsset: (state, action: PayloadAction<boolean>) => {
            state.isDeletingAsset = action.payload;
        },
        setIsUpdatingAsset: (state, action: PayloadAction<boolean>) => {
            state.isUpdatingAsset = action.payload;
        },
        setAssetUpdateErrors: (state, action: PayloadAction<unknown>) => {
            state.assetUpdateErrors = action.payload;
        },
    },
});

const assetsAdapterSelectors = assetsAdapter.getSelectors((state: RootState) => state.assets);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.assets.isLoading;
    export const isCreating = (state: RootState) => state.assets.isCreating;
    export const savingErrors = (state: RootState) => state.assets.savingErrors;
    export const isLoadingAsset = (state: RootState) => state.assets.isLoadingAsset;
    export const totalCount = (state: RootState) => state.assets.totalCount;
    export const isDeletingAsset = (state: RootState) => state.assets.isDeletingAsset;
    export const isUpdatingAsset = (state: RootState) => state.assets.isUpdatingAsset;
    export const assetUpdateErrors = (state: RootState) => state.assets.assetUpdateErrors;
}

export const assetsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...assetsAdapterSelectors,
        ...sliceSelectors,
    },
};

import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { MealSlotModel } from '@/models';

export interface FetchAllMealSlotsPayload {
    page?: number;
    page_size?: number;
    warehouse__uid?: EntityId | string;
    meal_time__in?: string;
}

export interface CreateMealSlotPayload {
    warehouse: string;
    meal_time: string;
    meal_subtype: string;
    price?: number;
    price_in_cents: Nullable<number | string>;
    variation_stub: string;
    color_hexcode: string;
    is_hot_preparation: boolean;
    packing_tote?: string;
    qty_per_tote: number;
    enabled: boolean;
}

export interface RemoveMealSlotPayload {
    id: EntityId;
}

const mealSlotsAdapter = createEntityAdapter<MealSlotModel>({
    selectId: (mealSlot) => mealSlot.id,
});

const initialState = mealSlotsAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type MealSlotsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllMealSlotsPayload>('mealSlots/async/fetchAll');
    export const create = createAction<CreateMealSlotPayload>('mealSlots/async/create');
    export const remove = createAction<RemoveMealSlotPayload>('mealSlots/async/remove');
}

const slice = createSlice({
    name: 'mealSlots' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<MealSlotModel[]>) => {
            mealSlotsAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<MealSlotModel>) => {
            mealSlotsAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<MealSlotModel>) => {
            mealSlotsAdapter.upsertOne(state, action.payload);
        },
        removeOne: (state, action: PayloadAction<EntityId>) => {
            mealSlotsAdapter.removeOne(state, action.payload);
        },
    },
});

const mealSlotsAdapterSelectors = mealSlotsAdapter.getSelectors((state: RootState) => state.mealSlots);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.mealSlots.isLoading;
    export const isSaving = (state: RootState) => state.mealSlots.isSaving;
    export const savingErrors = (state: RootState) => state.mealSlots.savingErrors;
    export const totalCount = (state: RootState) => state.mealSlots.totalCount;
}

export const mealSlotsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...mealSlotsAdapterSelectors,
        ...sliceSelectors,
    },
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';
import { FetchServingMenuByIdPayload } from "@/store/slices/staff/serving-menus.slice";

export const fetchServingMenuByIdEpic: PayloadEpic<FetchServingMenuByIdPayload, ServingMenuModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.fetchById.match),
        switchMap(({ payload }) => concat(
            of(actions.servingMenus.setIsLoading(true)),
            fetchMenuById(payload.menuId).pipe(
                map(({ data }) => actions.servingMenus.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchServingMenuByIdEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.servingMenus.setIsLoading(false)),
        )),
    );
};

const fetchMenuById = (menuId: EntityId) => {
    return axios.get<ServingMenuModel>(apiEndpoints.servingMenu(menuId));
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { CreateDriverPayload } from '@/store/slices/staff/drivers.slice';

export const createDriverEpic: PayloadEpic<CreateDriverPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.drivers.createDriver.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.drivers.setIsSaving(true)),
                createDriver(payload).pipe(
                    switchMap(({ data }) => [actions.drivers.addOne(data), actions.drivers.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createDriverEpic', error);

                        return of(actions.drivers.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.drivers.setIsSaving(false))
            )
        )
    );
};

const createDriver = (payload: CreateDriverPayload) => {
    return axios.post<UserModel>(apiEndpoints.drivers(), payload);
};

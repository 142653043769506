import { jsonClone } from '@/utils/object-utils';

export const addObjectToUrlSearchParams = (obj: any, urlSearchParams: URLSearchParams) => {
    for (const [key, value] of Object.entries(obj)) {
        if (value === undefined) {
            continue;
        }

        if (!Array.isArray(value)) {
            urlSearchParams.set(key, String(value));
            continue;
        }

        urlSearchParams.delete(key);
        for (const val of value) {
            urlSearchParams.append(key, val);
        }
    }
};

export const objectToUrlSearchParams = (obj: any) => {
    const urlSearchParams = new URLSearchParams();

    addObjectToUrlSearchParams(obj, urlSearchParams);

    return urlSearchParams;
};

type GetSearchParams = {
    (urlSearchParams: URLSearchParams): Record<string, string>;
    <T extends string>(urlSearchParams: URLSearchParams, keys: T[]): Record<T, string>;
};

export const getSearchParamsAsObject: GetSearchParams = (urlSearchParams: URLSearchParams, keys = Array.from(urlSearchParams.keys())) => {
    const result = {} as Record<string, string | undefined>;

    for (const key of keys) {
        if (urlSearchParams.has(key)) {
            result[key] = urlSearchParams.get(key) ?? undefined;
        }
    }

    return jsonClone(result) as Record<string, string>;
};

import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { StatisticsModel } from '@/models';

export const fetchStatisticsByStaffInvoiceIdEpic: PayloadEpic<EntityId> = (action$) => {
    return action$.pipe(
        filter(asyncActions.staffInvoices.fetchStatisticsByInvoiceId.match),
        map(({ payload }) => payload),
        // distinctUntilChanged(),
        switchMap((invoiceUid) =>
            concat(
                // of(actions.staffInvoices.setIsLoading(true)),
                fetchStatisticsByInvoiceId(invoiceUid).pipe(
                    map(({ data }) => actions.staffInvoices.setStatistics(data)),
                    catchError((error) => {
                        console.error('fetchStatisticsByStaffInvoiceIdEpic', error);

                        return EMPTY;
                    })
                )
                // of(actions.staffInvoices.setIsLoading(false)),
            )
        )
    );
};

const fetchStatisticsByInvoiceId = (invoiceUid: EntityId) => {
    return axios.get<StatisticsModel>(apiEndpoints.staffInvoiceStatistics(invoiceUid));
};

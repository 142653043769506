import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { MenuTemplateModel } from '@/models/menu-template.model';
import { FetchMenuTemplateByIdPayload } from '@/store/slices/staff/menu-template.slice';

export const fetchMenuTemplateByIdEpic: PayloadEpic<FetchMenuTemplateByIdPayload, MenuTemplateModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.menuTemplates.fetchById.match),
        switchMap(({ payload }) => concat(
            of(actions.menuTemplates.setIsLoading(true)),
            fetchMenuTemplateById(payload.menuId).pipe(
                map(({ data }) => actions.menuTemplates.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchMenuTemplateByIdEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.menuTemplates.setIsLoading(false)),
        )),
    );
};

const fetchMenuTemplateById = (menuId: EntityId) => {
    return axios.get<MenuTemplateModel>(apiEndpoints.menuTemplate(menuId));
};

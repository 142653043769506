import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ResetPasswordPayload } from '@/store/slices/staff/users.slice';

export const resetPasswordEpic: PayloadEpic<ResetPasswordPayload> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.resetPassword.match),
        switchMap(({ payload }) => concat(
            of(actions.users.setIsResettingPassword(true)),
            resetPasswordRequest(payload).pipe(
                switchMap(() => EMPTY),
                catchError((error) => of(actions.users.setResetPasswordErrors(error.response?.data))),
            ),
            of(actions.users.setIsResettingPassword(false)),
        )),
    );
};

const resetPasswordRequest = (payload: ResetPasswordPayload) => {
    return axios.post<void>(apiEndpoints.resetPassword(payload.userId), { new_password: payload.new_password });
};

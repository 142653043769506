import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { PricingProfileModel } from '@/models';
import { FetchPricingProfileByIdPayload } from '@/store/slices/staff/pricing-profiles.slice';

export const fetchPricingProfileByIdEpic: PayloadEpic<FetchPricingProfileByIdPayload, PricingProfileModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.fetchPricingProfileById.match),
        switchMap(({ payload }) => concat(
            of(actions.pricingProfiles.setIsLoading(true)),
            fetchPricingProfileByIdRequest(payload.pricingProfileId).pipe(
                map(({ data }) => actions.pricingProfiles.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchPricingProfileByIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoading(false)),
        )),
    );
};

const fetchPricingProfileByIdRequest = (pricingProfileId: EntityId) => {
    return axios.get<PricingProfileModel>(apiEndpoints.pricingProfile(pricingProfileId));
};

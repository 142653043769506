import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ManufacturerModel } from '@/models/manufacturer.model';
import { CreateManufacturerPayload } from '@/store/slices/payload-types/asset-payload-types';

export const createManufacturerEpic: PayloadEpic<CreateManufacturerPayload, ManufacturerModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.manufacturers.createManufacturer.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.manufacturers.setIsCreatingManufacturer(true)),
                createManufacturer(payload).pipe(
                    map(({ data }) => actions.manufacturers.addManufacturer(data)),
                    catchError((error) => {
                        console.error('createManufacturerEpic', error);

                        return of(actions.manufacturers.setCreateManufacturerErrors(error));
                    })
                ),
                of(actions.manufacturers.setIsCreatingManufacturer(false))
            )
        )
    );
};

const createManufacturer = (data: CreateManufacturerPayload) => {
    return axios.post<ManufacturerModel>(apiEndpoints.manufacturers(), data);
};

import { combineEpics } from 'redux-observable';
import { fetchStatisticsByStaffInvoiceIdEpic } from './fetch-statistics-by-invoice-id.epic';
import { runUpdateForExpiredOrdersEpic } from '@/store/epics/staff/invoices/post-update-for-expired-orders.epic';
import { updateInvoiceContractEpic } from '@/store/epics/staff/invoices/update-invoice-contract.epic';

export const combinedInvoicesEpics = combineEpics(
    fetchStatisticsByStaffInvoiceIdEpic,
    runUpdateForExpiredOrdersEpic,
    updateInvoiceContractEpic
);

import { MenuTemplateModel } from '@/models/menu-template.model';
import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

export interface FetchAllMenuTemplatesPayload {
    page?: number;
    page_size?: number;
}

export interface CreateMenuTemplatePayload {
    meal_slot: number;
    override_packing_tote: string;
    override_qty_per_tote: string;
    assets: Array<any>;
    title: string;
}

export interface RemoveMenuTemplatePayload {
    id: EntityId;
}

export interface UpdateMenuTemplatePayload extends CreateMenuTemplatePayload {
    id: MenuTemplateModel['id'];
}

export interface FetchMenuTemplateByIdPayload {
    menuId: EntityId;
}

const menuTemplatesAdapter = createEntityAdapter<MenuTemplateModel>({
    selectId: (menuTemplate) => menuTemplate.id,
});

const initialState = menuTemplatesAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type MenuTemplatesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllMenuTemplatesPayload>('menuTemplates/async/fetchAll');
    export const create = createAction<CreateMenuTemplatePayload>('menuTemplates/async/create');
    export const update = createAction<UpdateMenuTemplatePayload>('menuTemplates/async/update');
    export const fetchById = createAction<FetchMenuTemplateByIdPayload>('menuTemplates/async/fetchById');
    export const remove = createAction<RemoveMenuTemplatePayload>('menuTemplates/async/remove');
}

const slice = createSlice({
    name: 'menuTemplates' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<MenuTemplateModel[]>) => {
            menuTemplatesAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<MenuTemplateModel>) => {
            menuTemplatesAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<MenuTemplateModel>) => {
            menuTemplatesAdapter.upsertOne(state, action.payload);
        },
        removeOne: (state, action: PayloadAction<EntityId>) => {
            menuTemplatesAdapter.removeOne(state, action.payload);
        },
    },
});

const menuTemplatesAdapterSelectors = menuTemplatesAdapter.getSelectors((state: RootState) => state.menuTemplates);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.menuTemplates.isLoading;
    export const isSaving = (state: RootState) => state.menuTemplates.isSaving;
    export const savingErrors = (state: RootState) => state.menuTemplates.savingErrors;
    export const totalCount = (state: RootState) => state.menuTemplates.totalCount;
}

export const menuTemplatesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...menuTemplatesAdapterSelectors,
        ...sliceSelectors,
    },
};

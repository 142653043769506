import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { SlimAssetModel } from '@/models';
import { FetchAllSlimAssetsPayload } from '@/store/slices/staff/slim-assets.slice';

export const fetchAllSlimAssetsEpic: PayloadEpic<FetchAllSlimAssetsPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.slimAssets.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.slimAssets.setIsLoading(true)),
                fetchContracts(payload).pipe(
                    switchMap(({ data }) => from([actions.slimAssets.setAll(data), actions.slimAssets.setTotalCount(data.length)])),
                    catchError((error) => {
                        console.error('fetchAllSlimAssetsEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.slimAssets.setIsLoading(false))
            )
        )
    );
};

const fetchContracts = (payload: FetchAllSlimAssetsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<SlimAssetModel[]>(`${apiEndpoints.slimAssets()}?${searchParams}`);
};

import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SetUserGroupPayload } from '@/store/slices/staff/users.slice';
import { UserModel } from '@/models';

export const updateUserGroupsEpic: PayloadEpic<SetUserGroupPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.setUserGroups.match),
        debounceTime(500),
        switchMap(({ payload }) => concat(
            of(actions.users.setIsLoading(true)),
            updateUserGroupsRequest(payload).pipe(
                map(({ data }) => actions.users.updateUser(data)),
                catchError((error) => {
                    console.error('updateUserGroupsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.users.setIsLoading(false)),
        )),
    );
};

const updateUserGroupsRequest = (payload: SetUserGroupPayload) => {
    return axios.post<UserModel>(apiEndpoints.setUserGroups(payload.userId), { groups: payload.groups });
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ArchivePricingProfilePayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const archivePricingProfileEpic: PayloadEpic<ArchivePricingProfilePayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.archivePricingProfile.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.pricingProfiles.setIsArchivingPricingProfile(true)),
                of(actions.pricingProfiles.setSavingPricingProfileErrors(null)),
                archivePricingProfile(payload).pipe(
                    map(() => actions.pricingProfiles.archivePricingProfile(payload)),
                    catchError((error) => {
                        return of(actions.pricingProfiles.setSavingPricingProfileErrors(error.response.data));
                    })
                ),
                of(actions.pricingProfiles.setIsArchivingPricingProfile(false))
            )
        )
    );
};

const archivePricingProfile = (payload: ArchivePricingProfilePayload) => {
    return axios.delete<unknown>(apiEndpoints.pricingProfile(payload.uid));
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';
import { FetchUserByIdPayload } from '@/store/slices/staff/users.slice';

export const fetchUserByIdEpic: PayloadEpic<FetchUserByIdPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.fetchById.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.users.setIsLoading(true)),
                fetchUserById(payload.userId).pipe(
                    map(({ data }) => actions.users.upsertOne(data)),
                    catchError((error) => {
                        console.error('fetchUserByIdEpic', error);
                        return EMPTY;
                    })
                ),
                of(actions.users.setIsLoading(false))
            )
        )
    );
};

const fetchUserById = (userId: EntityId) => {
    return axios.get<UserModel>(apiEndpoints.user(userId));
};

import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { AuditCommentModel, ContractModel, SubscriptionModel, WarehouseModel } from '@/models';
import { CreateContractPayload } from '@/store/slices/payload-types/CreateContractPayload';
import { CreateSubscriptionPayload } from '@/store/slices/payload-types/CreateSubscriptionPayload';
import { UpdateContractPayload } from '@/store/slices/payload-types/UpdateContractPayload';
import { CreateCommentPayload } from '@/store/slices/payload-types/CreateCommentPayload';

export interface FetchAllContractsPayload {
    page?: number;
    page_size?: number;
    org_unit__organization__uid?: string;
}

export interface FetchAllSubscriptionsPayload {
    page?: number;
    page_size?: number;
}

export interface FetchContractByIdPayload {
    contractId: EntityId;
}

export interface SetCommentsByContractIdPayload {
    contractId: EntityId;
    comments: AuditCommentModel[];
}

export interface AddCommentByContractIdPayload {
    contractId: EntityId;
    comment: AuditCommentModel;
}

const contractsAdapter = createEntityAdapter<ContractModel>({
    selectId: (contract) => contract.uid,
});

const initialState = contractsAdapter.getInitialState({
    isLoading: false,
    isCreatingContract: false,
    isUpdatingContract: false,
    saveContractErrors: {} as Nullable<unknown>,
    facilities: [] as WarehouseModel[],
    totalCount: 0,
    subscriptions: [] as SubscriptionModel[],
    subscriptionsTotalCount: 0,
    selectedContract: null as Optional<ContractModel>,
    isLoadingComments: false,
    isCreatingComment: false,
    isCreatingSubscription: false,
});

export type ContractsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllContractsPayload>('contracts/async/fetchAll');
    export const fetchContractById = createAction<FetchContractByIdPayload>('public/contracts/async/fetchContractById');
    export const createSalesContract = createAction<CreateContractPayload>('contracts/async/createSalesContract');
    export const updateSalesContract = createAction<UpdateContractPayload>('contracts/async/updateSalesContract');
    export const fetchFacilities = createAction<void>('contracts/async/fetchFacilities');
    export const fetchAllSubscriptions = createAction<FetchAllContractsPayload>(
        'contracts/async/fetchAllSubscriptions'
    );
    export const createSubscription = createAction<CreateSubscriptionPayload>('contracts/async/createSubscription');
    export const createComment = createAction<CreateCommentPayload>('contracts/async/createComment');
    export const deleteSubscription = createAction<EntityId>('contracts/async/deleteSubscription');
    export const fetchCommentsById = createAction<EntityId>('contracts/async/fetchCommentsById');
}

const slice = createSlice({
    name: 'contracts' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<ContractModel[]>) => {
            contractsAdapter.setAll(state, action.payload);
        },
        setIsCreatingContract: (state, action: PayloadAction<boolean>) => {
            state.isCreatingContract = action.payload;
        },
        setIsUpdatingContract: (state, action: PayloadAction<boolean>) => {
            state.isUpdatingContract = action.payload;
        },
        setSaveContractErrors: (state, action: PayloadAction<unknown>) => {
            state.saveContractErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<ContractModel>) => {
            contractsAdapter.addOne(state, action.payload);
        },
        setFacilities: (state, action: PayloadAction<WarehouseModel[]>) => {
            state.facilities = action.payload;
        },
        upsertOne: (state, action: PayloadAction<ContractModel>) => {
            contractsAdapter.upsertOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAllSubscriptions: (state, action: PayloadAction<SubscriptionModel[]>) => {
            state.subscriptions = action.payload;
        },
        addSubscription: (state, action: PayloadAction<SubscriptionModel>) => {
            state.subscriptionsTotalCount++;
            state.subscriptions.unshift(action.payload);
        },
        deleteSubscription: (state, action: PayloadAction<EntityId>) => {
            const uid = action.payload;

            state.subscriptions = state.subscriptions.filter((subscription) => subscription.uid !== uid);
            state.subscriptionsTotalCount--;
        },
        setSubscriptionsTotalCount: (state, action: PayloadAction<number>) => {
            state.subscriptionsTotalCount = action.payload;
        },
        setSelectedContract: (state, action: PayloadAction<Nullable<ContractModel>>) => {
            state.selectedContract = action.payload;
        },
        setIsLoadingComments: (state, action: PayloadAction<boolean>) => {
            state.isLoadingComments = action.payload;
        },
        setIsCreatingComment: (state, action: PayloadAction<boolean>) => {
            state.isCreatingComment = action.payload;
        },
        setCommentsByContractId: (state, action: PayloadAction<SetCommentsByContractIdPayload>) => {
            contractsAdapter.updateOne(state, {
                id: action.payload.contractId,
                changes: {
                    comments: action.payload.comments,
                },
            });
        },
        addCommentToContract: (state, action: PayloadAction<AddCommentByContractIdPayload>) => {
            const contract = state.entities[action.payload.contractId];
            contract!.comments!.push(action.payload.comment);
        },
        setIsCreatingSubscription: (state, action: PayloadAction<boolean>) => {
            state.isCreatingSubscription = action.payload;
        },
    },
});

const contractsAdapterSelectors = contractsAdapter.getSelectors((state: RootState) => state.contracts);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.contracts.isLoading;
    export const isCreatingContract = (state: RootState) => state.contracts.isCreatingContract;
    export const isUpdatingContract = (state: RootState) => state.contracts.isUpdatingContract;
    export const saveContractErrors = (state: RootState) => state.contracts.saveContractErrors;
    export const facilities = (state: RootState) => state.contracts.facilities;
    export const totalCount = (state: RootState) => state.contracts.totalCount;
    export const subscriptionsTotalCount = (state: RootState) => state.contracts.subscriptionsTotalCount;
    export const subscriptions = (state: RootState) => state.contracts.subscriptions;
    export const selectedContract = (state: RootState) => state.contracts.selectedContract;
    export const selectCommentsByContractId = (state: RootState, contractId: EntityId) =>
        contractsAdapterSelectors.selectById(state, contractId)?.comments;
    export const isLoadingComments = (state: RootState) => state.contracts.isLoadingComments;
    export const isCreatingComment = (state: RootState) => state.contracts.isCreatingComment;
    export const isCreatingSubscription = (state: RootState) => state.contracts.isCreatingSubscription;
}

export const contractsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...contractsAdapterSelectors,
        ...sliceSelectors,
    },
};

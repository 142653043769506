import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DeliverySiteModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';
import { FetchDeliverySiteByIdPayload } from "@/store/slices/staff/delivery-sites.slice";

export const fetchDeliverySiteByIdEpic: PayloadEpic<FetchDeliverySiteByIdPayload, DeliverySiteModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.deliverySites.fetchDeliverySiteById.match),
        switchMap(({ payload }) => concat(
            of(actions.deliverySites.setIsLoading(true)),
            fetchDeliverySiteById(payload.deliverySiteId).pipe(
                map(({ data }) => actions.deliverySites.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchDeliverySiteByIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.deliverySites.setIsLoading(false)),
        )),
    );
};

const fetchDeliverySiteById = (deliverySiteId: EntityId) => {
    return axios.get<DeliverySiteModel>(apiEndpoints.deliverySite(deliverySiteId));
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { CreateServingMenuPayload } from "@/store/slices/staff/serving-menus.slice";

export const createServingMenuEpic: PayloadEpic<CreateServingMenuPayload, ServingMenuModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.create.match),
        switchMap(({ payload }) => concat(
            of(actions.servingMenus.setIsSaving(true)),
            createServingMenu(payload).pipe(
                switchMap(({ data }) => [
                    actions.servingMenus.addOne(data),
                    actions.servingMenus.setSavingErrors(null),
                ]),
                catchError((error) => {
                    console.error('createServingMenuEpic', error);

                    return of(actions.servingMenus.setSavingErrors(error.response.data));
                }),
            ),
            of(actions.servingMenus.setIsSaving(false)),
        )),
    );
};

const createServingMenu = (payload: CreateServingMenuPayload) => {
    return axios.post<ServingMenuModel>(apiEndpoints.servingMenus(), payload);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { PurchaseAssetLotPayload } from '@/store/slices/payload-types/asset-payload-types';
import { AssetLotModel } from '@/models';

export const purchaseComponentAssetLotEpic: PayloadEpic<PurchaseAssetLotPayload, AssetLotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.purchaseAssetLot.match),
        switchMap(({ payload }) => concat(
            of(actions.assetLots.setIsSaving(true)),
            purchaseComponentAssetLot(payload).pipe(
                map(({ data }) => actions.assetLots.addOne(data)),
                catchError((error) => {
                    console.error('purchaseComponentAssetLotEpic', error);

                    return of(actions.assetLots.setSavingErrors(error));
                }),
            ),
            of(actions.assetLots.setIsSaving(false)),
        )),
    );
};

const purchaseComponentAssetLot = (payload: PurchaseAssetLotPayload) => {
    return axios.post<AssetLotModel>(apiEndpoints.purchaseAssetLot(payload.facilityUid), payload.data);
};

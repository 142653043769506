import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetLotModel } from '@/models';
import { FetchAllAssetLotsPayload } from '@/store/slices/staff/asset-lots.slice';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllAssetLotsEpic: PayloadEpic<FetchAllAssetLotsPayload, AssetLotModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assetLots.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.assetLots.setIsLoading(true)),
            fetchAssetLots(payload).pipe(
                switchMap(({ data }) => from([
                    actions.assetLots.setAll(data.results),
                    actions.assetLots.setTotalCount(data.count),
                ])),
                catchError((error) => {
                    console.error('fetchAllAssetLotsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.assetLots.setIsLoading(false)),
        )),
    );
};

const fetchAssetLots = (payload: FetchAllAssetLotsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<AssetLotModel>>(`${apiEndpoints.assetLots()}?${searchParams}`);
};

import { combineEpics } from 'redux-observable';

import { fetchCurrentCartEpic } from './fetch-current-cart.epic';
import { setShippingOptionsEpic } from './set-shipping-options.epic';
import { postClearCartEpic } from './post-clear-cart.epic';
import { postCartCheckoutEpic } from './post-cart-checkout.epic';
import { postAddToCartEpic } from './post-add-to-cart.epic';
import { fetchShippingOptionsEpic } from './fetch-shipping-options.epic';
import { fetchCartFinalCheckoutEpic } from './fetch-cart-final-checkout.epic';

export const combinedCartEpics = combineEpics(
    fetchCurrentCartEpic,
    setShippingOptionsEpic,
    postClearCartEpic,
    postCartCheckoutEpic,
    postAddToCartEpic,
    fetchShippingOptionsEpic,
    fetchCartFinalCheckoutEpic,
);

import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { FetchInvoiceOrdersByIdPayload } from '@/store/slices/payload-types/public-invlices-payload-types';
import { OrderModel } from '@/models';

const ordersAdapter = createEntityAdapter<OrderModel>({
    selectId: (order) => order.uid,
});

const initialState = ordersAdapter.getInitialState({
    isLoadingOrders: null as Nullable<boolean>,
    isLoadingOrderById: null as Nullable<boolean>,
    totalCount: null as Nullable<number>,
});

export type OrdersState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchInvoiceOrdersByIdPayload>('public/orders/async/fetchAll');
    export const fetchOrderById = createAction<EntityId>('public/orders/async/fetchOrderById');
}

const slice = createSlice({
    name: 'orders' as const,
    initialState,
    reducers: {
        setAll: (state, { payload }: PayloadAction<OrderModel[]>) => {
            ordersAdapter.setAll(state, payload);
        },
        setIsLoadingOrders: (state, action: PayloadAction<boolean>) => {
            state.isLoadingOrders = action.payload;
        },
        setIsLoadingOrderById: (state, action: PayloadAction<boolean>) => {
            state.isLoadingOrderById = action.payload;
        },
        upsertOne: (state, action: PayloadAction<OrderModel>) => {
            ordersAdapter.upsertOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
    },
});

const ordersAdapterSelectors = ordersAdapter.getSelectors((state: RootState) => state.orders);

namespace sliceSelectors {
    export const isLoadingOrders = (state: RootState) => state.orders.isLoadingOrders;
    export const isLoadingOrderById = (state: RootState) => state.orders.isLoadingOrderById;
    export const totalCount = (state: RootState) => state.orders.totalCount;
}

export const ordersSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...ordersAdapterSelectors,
        ...sliceSelectors,
    },
};

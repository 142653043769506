import { combineEpics } from 'redux-observable';
import { createCommentEpic } from './create-comment.epic';
import { createSalesContractEpic } from './create-sales-contract.epic';
import { createSubscriptionEpic } from './create-subscription.epic';
import { deleteSubscriptionEpic } from './delete-subscription.epic';
import { fetchAllContractsEpic } from './fetch-all-contracts.epic';
import { fetchAllSubscriptionsEpic } from './fetch-all-subscriptions.epic';
import { fetchCommentsByContractIdEpic } from './fetch-comments-by-contract-id.epic';
import { fetchContractByIdEpic } from './fetch-contract-by-id.epic';
import { fetchFacilitiesEpic } from './fetch-facilities.epic';
import { updateSalesContractEpic } from './update-sales-contract.epic';

export const combinedContractsEpics = combineEpics(
    createCommentEpic,
    createSalesContractEpic,
    createSubscriptionEpic,
    deleteSubscriptionEpic,
    fetchAllContractsEpic,
    fetchAllSubscriptionsEpic,
    fetchCommentsByContractIdEpic,
    fetchContractByIdEpic,
    fetchFacilitiesEpic,
    updateSalesContractEpic,
);

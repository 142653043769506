import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, forkJoin, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetModel, ResourceType } from '@/models';
import { PatchComponentAssetPayload } from '@/store/slices/payload-types/asset-payload-types';
import { withSelector } from '@/utils/rxjs-utils';
import { EntityId } from '@reduxjs/toolkit';

export const patchAssetEpic: PayloadEpic<PatchComponentAssetPayload, AssetModel> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.assets.patchAsset.match),
        switchMap((action) =>
            forkJoin([
                withSelector(state$, (state) => selectors.assets.selectById(state, action.payload.uid)!),
                of(action.payload),
            ])
        ),
        switchMap(([asset, payload]) =>
            concat(
                of(actions.assets.setIsUpdatingAsset(true)),
                of(actions.assets.setAssetUpdateErrors(null)),
                patchAsset(asset.resourcetype, payload).pipe(
                    map(({ data }) => actions.assets.upsertOne(data)),
                    catchError((error) => {
                        return of(actions.assets.setAssetUpdateErrors(error.response.data));
                    })
                ),
                of(actions.assets.setIsUpdatingAsset(false))
            )
        )
    );
};

const patchAsset = (resourceType: ResourceType, payload: PatchComponentAssetPayload) => {
    return axios.patch<AssetModel>(getAssetUrl(resourceType, payload.uid), payload);
};

const getAssetUrl = (resourceType: ResourceType, uid: EntityId) => {
    switch (resourceType) {
        case 'Component':
            return apiEndpoints.componentAsset(uid);
        case 'Meal':
            return apiEndpoints.mealAsset(uid);
        case 'ContainerPackage':
            return apiEndpoints.packageAsset(uid);
    }
};

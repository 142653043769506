import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { ProductModel } from '@/models';

export interface FetchAllProductsPayload {
    search: string
}

const productsAdapter = createEntityAdapter<ProductModel>({
    selectId: (product) => product.uid,
});

const initialState = productsAdapter.getInitialState({
    isLoading: false,
});

export type ProductsSlice = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllProductsPayload>('staff/products/async/fetchAll');
}

const slice = createSlice({
    name: 'productsWithSlice' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<ProductModel[]>) => {
            productsAdapter.setAll(state, action.payload);
        },
    },
});

const productsAdapterSelectors = productsAdapter.getSelectors((state: RootState) => state.productsWithSlice);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.productsWithSlice.isLoading;
}

export const productsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...productsAdapterSelectors,
        ...sliceSelectors,
    },
};

import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction, Update } from '@reduxjs/toolkit';
import { CartMetaInfoModel, CartOrderModel, DeliverToModel } from '@/models';
import { RootState } from '@/store/root-state';

const cartOrdersAdapter = createEntityAdapter<CartOrderModel>({
    selectId: (order) => order.deliver_to.uid ?? '',
});

export interface PostAddToCartPayload {
    product: string;
    shipping_address: string;
    count: number;
}

export interface SetShippingOptionsPayload {
    shipping_address: string;
    shipping_option: string;
}

export interface FetchShippingOptionsPayload {
    shipping_address: EntityId;
}

namespace asyncActions {
    export const fetchCurrent = createAction<void>('public/carts/async/fetchCurrent');
    export const postAddToCart = createAction<PostAddToCartPayload>('public/carts/async/postAddToCart');
    export const fetchCartFinalCheckout = createAction<void>('public/carts/async/fetchCartFinalCheckout');
    export const postCartCheckout = createAction<void>('public/carts/async/postCartCheckout');
    export const postClearCart = createAction<void>('public/carts/async/postClearCart');
    export const fetchShippingOptions = createAction<FetchShippingOptionsPayload>(
        'public/carts/async/fetchShippingOptions'
    );
    export const setShippingOptions = createAction<SetShippingOptionsPayload>('public/carts/async/setShippingOptions');
}

const initialState = {
    isLoading: null as Nullable<boolean>,
    current: {
        isLoading: false,
        metaInfo: null as Nullable<CartMetaInfoModel>,
        orders: cartOrdersAdapter.getInitialState(),
    },
    checkoutPage: 0,
    isCheckingOut: false,
    customerNote: null as Nullable<string>,
    checkoutRedirectUrl: null as Nullable<string>,
    checkoutError: null as Nullable<unknown>,
    setShippingOptionsErrors: null as Nullable<unknown>,
    fetchShippingOptionsErrors: null as Nullable<unknown>,
};

export type CartsState = typeof initialState;

const slice = createSlice({
    name: 'carts',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<Nullable<boolean>>) => {
            state.isLoading = action.payload;
        },
        setIsLoadingCurrentCart: (state, action: PayloadAction<boolean>) => {
            state.current.isLoading = action.payload;
        },
        upsertOne: (state, action: PayloadAction<CartOrderModel>) => {
            cartOrdersAdapter.upsertOne(state.current.orders, action.payload);
        },
        setCurrentCartOrders: (state, action: PayloadAction<CartOrderModel[]>) => {
            cartOrdersAdapter.setAll(state.current.orders, action.payload);
        },
        setCurrentCartMetaInfo: (state, action: PayloadAction<Nullable<CartMetaInfoModel>>) => {
            state.current.metaInfo = action.payload;
        },
        updateCurrentCartOrder: (state, action: PayloadAction<Update<CartOrderModel>>) => {
            cartOrdersAdapter.updateOne(state.current.orders, action.payload);
        },
        setCheckoutPage: (state, action: PayloadAction<number>) => {
            state.checkoutPage = action.payload;
        },
        setIsCheckingOut: (state, action: PayloadAction<boolean>) => {
            state.isCheckingOut = action.payload;
        },
        setCustomerNote: (state, action: PayloadAction<string>) => {
            state.customerNote = action.payload;
        },
        setCheckoutError: (state, action: PayloadAction<Nullable<unknown>>) => {
            state.checkoutError = action.payload;
        },
        setCheckoutRedirectUrl: (state, action: PayloadAction<Nullable<string>>) => {
            state.checkoutRedirectUrl = action.payload;
        },
        setCurrentCartShippingOptions: (state, action: PayloadAction<DeliverToModel>) => {
            const deliverTo = action.payload;

            cartOrdersAdapter.updateOne(state.current.orders, {
                id: deliverTo.uid,
                changes: {
                    deliver_to: deliverTo,
                },
            });
        },
        reset: () => initialState,
        setSetShippingOptionErrors: (state, action: PayloadAction<Optional<unknown>>) => {
            state.setShippingOptionsErrors = action.payload;
        },
        setFetchShippingOptionsErrors: (state, action: PayloadAction<Optional<unknown>>) => {
            state.fetchShippingOptionsErrors = action.payload;
        },
    },
});

const currentCartOrdersSelectors = cartOrdersAdapter.getSelectors((state: RootState) => state.carts.current.orders);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.carts.isLoading;

    export const checkoutPage = (state: RootState) => state.carts.checkoutPage;
    export const isCheckingOut = (state: RootState) => state.carts.isCheckingOut;
    export const customerNote = (state: RootState) => state.carts.customerNote;
    export const checkoutError = (state: RootState) => state.carts.checkoutError;
    export const checkoutRedirectUrl = (state: RootState) => state.carts.checkoutRedirectUrl;
    export const setShippingOptionsErrors = (state: RootState) => state.carts.setShippingOptionsErrors;
    export const fetchShippingOptionsErrors = (state: RootState) => state.carts.fetchShippingOptionsErrors;
}

namespace currentCartSelectors {
    export const isLoading = (state: RootState) => state.carts.current.isLoading;
    export const isLoaded = (state: RootState) => !state.carts.current.isLoading && !!state.carts.current.metaInfo;
    export const metaInfo = (state: RootState) => state.carts.current.metaInfo;
    export const totalQuantity = (state: RootState) => metaInfo(state)?.total_quantity ?? 0;

    export const lineItem = (state: RootState, addressUid: EntityId, productUid: EntityId) => {
        const order = currentCartOrdersSelectors.selectById(state, addressUid);

        // todo: fix returning {}
        // returning {} for backward compatibility.
        return order?.line_items.find((item) => item.product.uid === productUid) ?? {};
    };
    export const shippingOptions = (state: RootState, deliverToUid: EntityId) =>
        currentCartOrdersSelectors.selectById(state, deliverToUid)?.deliver_to.rates;
}

export const cartsSlice = {
    reducer: slice.reducer,
    actions: slice.actions,
    asyncActions,
    selectors: {
        current: {
            ...currentCartOrdersSelectors,
            ...currentCartSelectors,
        },
        ...sliceSelectors,
    },
};

import { combineEpics } from 'redux-observable';

import { fetchAllDeliverySitesEpic } from '@/store/epics/staff/delivery-sites/fetch-all-delivery-sites.epic';
import { fetchDeliverySiteByIdEpic } from '@/store/epics/staff/delivery-sites/fetch-delivery-site-by-id.epic';
import { createDeliverySiteEpic } from '@/store/epics/staff/delivery-sites/create-delivery-site.epic';
import { updateDeliverySiteEpic } from '@/store/epics/staff/delivery-sites/update-delivery-site.epic';

export const combinedDeliverySitesEpics = combineEpics(
    fetchAllDeliverySitesEpic,
    fetchDeliverySiteByIdEpic,
    createDeliverySiteEpic,
    updateDeliverySiteEpic
);

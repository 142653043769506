import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
// import { ServingMenuModel } from '@/models';
import { WarehouseRouteModel } from '@/models';

export interface FetchAllWarehouseRoutesPayload {
    page?: number;
    page_size?: number;
}

export interface CreateWarehouseRoutePayload {
    warehouse: string;
    regular_driver: string;
    delivery_days: number[];
    archived_at: Date | null;
    title: string;
}

export interface UpdateWarehouseRoutePayload extends CreateWarehouseRoutePayload {
    id: WarehouseRouteModel['id'];
}

export interface FetchWarehouseRouteByIdPayload {
    warehouseRouteId: EntityId;
}

const warehouseRoutesAdapter = createEntityAdapter<WarehouseRouteModel>({
    selectId: (warehouseRoute) => warehouseRoute.id,
});

const initialState = warehouseRoutesAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type WarehouseRoutesState = typeof initialState;

// Will change routes when API is done
namespace asyncActions {
    export const fetchAll = createAction<FetchAllWarehouseRoutesPayload>('warehouseRoutes/async/fetchAll');
    export const create = createAction<CreateWarehouseRoutePayload>('warehouseRoutes/async/create');
    export const update = createAction<UpdateWarehouseRoutePayload>('warehouseRoutes/async/update');
    export const fetchById = createAction<FetchWarehouseRouteByIdPayload>('warehouseRoutes/async/fetchById');
}

const slice = createSlice({
    name: 'warehouseRoutes' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<WarehouseRouteModel[]>) => {
            warehouseRoutesAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<WarehouseRouteModel>) => {
            warehouseRoutesAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<WarehouseRouteModel>) => {
            warehouseRoutesAdapter.upsertOne(state, action.payload);
        },
    },
});

const warehouseRoutesAdapterSelectors = warehouseRoutesAdapter.getSelectors((state: RootState) => state.warehouseRoutes);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.warehouseRoutes.isLoading;
    export const isSaving = (state: RootState) => state.warehouseRoutes.isSaving;
    export const savingErrors = (state: RootState) => state.warehouseRoutes.savingErrors;
    export const totalCount = (state: RootState) => state.warehouseRoutes.totalCount;
}

export const warehouseRoutesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...warehouseRoutesAdapterSelectors,
        ...sliceSelectors,
    },
};

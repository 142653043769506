import { combineEpics } from 'redux-observable';
import { fetchAllRouteStopsByRouteId } from '@/store/epics/staff/route-stops/fetch-all-route-stops-by-route-id.epic';
import { createRouteStopEpic } from '@/store/epics/staff/route-stops/create-route-stop.epic';
import { updateRouteStopEpic } from '@/store/epics/staff/route-stops/update-route-stop.epic';
import { fetchRouteStopByIdEpic } from '@/store/epics/staff/route-stops/fetch-route-stop-by-id.epic';
import { deleteRouteStopEpic } from '@/store/epics/staff/route-stops/delete-route-stop.epic';

export const combinedRouteStopsEpics = combineEpics(
    fetchAllRouteStopsByRouteId,
    createRouteStopEpic,
    updateRouteStopEpic,
    fetchRouteStopByIdEpic,
    deleteRouteStopEpic
);

import { combineReducers } from 'redux';
import { reducer as emailVerification } from '@/public/ducks/emailVerification';
import { reducer as registration } from '@/public/ducks/registration';
import { reducer as products } from '@/public/ducks/products';
import { reducer as shippingAddress } from '@/public/ducks/shippingAddress';
import { reducer as bulkAddressGroups } from '@/public/ducks/bulkAddressGroups';
import { reducer as bulkAddressGroupsStatuses } from '@/public/ducks/bulkAddressGroupsStatuses';
import { reducer as bulkAddresses } from '@/public/ducks/bulkAddresses';
import { reducer as bulkInvoices } from '@/public/ducks/bulkInvoices';

import { reducer as staffOrganizations } from '@/staff/ducks/organizations';
import { reducer as staffOrganizationUnits } from '@/staff/ducks/organizationUnits';
import { reducer as staffShippingAddresses, reducer as staffUsers } from '@/staff/ducks/shippingAddresses';
import { reducer as staffInvoices } from '@/staff/ducks/invoices';
import { reducer as staffInvoiceAuditLog } from '@/staff/ducks/invoiceAuditLog';
import { reducer as staffOrders } from '@/staff/ducks/orders';
import { reducer as staffProducts } from '@/staff/ducks/products';
import { reducer as staffComponents } from '@/staff/ducks/components';
import { reducer as staffAllergens } from '@/staff/ducks/allergens';
import { reducer as staffAssets } from '@/staff/ducks/assets';
import { profileSlice } from '@/store/slices/public/profile.slice';
import { usersSlice } from '@/store/slices/staff/users.slice';
import { contractsSlice } from '@/store/slices/staff/contracts.slice';
import { publicContractsSlice } from '@/store/slices/public/contracts.slice';
import { pricingProfilesSlice } from '@/store/slices/staff/pricing-profiles.slice';
import { publicInvoicesSlice } from '@/store/slices/public/invoices.slice';
import { staffInvoicesSlice } from '@/store/slices/staff/invoices.slice';
import { cartsSlice } from '@/store/slices/public/carts.slice';
import { productsSlice } from '@/store/slices/staff/products.slice';
import { assetsSlice } from '@/store/slices/staff/assets.slice';
import { ordersSlice } from '@/store/slices/public/orders.slice';
import { vendorsSlice } from '@/store/slices/staff/vendors.slice';
import { inventoryAllergensSlice } from '@/store/slices/staff/inventory-allergens.slice';
import { assetLotsSlice } from '@/store/slices/staff/asset-lots.slice';
import { mealSlotsSlice } from '@/store/slices/staff/meal-slots.slice';
import { warehouseSlice } from '@/store/slices/staff/warehouses.slice';
import { auditLogsSlice } from '@/store/slices/staff/audit-log.slice';
import { lotAdjustmentSlice } from '@/store/slices/staff/lot-adjustment.slice';
import { servingMenusSlice } from '@/store/slices/staff/serving-menus.slice';
import { deliverySitesSlice } from '@/store/slices/staff/delivery-sites.slice';
import { warehouseRoutesSlice } from '@/store/slices/staff/warehouse-routes.slice';
import { routeStopsSlice } from '@/store/slices/staff/route-stops.slice';
import { dispatchRoutesSlice } from '@/store/slices/staff/dispatch-routes.slice';
import { driverRoutesSlice } from '@/store/slices/driver/driver-routes.slice';
import { manufacturersSlice } from '@/store/slices/staff/manufacturers.slice';
import { driversSlice } from '@/store/slices/staff/drivers.slice';
import { slimAssetsSlice } from '@/store/slices/staff/slim-assets.slice';
import { menuTemplatesSlice } from '@/store/slices/staff/menu-template.slice';

export default combineReducers({
    profile: profileSlice.reducer,
    carts: cartsSlice.reducer,
    users: usersSlice.reducer,
    drivers: driversSlice.reducer,
    publicContracts: publicContractsSlice.reducer,
    contracts: contractsSlice.reducer,
    pricingProfiles: pricingProfilesSlice.reducer,
    publicInvoices: publicInvoicesSlice.reducer,
    productsWithSlice: productsSlice.reducer,
    orders: ordersSlice.reducer,
    assets: assetsSlice.reducer,
    slimAssets: slimAssetsSlice.reducer,
    vendors: vendorsSlice.reducer,
    manufacturers: manufacturersSlice.reducer,
    inventoryAllergens: inventoryAllergensSlice.reducer,
    assetLots: assetLotsSlice.reducer,
    mealSlots: mealSlotsSlice.reducer,
    warehouses: warehouseSlice.reducer,
    auditLogs: auditLogsSlice.reducer,
    lotAdjustmentsInfo: lotAdjustmentSlice.reducer,
    servingMenus: servingMenusSlice.reducer,
    menuTemplates: menuTemplatesSlice.reducer,
    warehouseRoutes: warehouseRoutesSlice.reducer,
    dispatchRoutes: dispatchRoutesSlice.reducer,
    routeStops: routeStopsSlice.reducer,
    deliverySites: deliverySitesSlice.reducer,
    driverRoutes: driverRoutesSlice.reducer,

    // todo: change to staffInvoices after removing the staffInvoices duck.
    staffInvoicesWithSlice: staffInvoicesSlice.reducer,

    emailVerification,
    registration,
    products,
    shippingAddress,
    staffOrganizations,
    staffOrganizationUnits,
    staffShippingAddresses,
    staffUsers,
    staffInvoices,
    staffOrders,
    staffProducts,
    staffComponents,
    staffAllergens,
    bulkAddressGroups,
    bulkAddresses,
    bulkAddressGroupsStatuses,
    bulkInvoices,
    staffInvoiceAuditLog,
    staffAssets,
});

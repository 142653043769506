import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { WarehouseModel } from '@/models';
import { EditWarehousePayload } from '@/store/slices/payload-types/warehouse-payload-types';

export const editWarehouseEpic: PayloadEpic<EditWarehousePayload, WarehouseModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.edit.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouses.setIsSaving(true)),
                editWarehouse(payload).pipe(
                    switchMap(({ data }) => from([actions.warehouses.upsertOne(data)])),
                    catchError((error) => {
                        console.error('editingWarehouseEpic', error);

                        return of(actions.warehouses.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.warehouses.setIsSaving(false))
            )
        )
    );
};

const editWarehouse = (data: EditWarehousePayload) => {
    return axios.put<WarehouseModel>(`${apiEndpoints.warehouses()}${data.uid}/`, data);
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { DeletePricingRulePayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const deletePricingRuleEpic: PayloadEpic<DeletePricingRulePayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.deletePricingRule.match),
        switchMap(({ payload }) => concat(
            of(actions.pricingProfiles.setIsDeletingPricingRule(true)),
            deletePricingRule(payload).pipe(
                map(() => actions.pricingProfiles.deletePricingRule(payload)),
                catchError((error) => {
                    console.error('deletePricingRuleEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.pricingProfiles.setIsDeletingPricingRule(false)),
        )),
    );
};

const deletePricingRule = (payload: DeletePricingRulePayload) => {
    return axios.delete<unknown>(apiEndpoints.pricingRule(payload.pricingRuleId));
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { ArchiveUserPayload } from '@/store/slices/staff/users.slice';

export const archiveUserEpic: PayloadEpic<ArchiveUserPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.archiveUser.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.users.setIsSaving(true)),
                archiveUser(payload).pipe(
                    switchMap(({ data }) => [actions.users.removeOne(payload.userId), actions.users.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('archiveUserEpic', error);

                        return of(actions.users.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.users.setIsSaving(false))
            )
        )
    );
};

const archiveUser = (payload: ArchiveUserPayload) => {
    return axios.post<UserModel>(apiEndpoints.user(payload.userId) + 'deactivate/');
};

import { getEntries } from './common';
import { strictCompareFn } from './compare-values';

export const getObjectDiffs = <T extends {}>(newValue: T, oldValue: T, compareFn = strictCompareFn) => {
    const diffs = {} as Partial<T>;

    for (let [key, value] of getEntries(newValue)) {
        if (!compareFn(value, oldValue[key])) {
            diffs[key] = value;
        }
    }

    return Object.keys(diffs)?.length ? diffs : null;
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { ProfileModel } from '@/models';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';

export const patchProfileDataEpic: PayloadEpic<Partial<ProfileModel>, ProfileModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.profile.patchData.match),
        switchMap(({ payload }) => concat(
            of(actions.profile.setIsLoading(true)),
            patchProfileRequest(payload).pipe(
                map(({ data }) => actions.profile.setData(data)),
                catchError((error) => {
                    console.error('patchProfileDataEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.profile.setIsLoading(false)),
        )),
    );
};

const patchProfileRequest = (payload: Partial<ProfileModel>) => {
    return axios.patch<ProfileModel>(apiEndpoints.profile(), payload);
};

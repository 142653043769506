import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { FetchAllServingMenusPayload } from '@/store/slices/staff/serving-menus.slice';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllServingMenus: PayloadEpic<FetchAllServingMenusPayload, ServingMenuModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.servingMenus.setIsLoading(true)),
                fetchAssetLots(payload).pipe(
                    switchMap(({ data }) => from([actions.servingMenus.setAll(data.results), actions.servingMenus.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllServingMenus', error);

                        return EMPTY;
                    })
                ),
                of(actions.servingMenus.setIsLoading(false))
            )
        )
    );
};

export const fetchAssetLots = (payload: FetchAllServingMenusPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<ServingMenuModel>>(`${apiEndpoints.servingMenus()}?${searchParams}`);
};

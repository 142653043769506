import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SetUserEmailVerifiedPayload, SetUserGroupPayload } from '@/store/slices/staff/users.slice';
import { UserModel } from '@/models';

export const updateUserEmailVerifiedEpic: PayloadEpic<SetUserGroupPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.setUserEmailVerified.match),
        switchMap(({ payload }) => concat(
            of(actions.users.setIsMarkingVerified(true)),
            updateUserEmailVerifiedRequest(payload).pipe(
                map(({ data }) => actions.users.updateUser(data)),
                catchError((error) => {
                    console.error('updateUserEmailVerifiedEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.users.setIsMarkingVerified(false)),
        )),
    );
};

const updateUserEmailVerifiedRequest = (payload: SetUserEmailVerifiedPayload) => {
    return axios.patch<UserModel>(apiEndpoints.user(payload.userId), { is_email_verified: true });
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DispatchRouteModel } from '@/models/dispatch-route.model';
import { ChangeDriverPayload } from '@/store/slices/staff/dispatch-routes.slice';
import lightJoin from 'light-join';
import { fetchDispatchRoutes } from './fetch-all-dispatch-routes.epic';

export const changeDriver: PayloadEpic<ChangeDriverPayload, DispatchRouteModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.dispatchRoutes.changeDriver.match),
        switchMap(({ payload }) =>
            concat(
                changeDriverOfDispatchRoute(payload).pipe(
                    switchMap(() => fetchDispatchRoutes({warehouse: payload.warehouse, date: payload.date}).pipe(
                        switchMap(({ data }) => from([actions.dispatchRoutes.setAll(data)])),
                    )),
                    catchError((error) => {
                        console.error('changeDriverOfDispatchRoutes', error);

                        return EMPTY;
                    })
                )
            )
        )
    );
};

const changeDriverOfDispatchRoute = (payload: ChangeDriverPayload) => {
    return axios.post<DispatchRouteModel>(lightJoin(`${apiEndpoints.dispatchRoute(payload.route)}`, 'change-driver/'), {
        driver: payload.driver,
    });
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { MealSlotModel } from '@/models';
import { CreateMealSlotPayload } from '@/store/slices/staff/meal-slots.slice';

export const createMealSlotEpic: PayloadEpic<CreateMealSlotPayload, MealSlotModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.mealSlots.create.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.mealSlots.setIsSaving(true)),
                createMealSlot(payload).pipe(
                    switchMap(({ data }) => [actions.mealSlots.addOne(data), actions.mealSlots.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createMealSlotEpic', error);

                        return of(actions.mealSlots.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.mealSlots.setIsSaving(false))
            )
        )
    );
};

const createMealSlot = (payload: CreateMealSlotPayload) => {
    return axios.post<MealSlotModel>(apiEndpoints.mealSlots(), payload);
};

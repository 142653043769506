import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { AssetModel } from '@/models';

export const fetchAssetByIdEpic: PayloadEpic<EntityId, AssetModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assets.fetchAssetById.match),
        switchMap(({ payload }) => concat(
            of(actions.assets.setIsLoadingAsset(true)),
            fetchAssetById(payload).pipe(
                map(({ data }) => actions.assets.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchAssetByIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.assets.setIsLoadingAsset(false)),
        )),
    );
};

const fetchAssetById = (componentId: EntityId) => {
    return axios.get<AssetModel>(apiEndpoints.inventoryAsset(componentId));
};

import {useSelector} from 'react-redux';

/* This is an implentation of the Redux "Ducks" pattern"
https://medium.com/@matthew.holman/what-is-redux-ducks-46bcb1ad04b7

Basically, a duck is a small namespace for set of redux state and actions.

Included is the APIDuck, which is a redux store to GET the contents of a
single URL.

See `counter_demo.js` for a basic duck example.

*/

export function simpleActionCreatorCreator(type) {
    return (data) => {
        return { type, data }
    }
}
export function simpleDuckReducer(reducers, defaultState) {
    return (state, action) => {
        if (typeof state === 'undefined')
        {
            state = defaultState;
            if(!action.type)
                return defaultState;
        }
        if (typeof reducers[action.type] === 'function')
        {
            console.log(action.type)
            return reducers[action.type](state, action);
        }
        return state || defaultState;
    }
}

export default function duckBuilder(name, ducklings, defaultState = null) {
    const [actions, actionTypes, reducers] = [{}, {}, {}];
    const selectDuck = (state) => {
        return state[name]
    }
    const useDuckSelector = (selector) => {
        return useSelector(state => {
            const duckState = selectDuck(state);
            if(selector)
                return selector(duckState)
            return duckState
        })
    }
    const namespace = `ducks/${name}`;

    for (let [action, definition] of Object.entries(ducklings)) {
        let reduce, actionCreator;
        let type = `${namespace}/${action}`;

        if (typeof definition === 'function') {
            reduce = definition;
            actionCreator = simpleActionCreatorCreator(type)
        }
        else {
            [actionCreator, reduce] = definition;
        }

        actionTypes[action] = type
        actions[action] = actionCreator
        reducers[type] = reduce
    }
    const reducer = simpleDuckReducer(reducers, defaultState);

    return { name, reducer, actionTypes, actions, reducers, selectDuck, useDuckSelector }
}
import { combineEpics } from 'redux-observable';
import { combinedAssetsCatalogEpics } from './assets';
import { combinedContractsEpics } from './contracts';
import { combinedInvoicesEpics } from './invoices';
import { combinedPricingProfilesEpics } from './pricing-profiles';
import { combinedPricingRulesEpics } from './pricing-rules';
import { combinedProductsEpics } from './products';
import { combinedUsersEpics } from './users';
import { combinedVendorsEpics } from '@/store/epics/staff/assets/vendors';
import { combinedInventoryAllergenEpics } from '@/store/epics/staff/assets/allergens';
import { combineWarehousesEpics } from '@/store/epics/staff/warehouses';
import { combinedAuditLogsEpics } from '@/store/epics/staff/audit-log';
import { combinedLotAdjustmentsLogsEpics } from '@/store/epics/staff/lot-adjustments-audit-log';
import { combinedServingMenusEpics } from '@/store/epics/staff/serving-menus';
import { combinedDeliverySitesEpics } from '@/store/epics/staff/delivery-sites';
import { combinedWarehouseRouteEpics } from '@/store/epics/staff/warehouse-routes';
import { combinedRouteStopsEpics } from '@/store/epics/staff/route-stops';
import { combinedDispatchRouteEpics } from './dispatch-routes';
import { combinedManufacturersEpics } from './assets/manufacturers';
import { combinedDriversEpics } from './drivers';
import { combinedSlimAssetsEpics } from './slim-assets';
import { combinedMenuTemplatesEpics } from './menu-templates';

export const combinedStaffEpics = combineEpics(
    combinedAssetsCatalogEpics,
    combinedSlimAssetsEpics,
    combinedContractsEpics,
    combinedInvoicesEpics,
    combinedPricingProfilesEpics,
    combinedPricingRulesEpics,
    combinedProductsEpics,
    combinedUsersEpics,
    combinedDriversEpics,
    combinedVendorsEpics,
    combinedManufacturersEpics,
    combinedInventoryAllergenEpics,
    combineWarehousesEpics,
    combinedAuditLogsEpics,
    combinedLotAdjustmentsLogsEpics,
    combinedServingMenusEpics,
    combinedMenuTemplatesEpics,
    combinedWarehouseRouteEpics,
    combinedRouteStopsEpics,
    combinedDeliverySitesEpics,
    combinedDispatchRouteEpics
);

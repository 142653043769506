import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DeliverToModel, RateModel } from '@/models';
import { withSelector } from '@/utils/rxjs-utils';

interface PostClearCartResponse {
    deliver_to: DeliverToModel;
    rates: RateModel[];
}

export const postClearCartEpic: PayloadEpic<void> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.postClearCart.match),
        switchMap(() => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
        ])),
        switchMap(([selectedOrgUnit, selectedContract]) => concat(
            of(actions.carts.setIsLoading(true)),
            clearCart(selectedOrgUnit, selectedContract).pipe(
                switchMap(() => from([
                    actions.carts.reset(),
                    asyncActions.carts.fetchCurrent(),
                ])),
                catchError((error) => {
                    console.error('postClearCartEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    );
};

const clearCart = (selectedOrgUnit, selectedContract) => {
    return axios.post<PostClearCartResponse>(
        apiEndpoints.clearCart(selectedOrgUnit!.uid),
        null,
        { params: { sales_contract: selectedContract?.uid } },
    );
};

import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { PublicInvoiceModel } from '@/models';
import { FetchPublicInvoicesPayload } from '@/store/slices/payload-types/FetchPublicInvoicesPayload';
import {
    ProcessPaymentPayload,
    SetInvoiceStatisticsPayload,
} from '@/store/slices/payload-types/public-invlices-payload-types';

const publicInvoicesAdapter = createEntityAdapter<PublicInvoiceModel>({
    selectId: (invoice) => invoice.uid,
});

const initialState = publicInvoicesAdapter.getInitialState({
    isLoadingPublicInvoices: null as Nullable<boolean>,
    totalCount: 0,
    isLoadingInvoice: null as Nullable<boolean>,
    loadInvoiceErrors: null as Nullable<unknown>,

    isProcessingPayment: false,
    processPaymentErrors: null as Nullable<unknown>,
    processPaymentRedirectUrl: null as Nullable<string>,

    isLoadingPaymentToken: false,
    paymentTokenInfo: null as Nullable<unknown>,
});

export type PublicInvoicesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchPublicInvoicesPayload>('public/invoices/async/fetchAll');
    export const fetchStatisticsByInvoiceId = createAction<EntityId>('public/invoices/async/fetchStatisticsByInvoiceId');
    export const fetchInvoiceById = createAction<EntityId>('public/invoices/async/fetchInvoiceById');
    export const processPayment = createAction<ProcessPaymentPayload>('public/invoices/async/processPayment');
    export const fetchPaymentTokenInfo = createAction<EntityId>('public/invoices/async/fetchPaymentTokenInfo');
}

const slice = createSlice({
    name: 'publicInvoices' as const,
    initialState,
    reducers: {
        setAll: (state, action: PayloadAction<PublicInvoiceModel[]>) => {
            publicInvoicesAdapter.setAll(state, action.payload);
        },
        setInvoiceStatistics: (state, { payload }: PayloadAction<SetInvoiceStatisticsPayload>) => {
            const invoice = state.entities[payload.invoiceId]!;
            invoice.statistics = payload.statistics;
        },
        setIsLoadingPublicInvoices: (state, action: PayloadAction<boolean>) => {
            state.isLoadingPublicInvoices = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setIsLoadingInvoice: (state, action: PayloadAction<boolean>) => {
            state.isLoadingInvoice = action.payload;
        },
        upsertOne: (state, action: PayloadAction<PublicInvoiceModel>) => {
            publicInvoicesAdapter.upsertOne(state, action.payload);
        },
        setIsProcessingPayment: (state, action: PayloadAction<boolean>) => {
            state.isProcessingPayment = action.payload;
        },
        setLoadInvoiceErrors: (state, action: PayloadAction<unknown>) => {
            state.loadInvoiceErrors = action.payload;
        },
        setProcessPaymentErrors: (state, action: PayloadAction<unknown>) => {
            state.processPaymentErrors = action.payload;
        },
        setProcessPaymentRedirectUrl: (state, action: PayloadAction<Nullable<string>>) => {
            state.processPaymentRedirectUrl = action.payload;
        },
        setPaymentTokenInfo: (state, action: PayloadAction<Nullable<unknown>>) => {
            state.paymentTokenInfo = action.payload;
        },
        setIsLoadingPaymentToken: (state, action: PayloadAction<boolean>) => {
            state.isLoadingPaymentToken = action.payload;
        },
    },
});

const invoicesAdapterSelectors = publicInvoicesAdapter.getSelectors((state: RootState) => state.publicInvoices);

namespace sliceSelectors {
    export const invoiceStatisticsById = (state: RootState, invoiceId: EntityId) => (
        invoicesAdapterSelectors.selectById(state, invoiceId)?.statistics
    );
    export const isLoadingPublicInvoices = (state: RootState) => state.publicInvoices.isLoadingPublicInvoices;
    export const isLoadingInvoice = (state: RootState) => state.publicInvoices.isLoadingInvoice;
    export const totalCount = (state: RootState) => state.publicInvoices.totalCount;
    export const loadInvoiceErrors = (state: RootState) => state.publicInvoices.loadInvoiceErrors;

    export const isProcessingPayment = (state: RootState) => state.publicInvoices.isProcessingPayment;
    export const processPaymentErrors = (state: RootState) => state.publicInvoices.processPaymentErrors;
    export const processPaymentRedirectUrl = (state: RootState) => state.publicInvoices.processPaymentRedirectUrl;

    export const isLoadingPaymentToken = (state: RootState) => state.publicInvoices.isLoadingPaymentToken;
    export const paymentTokenInfo = (state: RootState) => state.publicInvoices.paymentTokenInfo;
}

export const publicInvoicesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...invoicesAdapterSelectors,
        ...sliceSelectors,
    },
};

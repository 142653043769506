import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { SlimAssetModel } from '@/models';

export interface FetchAllSlimAssetsPayload {
    inventory_warehouse?: string;
}

const slimAssetsAdapter = createEntityAdapter<SlimAssetModel>({
    selectId: (slimAssets) => slimAssets.uid,
});

const initialState = slimAssetsAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
});

export type SlimAssetsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllSlimAssetsPayload>('slimAssets/async/fetchAll');
    export const fetchAssetById = createAction<EntityId>('slimAssets/async/fetchAssetById');
}

const slice = createSlice({
    name: 'slimAssets' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<SlimAssetModel[]>) => {
            slimAssetsAdapter.setAll(state, action.payload);
        },
        addOne: (state, action: PayloadAction<SlimAssetModel>) => {
            slimAssetsAdapter.addOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        upsertOne: (state, action: PayloadAction<SlimAssetModel>) => {
            slimAssetsAdapter.upsertOne(state, action.payload);
        },
    },
});

const slimAssetsAdapterSelectors = slimAssetsAdapter.getSelectors((state: RootState) => state.slimAssets);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.slimAssets.isLoading;
    export const totalCount = (state: RootState) => state.slimAssets.totalCount;
}

export const slimAssetsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...slimAssetsAdapterSelectors,
        ...sliceSelectors,
    },
};

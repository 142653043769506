import { viewsetDuck } from '@/shared/ducks/viewsetDuck';

export const DEFAULT_STATE = {};

export const duck = viewsetDuck('bulkInvoices', '/api/invoices/');

export const useBulkDisableFailuresApi = uid => {
    return duck.useAPI(`${uid}/disable-orders-addresses/`, { suspense: false });
};

export const useCreateBulkFulfillmentInvoice = () => {
    return duck.useAPI(`create-bulkfulfillment-invoice/`, { suspense: false });
};

export const {
    actionTypes,
    actions,
    reducer,
    useAPI,
} = duck;

export default duck;

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { CartMetaInfoModel, CartModel, CartOrderModel } from '@/models';
import { withSelector } from '@/utils/rxjs-utils';

export type FetchCartFinalCheckoutEpicOutput = CartOrderModel[] | CartMetaInfoModel;

export const fetchCartFinalCheckoutEpic: PayloadEpic<void, FetchCartFinalCheckoutEpicOutput> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.fetchCartFinalCheckout.match),
        switchMap(() => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
        ])),
        switchMap(([selectedOrgUnit, selectedContract]) => concat(
            of(actions.carts.setIsLoading(true)),
            fetchCartFinalCheckout(selectedOrgUnit, selectedContract).pipe(
                switchMap(({ data }) => from([
                    ...data.orders.map((order) => actions.carts.updateCurrentCartOrder({
                        id: order.deliver_to.uid,
                        changes: getShippingInfoFromOrder(order),
                    })),
                    actions.carts.setCurrentCartMetaInfo(data),
                ])),
                catchError((error) => {
                    console.error('fetchCartFinalCheckoutEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    )
        ;
};

const fetchCartFinalCheckout = (selectedOrgUnit, selectedContract) => {
    return axios.get<CartModel>(
        apiEndpoints.cartFinalCheckout(selectedOrgUnit!.uid),
        { params: { sales_contract: selectedContract?.uid } },
    );
};

const getShippingInfoFromOrder = (order: CartOrderModel) => ({
    shipping_carrier: order.shipping_carrier,
    shipping_cost_in_cents: order.shipping_cost_in_cents,
    shipping_method: order.shipping_method,
    shipping_option: order.shipping_option,
    shipping_rate: order.shipping_rate,
});

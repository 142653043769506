import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ChangeUserPermissionPayload } from '@/store/slices/staff/users.slice';
import { UserModel } from '@/models';

export const addPermissionEpic: PayloadEpic<ChangeUserPermissionPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.addPermission.match),
        switchMap(({ payload }) => concat(
            of(actions.users.setIsLoading(true)),
            addPermissionRequest(payload).pipe(
                map(({ data }) => actions.users.updateUser(data)),
                catchError((error) => {
                    console.error('addPermissionEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.users.setIsLoading(false)),
        )),
    );
};

const addPermissionRequest = (payload: ChangeUserPermissionPayload) => {
    return axios.post<UserModel>(apiEndpoints.addPermission(payload.userId), { permission: payload.permission });
};

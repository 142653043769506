import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { SubscriptionModel } from '@/models';
import { CreateSubscriptionPayload } from '@/store/slices/payload-types/CreateSubscriptionPayload';

export const createSubscriptionEpic: PayloadEpic<CreateSubscriptionPayload, SubscriptionModel[]> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.contracts.createSubscription.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsCreatingSubscription(true)),
            createSubscription(payload).pipe(
                map(({ data }) => actions.contracts.addSubscription(data)),
                catchError((error) => {
                    console.error('createSubscriptionEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsCreatingSubscription(false)),
        )),
    );
};

const createSubscription = (data: CreateSubscriptionPayload) => {
    return axios.post<SubscriptionModel>(apiEndpoints.subscriptions(), data);
};

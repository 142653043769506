import { PayloadEpic } from '@/store/epics/epic-types';
import { ProcureAssetLotPayload } from '@/store/slices/payload-types/warehouse-payload-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';

export const procureAssetLotEpic: PayloadEpic<ProcureAssetLotPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.procureAssetLot.match),
        switchMap(({ payload }) => concat(
            of(actions.warehouses.setIsPurchasing(true)),
            procureAssetLot(payload).pipe(
                switchMap(() => EMPTY),
                catchError((error) => {
                    console.error('procureAssetLotEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.warehouses.setIsPurchasing(false)),
        )),
    );
};

const procureAssetLot = ({ facilityUid, ...payload }: ProcureAssetLotPayload) => {
    return axios.post<unknown>(apiEndpoints.procureAssetLot(facilityUid), payload);
};

import { combineEpics } from 'redux-observable';
import { createPricingProfileEpic } from './create-pricing-profile.epic';
import { fetchAllPricingProfilesEpic } from './fetch-all-pricing-profiles.epic';
import { fetchPricingProfileByIdEpic } from './fetch-pricing-profile-by-id.epic';
import { updatePricingProfileEpic } from './update-pricing-profile.epic';
import { archivePricingProfileEpic } from './archive-pricing-profile.epic';

export const combinedPricingProfilesEpics = combineEpics(
    createPricingProfileEpic,
    fetchAllPricingProfilesEpic,
    fetchPricingProfileByIdEpic,
    updatePricingProfileEpic,
    archivePricingProfileEpic
);

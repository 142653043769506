import { viewsetDuck } from '@/shared/ducks/viewsetDuck'

export const DEFAULT_STATE = {}

export const duck = viewsetDuck('staffInvoiceAuditLog', '/mgmt/api/invoice-action-audits/')

export const useInvoiceAuditLog = uid => {
    return duck.useAPI(`?invoice__uid=${uid}`)
}

export const useOrderAuditLog = uid => {
    return duck.useAPI(`?order__uid=${uid}`)
}

export const {
    actionTypes,
    actions,
    reducer,
    useAPI
} = duck

export default duck

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { ContractModel } from '@/models';

export const fetchSelectedContractByIdEpic: PayloadEpic<EntityId, ContractModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.publicContracts.fetchSelectedContract.match),
        switchMap(({ payload }) => concat(
            of(actions.publicContracts.setIsLoadingSelectedContract(true)),
            fetchPublicContractById(payload).pipe(
                map(({ data }) => actions.publicContracts.setSelectedContract(data)),
                catchError((error) => {
                    console.error('fetchPublicContractByIdEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.publicContracts.setIsLoadingSelectedContract(false)),
        )),
    );
};

const fetchPublicContractById = (contractId: EntityId) => {
    return axios.get<ContractModel>(apiEndpoints.publicContract(contractId));
};

import axios from 'axios-observable';
import Cookies from 'js-cookie';

const configureAxios = () => {
    axios.interceptors.request.use((config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        };
    });
};

export default configureAxios;

import { combineEpics } from 'redux-observable';
import { combinedCartEpics } from './cart';
import { combinedInvoicesEpics } from './invoices';
import { combinedOrdersEpics } from './orders';
import { combinedProfileEpics } from './profile';
import { combinedContractsEpics } from './contracts';

export const combinedPublicEpics = combineEpics(
    combinedCartEpics,
    combinedInvoicesEpics,
    combinedOrdersEpics,
    combinedProfileEpics,
    combinedContractsEpics,
);

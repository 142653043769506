import React from 'react';
import { Route } from 'react-router-dom';

import { isProduction } from '@/shared/utils/env';

const GTAG_ID = 'GTM-T6LBVV5D';

export const GtmRoute = () =>
    isProduction ? (
        <Route
            path="/"
            render={({ location }) => {
                if (!window.gtag) {
                    return;
                }

                window.gtag('config', GTAG_ID, {
                    page_path: location.pathname + location.search,
                });
            }}
        />
    ) : null;

export const loadGtmScript = () => {
    if (!isProduction) {
        return;
    }

    window.dataLayer = window.dataLayer || [];

    window.gtag = function () {
        window.dataLayer.push(arguments);
    };

    const script = document.createElement('script');
    script.onload = function () {
        window.gtag('js', new Date());
    };
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);
};

export const trackConversion = (amount) => {
    if (!isProduction || !window.gtag) {
        return;
    }

    window.gtag('event', 'conversion', {
        send_to: `${GTAG_ID}/tBzBCMG6vv4CEOOApZko`,
        value: amount,
        currency: 'USD',
        transaction_id: '',
    });
};

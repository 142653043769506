import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from '@/shared/ducks/rootReducer';

import './index.css';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from '@/store/epics';
import { ModalContainer } from '@/shared/components/Modal/Modal';

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            thunk: false,
            // todo: fix this later
            immutableCheck: false,
            serializableCheck: false,
        }),
        epicMiddleware,
    ],
    // devTools: boolean, - the default is true
});

epicMiddleware.run(rootEpic);

Sentry.init({
    dsn: 'https://8472ccc91d744d12b275b66eeec88d58@o568062.ingest.sentry.io/4505035336515584',
    release: `mealsolutions-frontend@${process.env.npm_package_version}`,
    environment: process.env.NODE_ENV || 'development',
    enabled: process.env.NODE_ENV !== 'development',
    tracesSampleRate: 0.2,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render( 
    <BrowserRouter>
        <Provider store={store}>
            <ModalContainer>
                <App />
            </ModalContainer>
        </Provider>
    </BrowserRouter>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

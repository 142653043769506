import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { PricingProfileModel } from '@/models';
import { CreatePricingProfilePayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const createPricingProfileEpic: PayloadEpic<CreatePricingProfilePayload, PricingProfileModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.createPricingProfile.match),
        switchMap(({ payload }) => concat(
            of(actions.pricingProfiles.setIsSaving(true)),
            createPricingProfile(payload).pipe(
                switchMap(({ data }) => [
                    actions.pricingProfiles.addOne(data),
                    actions.pricingProfiles.setSelectedPricingProfile(data),
                ]),
                catchError((error) => {
                    console.error('createPricingProfileEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.pricingProfiles.setIsSaving(false)),
        )),
    );
};

const createPricingProfile = (data: CreatePricingProfilePayload) => {
    return axios.post<PricingProfileModel>(apiEndpoints.pricingProfiles(), data);
};

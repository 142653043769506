import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/root-state';
import { PricingProfileModel, PricingRuleModel } from '@/models';
import {
    ArchivePricingProfilePayload,
    CreatePricingProfilePayload,
    CreatePricingRulePayload,
    DeletePricingRulePayload,
    FetchPricingRulesByProfileIdPayload,
    ReorderPricingRulesPayload,
    UpdatePricingProfilePayload,
} from '@/store/slices/payload-types/pricing-profile-payload-types';

export interface FetchAllPricingProfilesPayload {
    page?: number;
    page_size?: number;
}

export interface SetPricingRulesPayload {
    pricingProfileId: EntityId;
    pricingRules: PricingRuleModel[];
    pricingRulesTotalCount: number;
}

export interface FetchPricingProfileByIdPayload {
    pricingProfileId: EntityId;
}

const pricingProfilesAdapter = createEntityAdapter<PricingProfileModel>({
    selectId: (pricingProfile) => pricingProfile.uid,
});

const pricingRulesAdapter = createEntityAdapter<PricingRuleModel>({
    selectId: (pricingRule) => pricingRule.uid,
});

const initialState = pricingProfilesAdapter.getInitialState({
    isLoading: false,
    isSaving: false,
    isSavingPricingRule: false,
    pricingRuleSavingErrors: null as Nullable<unknown>,
    totalCount: 0,
    selectedPricingProfile: null as Optional<PricingProfileModel>,
    isLoadingPricingRules: false,
    isDeletingPricingRule: false,
    isReorderingPricingRules: false,
    isArchivingPricingProfile: false,
    pricingProfileSavingErrors: null as Nullable<unknown>,
});

export type PricingProfilesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllPricingProfilesPayload>('pricingProfile/async/fetchAll');
    export const fetchPricingProfileById = createAction<FetchPricingProfileByIdPayload>(
        'pricingProfile/async/fetchPricingProfileById'
    );
    export const createPricingProfile = createAction<CreatePricingProfilePayload>(
        'pricingProfile/async/createPricingProfile'
    );
    export const updatePricingProfile = createAction<UpdatePricingProfilePayload>(
        'pricingProfile/async/updatePricingProfile'
    );

    export const archivePricingProfile = createAction<UpdatePricingProfilePayload>(
        'pricingProfile/async/archivePricingProfile'
    );
    export const fetchPricingRulesByProfileId = createAction<FetchPricingRulesByProfileIdPayload>(
        'pricingProfile/async/fetchPricingRulesByProfileId'
    );
    export const createPricingRule = createAction<CreatePricingRulePayload>('pricingProfile/async/createPricingRule');
    export const deletePricingRule = createAction<DeletePricingRulePayload>('pricingProfile/async/deletePricingRule');
    export const reorderPricingRules = createAction<ReorderPricingRulesPayload>(
        'pricingProfile/async/reorderPricingRules'
    );
}

const slice = createSlice({
    name: 'pricingProfile' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setIsSavingPricingRule: (state, action: PayloadAction<boolean>) => {
            state.isSavingPricingRule = action.payload;
        },
        setSavingPricingRuleErrors: (state, action: PayloadAction<unknown>) => {
            state.pricingRuleSavingErrors = action.payload;
        },
        setPricingRules: (state, action: PayloadAction<SetPricingRulesPayload>) => {
            const initialRulesState = pricingRulesAdapter.getInitialState();

            pricingProfilesAdapter.updateOne(state, {
                id: action.payload.pricingProfileId,
                changes: {
                    pricingRules: pricingRulesAdapter.setAll(initialRulesState, action.payload.pricingRules),
                    pricingRulesTotalCount: action.payload.pricingRulesTotalCount,
                },
            });
        },
        addPricingRule: (state, { payload }: PayloadAction<PricingRuleModel>) => {
            const pricingProfile = state.entities[payload.profile!.uid];

            pricingRulesAdapter.addOne(pricingProfile!.pricingRules!, payload);
            pricingProfile!.pricingRulesTotalCount++;
        },
        setAll: (state, action: PayloadAction<PricingProfileModel[]>) => {
            pricingProfilesAdapter.setAll(state, action.payload);
        },
        addOne: (state, action: PayloadAction<PricingProfileModel>) => {
            pricingProfilesAdapter.addOne(state, action.payload);
        },
        upsertOne: (state, action: PayloadAction<PricingProfileModel>) => {
            pricingProfilesAdapter.upsertOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setSelectedPricingProfile: (state, action: PayloadAction<Optional<PricingProfileModel>>) => {
            state.selectedPricingProfile = action.payload;
        },
        setIsLoadingPricingRules: (state, action: PayloadAction<boolean>) => {
            state.isLoadingPricingRules = action.payload;
        },
        setIsDeletingPricingRule: (state, action: PayloadAction<boolean>) => {
            state.isDeletingPricingRule = action.payload;
        },
        deletePricingRule: (state, action: PayloadAction<DeletePricingRulePayload>) => {
            const pricingProfile = state.entities[action.payload.pricingProfileId];

            pricingRulesAdapter.removeOne(pricingProfile!.pricingRules!, action.payload.pricingRuleId);
            pricingProfile!.pricingRulesTotalCount--;
        },
        setIsReorderingPricingRules: (state, action: PayloadAction<boolean>) => {
            state.isReorderingPricingRules = action.payload;
        },
        setIsArchivingPricingProfile: (state, action: PayloadAction<boolean>) => {
            state.isArchivingPricingProfile = action.payload;
        },
        archivePricingProfile: (state, action: PayloadAction<ArchivePricingProfilePayload>) => {
            pricingProfilesAdapter.removeOne(state, action.payload.uid);
        },
        setSavingPricingProfileErrors: (state, action: PayloadAction<unknown>) => {
            state.pricingProfileSavingErrors = action.payload;
        },
    },
});

const pricingProfilesAdapterSelectors = pricingProfilesAdapter.getSelectors(
    (state: RootState) => state.pricingProfiles
);
const purePricingRulesAdapterSelectors = pricingRulesAdapter.getSelectors();

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.pricingProfiles.isLoading;
    export const isSaving = (state: RootState) => state.pricingProfiles.isSaving;
    export const isSavingPricingRule = (state: RootState) => state.pricingProfiles.isSavingPricingRule;
    export const pricingRuleSavingErrors = (state: RootState) => state.pricingProfiles.pricingRuleSavingErrors;
    export const totalCount = (state: RootState) => state.pricingProfiles.totalCount;
    export const selectedPricingProfile = (state: RootState) => state.pricingProfiles.selectedPricingProfile;
    export const selectPricingRulesByProfileId = (state: RootState, pricingProfileId: EntityId) => {
        const pricingProfile = pricingProfilesAdapterSelectors.selectById(state, pricingProfileId);
        return pricingProfile?.pricingRules && purePricingRulesAdapterSelectors.selectAll(pricingProfile.pricingRules);
    };
    export const isLoadingPricingRules = (state: RootState) => state.pricingProfiles.isLoadingPricingRules;
    export const isDeletingPricingRule = (state: RootState) => state.pricingProfiles.isDeletingPricingRule;
    export const isReorderingPricingRules = (state: RootState) => state.pricingProfiles.isReorderingPricingRules;
    export const isArchivingPricingProfile = (state: RootState) => state.pricingProfiles.isArchivingPricingProfile;
    export const pricingProfileSavingErrors = (state: RootState) => state.pricingProfiles.pricingProfileSavingErrors;
}

export const pricingProfilesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...pricingProfilesAdapterSelectors,
        ...sliceSelectors,
    },
};

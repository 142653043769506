import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { AssetLotModel } from '@/models';
import {
    MakeStockAdjustmentsPayload,
    MarkAssetLotReceivedPayload,
    MoveAssetLotToWarehousePayload,
    PurchaseAssetLotPayload,
    UpdateUnitCostPayload,
} from '@/store/slices/payload-types/asset-payload-types';

export interface FetchAllAssetLotsPayload {
    page?: number;
    page_size?: number;
}

const assetLotsAdapter = createEntityAdapter<AssetLotModel>({
    selectId: (assetLot) => assetLot.uid,
});

const initialState = assetLotsAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
    isRequestingAssetInventory: false,
    isChangingUnitCost: false,
    requestAssetInventoryErrors: null as Nullable<unknown>,
});

export type AssetLotsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllAssetLotsPayload>('assetLots/async/fetchAll');
    export const purchaseAssetLot = createAction<PurchaseAssetLotPayload>('assetLots/async/purchaseAssetLot');
    export const markAssetLotReceived = createAction<MarkAssetLotReceivedPayload>(
        'assetLots/async/markAssetLotReceived'
    );
    export const makeStockAdjustments = createAction<MakeStockAdjustmentsPayload>(
        'assetLots/async/makeStockAdjustments'
    );
    export const moveToWarehouse = createAction<MoveAssetLotToWarehousePayload>('assetLots/async/moveToWarehouse');
    export const requestAssetInventory = createAction<EntityId>('assetLots/async/requestAssetInventory');
    export const changeUnitCost = createAction<UpdateUnitCostPayload>('assetLots/async/changeUnitCost');
}

const slice = createSlice({
    name: 'assetLots' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<AssetLotModel[]>) => {
            assetLotsAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<AssetLotModel>) => {
            assetLotsAdapter.addOne(state, clearRequestInventoryAction(action.payload));
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<AssetLotModel>) => {
            assetLotsAdapter.upsertOne(state, clearRequestInventoryAction(action.payload));
        },
        setIsRequestingAssetLotInventory: (state, action: PayloadAction<boolean>) => {
            state.isRequestingAssetInventory = action.payload;
        },
        setRequestAssetInventoryErrors: (state, action: PayloadAction<unknown>) => {
            state.requestAssetInventoryErrors = action.payload;
        },
        setIsChangingUnitCost: (state, action: PayloadAction<boolean>) => {
            state.isChangingUnitCost = action.payload;
        },
    },
});

const assetLotsAdapterSelectors = assetLotsAdapter.getSelectors((state: RootState) => state.assetLots);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.assetLots.isLoading;
    export const isSaving = (state: RootState) => state.assetLots.isSaving;
    export const savingErrors = (state: RootState) => state.assetLots.savingErrors;
    export const totalCount = (state: RootState) => state.assetLots.totalCount;
    export const isRequestingAssetInventory = (state: RootState) => state.assetLots.isRequestingAssetInventory;
    export const isChangingUnitCost = (state: RootState) => state.assetLots.isChangingUnitCost;
    export const requestAssetInventoryErrors = (state: RootState) => state.assetLots.requestAssetInventoryErrors;
}

const clearRequestInventoryAction = (assetLot: AssetLotModel) => {
    assetLot.available_actions = assetLot.available_actions.filter((action) => action !== 'request_inventory');

    return assetLot;
};

export const assetLotsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...assetLotsAdapterSelectors,
        ...sliceSelectors,
    },
};

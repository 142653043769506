import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { RemoveServingMenuPayload } from '@/store/slices/staff/serving-menus.slice';

export const removeServingMenuEpic: PayloadEpic<RemoveServingMenuPayload, ServingMenuModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.remove.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.servingMenus.setIsSaving(true)),
                removeServingMenu(payload).pipe(
                    switchMap(({ data }) => [actions.servingMenus.removeOne(payload.id), actions.servingMenus.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('removeServingMenuEpic', error);

                        return of(actions.servingMenus.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.servingMenus.setIsSaving(false))
            )
        )
    );
};

const removeServingMenu = (payload: RemoveServingMenuPayload) => {
    return axios.delete<ServingMenuModel>(apiEndpoints.servingMenu(payload.id));
};

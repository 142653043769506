import { viewsetDuck } from '@/shared/ducks/viewsetDuck';

export const DEFAULT_STATE = {};

export const duck = viewsetDuck('staffOrders', '/mgmt/api/orders/');

export const useUpdateShippingAPI = (uid) => {
    return duck.useAPI(`${uid}/update-shipping/`, { suspense: false });
};

export const useRemoveLineItemAPI = (uid) => {
    return duck.useAPI(`${uid}/modify/remove/`, { suspense: false });
};

export const useAddDiscountAPI = (uid) => {
    return duck.useAPI(`${uid}/discounts/`, { suspense: false });
};

export const useRemoveDiscountAPI = (uid, discount_id) => {
    return duck.useAPI(`${uid}/discounts/${discount_id}`, { suspense: false });
};

export const useAddLineItemAPI = (uid) => {
    return duck.useAPI(`${uid}/modify/add/`, { suspense: false });
};

export const useModifyQuantityOfLineItemAPI = (uid) => {
    return duck.useAPI(`${uid}/modify/change-quantity/`, { suspense: false });
};

export const useSubstituteLineItemAPI = (uid) => {
    return duck.useAPI(`${uid}/modify/substitute/`, { suspense: false });
};

export const { actionTypes, actions, reducer, useAPI } = duck;

export default duck;

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { UpdateDriverPayload } from '@/store/slices/staff/drivers.slice';

export const updateDriverEpic: PayloadEpic<UpdateDriverPayload, UserModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.drivers.updateDriver.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.drivers.setIsSaving(true)),
                updateUser(payload).pipe(
                    switchMap(({ data }) => from([actions.drivers.upsertOne(data)])),
                    catchError((error) => {
                        console.error('updatingDriverEpic', error);

                        return of(actions.drivers.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.drivers.setIsSaving(false))
            )
        )
    );
};

const updateUser = (data: UpdateDriverPayload) => {
    return axios.put<UserModel>(`${apiEndpoints.drivers()}${data.id}/`, data);
};

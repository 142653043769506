import loadingAnimation from '@/loadingAnimation.json';
import Lottie from 'react-lottie';
import React from 'react';
import {makeStyles} from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
    graphicWrapper: {
        width: '100%',
        height: '100%',
        padding: '12em',
        minHeight: '60vh',
    },
});

export const FullPageSpinner = () => {
    return (
        <Backdrop open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export const GraphicLoader = () => {
    const classes = useStyles();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className={classes.graphicWrapper}>
            <Lottie options={defaultOptions} height={250} width={250} />
        </div>
    );
};

import { combineEpics } from 'redux-observable';
import { createMenuTemplateEpic } from './create-menu-templates';
import { fetchAllMenuTemplatesEpic } from './fetch-all-menu-templates';
import { updateMenuTemplateEpic } from './update-menu-template';
import { fetchMenuTemplateByIdEpic } from './fetch-menu-template-by-id';
import { removeMenuTemplateEpic } from './remove-menu-template';


export const combinedMenuTemplatesEpics = combineEpics(
    fetchAllMenuTemplatesEpic,
    fetchMenuTemplateByIdEpic,
    createMenuTemplateEpic,
    updateMenuTemplateEpic,
    removeMenuTemplateEpic,
);

import { catchError, delay, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ChangeStopTimePayload } from '@/store/slices/staff/dispatch-routes.slice';
import lightJoin from 'light-join';
import { RouteStopModel } from '@/models';
import { fetchDispatchRoutes } from './fetch-all-dispatch-routes.epic';

export const changeStopTime: PayloadEpic<ChangeStopTimePayload, RouteStopModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.dispatchRoutes.changeStopTime.match),
        switchMap(({ payload }) =>
            concat(
                changeStopTimeOfDispatchRoute(payload).pipe(
                    // switchMap(({ data }) => from([actions.dispatchRoutes.updateStopTime({ routeStop: data, dispatchId: payload.dispatchRouteId })])),
                    switchMap(() =>
                        fetchDispatchRoutes({ warehouse: payload.warehouse, date: payload.date }).pipe(
                            switchMap(({ data }) => from([actions.dispatchRoutes.setAll(data)]))
                        )
                    ),
                    catchError((error) => {
                        console.error('changeStopTimeOfDispatchRoutes', error);

                        // SETS THE ERROR STATE AND REMOVES IT SO THAT IT CAN SET AGAIN
                        return concat(
                            of(
                                actions.dispatchRoutes.setChangeRouteStopTimeErrors(
                                    error?.response?.data?.stop_time?.stop_time
                                )
                            ),
                            EMPTY.pipe(delay(1000)),
                            of(actions.dispatchRoutes.setChangeRouteStopTimeErrors(''))
                        );
                    })
                )
            )
        )
    );
};

const changeStopTimeOfDispatchRoute = (payload: ChangeStopTimePayload) => {
    return axios.post<RouteStopModel>(
        lightJoin(`${apiEndpoints.dispatchRouteStop(payload.route_stop_id)}`, 'change-stop-time/'),
        {
            stop_time: payload.stopTime,
        }
    );
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { FetchAllWarehouseRoutesPayload } from '@/store/slices/staff/warehouse-routes.slice';
import { WarehouseRouteModel } from '@/models';

export const fetchAllWarehouseRoutes: PayloadEpic<FetchAllWarehouseRoutesPayload, WarehouseRouteModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouseRoutes.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouseRoutes.setIsLoading(true)),
                fetchWarehouseRoutes(payload).pipe(
                    switchMap(({ data }) => from([actions.warehouseRoutes.setAll(data.results), actions.warehouseRoutes.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllWarehouseRoutes', error);

                        return EMPTY;
                    })
                ),
                of(actions.warehouseRoutes.setIsLoading(false))
            )
        )
    );
};

const fetchWarehouseRoutes = (payload: FetchAllWarehouseRoutesPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<WarehouseRouteModel>>(`${apiEndpoints.warehouseRoutes()}?${searchParams}`);
};

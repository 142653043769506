import { DriverRouteModel } from '@/models';
import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FetchAllDriverRoutesPayload {
    date?: string;
}

const driverRoutesAdapter = createEntityAdapter<DriverRouteModel>({
    selectId: (driverRoute) => driverRoute.id,
});

const initialState = driverRoutesAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type DriverRoutesState = typeof initialState;

// Will change routes when API is done
namespace asyncActions {
    export const fetchAll = createAction<FetchAllDriverRoutesPayload>('driverRoutes/async/fetchAll');
}

const slice = createSlice({
    name: 'driverRoutes' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<DriverRouteModel[]>) => {
            driverRoutesAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<DriverRouteModel>) => {
            driverRoutesAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<DriverRouteModel>) => {
            driverRoutesAdapter.upsertOne(state, action.payload);
        },
    },
});

const driverRoutesAdapterSelectors = driverRoutesAdapter.getSelectors((state: RootState) => state.driverRoutes);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.driverRoutes.isLoading;
    export const isSaving = (state: RootState) => state.driverRoutes.isSaving;
    export const savingErrors = (state: RootState) => state.driverRoutes.savingErrors;
    export const totalCount = (state: RootState) => state.driverRoutes.totalCount;
}

export const driverRoutesSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...driverRoutesAdapterSelectors,
        ...sliceSelectors,
    },
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { RouteStopModel } from '@/models';
import { UpdateRouteStopPayload } from '@/store/slices/staff/route-stops.slice';

export const updateRouteStopEpic: PayloadEpic<UpdateRouteStopPayload, RouteStopModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.routeStops.update.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.routeStops.setIsSaving(true)),
                updateRouteStop(payload).pipe(
                    switchMap(({ data }) => from([actions.routeStops.upsertOne(data)])),
                    catchError((error) => {
                        console.error('updatingRouteStopEpic', error);

                        return of(actions.routeStops.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.routeStops.setIsSaving(false))
            )
        )
    );
};

const updateRouteStop = (data: UpdateRouteStopPayload) => {
    return axios.put<RouteStopModel>(apiEndpoints.routeStop(data.id), data);
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { PricingRuleModel } from '@/models';
import { FetchPricingProfileByIdPayload } from '@/store/slices/staff/pricing-profiles.slice';
import { FetchPricingRulesByProfileIdPayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const fetchAllPricingRulesByProfileIdEpic: PayloadEpic<FetchPricingProfileByIdPayload> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.fetchPricingRulesByProfileId.match),
        switchMap(({ payload }) => concat(
            of(actions.pricingProfiles.setIsLoadingPricingRules(true)),
            fetchAllPricingRulesByProfileId(payload).pipe(
                map(({ data }) => actions.pricingProfiles.setPricingRules({
                    pricingProfileId: payload.pricingProfileId,
                    pricingRules: data.results,
                    pricingRulesTotalCount: data.count,
                })),
                catchError((error) => {
                    console.error('fetchAllPricingRulesEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.pricingProfiles.setIsLoadingPricingRules(false)),
        )),
    );
};

const fetchAllPricingRulesByProfileId = (data: FetchPricingRulesByProfileIdPayload) => {
    const params = objectToUrlSearchParams({
        profile__uid: data.pricingProfileId,
    });

    return axios.get<ListResponse<PricingRuleModel>>(`${apiEndpoints.pricingRules()}?${params}`);
};

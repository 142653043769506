import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, Draft, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { AuditLogModel } from '@/models';
import {
    FetchAllAuditLogsPayload,
    FetchAndSetAllAuditLogsPayload,
    UpsertManyAuditLogsPayload,
} from '@/store/slices/payload-types/audit-log-payload-types';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

const auditLogsAdapter = createEntityAdapter<AuditLogModel>({
    selectId: (auditLog) => auditLog.id,
});

const initialState = auditLogsAdapter.getInitialState({
    filterLogs: {} as Record<string, Optional<EntityId[]>>,
    isLoading: false,
    totalCount: 0,
});

export type AuditLogsState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllAuditLogsPayload>('auditLog/async/fetchAll');
    export const fetchAndSetAll = createAction<FetchAndSetAllAuditLogsPayload>('auditLog/async/fetchAndSetAll');
}

const slice = createSlice({
    name: 'auditLogs' as const,
    initialState,
    reducers: {
        upsertMany: (state, { payload }: PayloadAction<UpsertManyAuditLogsPayload>) => {
            auditLogsAdapter.upsertMany(state, payload.auditLogs);
            updateFilterLogs(state, payload.auditLogs, payload.filter);
            state.totalCount = payload.totalCount;
        },
        setAll: (state, { payload }: PayloadAction<AuditLogModel[]>) => {
            auditLogsAdapter.setAll(state, payload);
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
    },
});

const auditLogAdapterSelectors = auditLogsAdapter.getSelectors((state: RootState) => state.auditLogs);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.auditLogs.isLoading;
    export const totalCount = (state: RootState) => state.auditLogs.totalCount;
    export const selectLogsByFilter = (state: RootState, filter: object) => {
        const filterStr = getFilterStr(filter);
        const ids = state.auditLogs.filterLogs[filterStr] ?? [];

        return ids.map((id) => auditLogAdapterSelectors.selectById(state, id)!);
    };
}

export const auditLogsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...auditLogAdapterSelectors,
        ...sliceSelectors,
    },
};

const updateFilterLogs = (state: Draft<AuditLogsState>, auditLogs: AuditLogModel[], filter: object) => {
    const filterStr = getFilterStr(filter);
    state.filterLogs[filterStr] = auditLogs.map((log) => log.id);
};

const getFilterStr = (filter: object) => {
    const filterParams = objectToUrlSearchParams(filter);
    filterParams.sort();

    return filterParams.toString();
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ContractModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';
import { FetchContractByIdPayload } from '@/store/slices/staff/contracts.slice';

export const fetchContractByIdEpic: PayloadEpic<FetchContractByIdPayload, ContractModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.fetchContractById.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsLoading(true)),
            fetchContractById(payload.contractId).pipe(
                map(({ data }) => actions.contracts.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchContractByIdEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoading(false)),
        )),
    );
};

const fetchContractById = (contractId: EntityId) => {
    return axios.get<ContractModel>(apiEndpoints.contract(contractId));
};

import { identity } from './common';

export type MapFn = (value: any) => any;
export type CompareFn = (first: any, second: any) => boolean;

export const getCompareFnForMapping = (mapValue: MapFn): CompareFn => {
    return (first: any, second: any) => Object.is(mapValue(first), mapValue(second));
};

export const strictCompareFn = getCompareFnForMapping(identity);
export const compareStringValuesFn = getCompareFnForMapping(String);

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ContractModel } from '@/models';
import { FetchAllContractsPayload } from '@/store/slices/public/contracts.slice';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllContractsEpic: PayloadEpic<FetchAllContractsPayload, ContractModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.publicContracts.fetchAll.match),
        switchMap(({ payload }) => concat(
            of(actions.publicContracts.setIsLoading(true)),
            fetchContracts(payload).pipe(
                map(({ data }) => actions.publicContracts.setAll(data.results)),
                catchError((error) => {
                    console.error('fetchAllContractsEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.publicContracts.setIsLoading(false)),
        )),
    );
};

const fetchContracts = (payload: FetchAllContractsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<ContractModel>>(`${apiEndpoints.publicContracts()}?${searchParams}`);
};

import { combineEpics } from 'redux-observable';
import { fetchAssetByIdEpic } from '@/store/epics/staff/assets/component/fetch-asset-by-id.epic';
import { createComponentAssetEpic } from '@/store/epics/staff/assets/component/create-component-asset.epic';
import { patchAssetEpic } from '@/store/epics/staff/assets/component/patch-asset.epic';
import { createMealAssetEpic } from '@/store/epics/staff/assets/component/create-meal-asset.epic';
import { createPackageAssetEpic } from '@/store/epics/staff/assets/component/create-package-asset.epic';

export const combinedInventoryComponentEpics = combineEpics(
    fetchAssetByIdEpic,
    createComponentAssetEpic,
    createMealAssetEpic,
    createPackageAssetEpic,
    patchAssetEpic,
);

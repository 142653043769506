import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { MenuTemplateModel } from '@/models/menu-template.model';
import { UpdateMenuTemplatePayload } from '@/store/slices/staff/menu-template.slice';

export const updateMenuTemplateEpic: PayloadEpic<UpdateMenuTemplatePayload, MenuTemplateModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.menuTemplates.update.match),
        switchMap(({ payload }) => concat(
            of(actions.menuTemplates.setIsSaving(true)),
            updateMenuTemplate(payload).pipe(
                switchMap(({ data }) => from([
                    actions.menuTemplates.upsertOne(data),
                ])),
                catchError((error) => {
                    console.error('updatingMenuTemplateEpic', error);

                    return of(actions.menuTemplates.setSavingErrors(error.response.data));
                }),
            ),
            of(actions.menuTemplates.setIsSaving(false)),
        )),
    );
};

const updateMenuTemplate = (data: UpdateMenuTemplatePayload) => {
    return axios.put<MenuTemplateModel>(apiEndpoints.menuTemplate(data.id), data);
};

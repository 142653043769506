import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { WarehouseModel } from '@/models';

export const fetchFacilitiesEpic: PayloadEpic<void, WarehouseModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.fetchFacilities.match),
        switchMap(({ payload }) => concat(
            of(actions.contracts.setIsLoading(true)),
            fetchFacilities().pipe(
                map(({ data }) => actions.contracts.setFacilities(data.results)),
                catchError((error) => {
                    console.error('fetchFacilitiesEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.contracts.setIsLoading(false)),
        )),
    );
};

const fetchFacilities = () => {
    return axios.get<ListResponse<WarehouseModel>>(apiEndpoints.facilities());
};

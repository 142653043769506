import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ContractModel } from '@/models';
import { objectToFormData } from '@/shared/utils/objectToFormData';
import { UpdateContractPayload } from '@/store/slices/payload-types/UpdateContractPayload';

export const updateSalesContractEpic: PayloadEpic<UpdateContractPayload, ContractModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.contracts.updateSalesContract.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.contracts.setIsUpdatingContract(true)),
                updateSalesContract(payload).pipe(
                    switchMap(({ data }) =>
                        from([
                            actions.contracts.upsertOne(data),
                            actions.contracts.setSelectedContract(data),
                            actions.contracts.setSaveContractErrors(null),
                        ])
                    ),
                    catchError((error) => {
                        console.error('updatingSalesContractEpic', error);

                        return of(actions.contracts.setSaveContractErrors(error));
                    })
                ),
                of(actions.contracts.setIsUpdatingContract(false))
            )
        )
    );
};

const updateSalesContract = (data: UpdateContractPayload) => {
    if (typeof data.deal_pdf === 'string') {
        delete data.deal_pdf;
    }
    return axios.put<ContractModel>(apiEndpoints.contract(data.uid), objectToFormData(data));
};

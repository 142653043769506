import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { VendorModel } from '@/models';
import { FetchVendorsPayload } from '@/store/slices/payload-types/asset-payload-types';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';

export const fetchAllVendorsEpic: PayloadEpic<FetchVendorsPayload, VendorModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.vendors.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.vendors.setIsLoading(true)),
                fetchVendors(payload).pipe(
                    switchMap(({ data }) => of(actions.vendors.setAll(data.results))),
                    catchError((error) => {
                        console.error('fetchAllVendorsEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.vendors.setIsLoading(false))
            )
        )
    );
};

const fetchVendors = (payload: FetchVendorsPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<VendorModel>>(`${apiEndpoints.inventoryVendors()}?${searchParams}`);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { DeliverySiteModel } from '@/models';
import { UpdateDeliverySitePayload } from '@/store/slices/staff/delivery-sites.slice';

export const updateDeliverySiteEpic: PayloadEpic<UpdateDeliverySitePayload, DeliverySiteModel[]> = (
    action$
) => {
    return action$.pipe(
        filter(asyncActions.deliverySites.update.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.deliverySites.setIsSaving(true)),
                createDeliverySite(payload).pipe(
                    switchMap(({ data }) =>
                        from([
                            actions.deliverySites.upsertOne(data),
                            actions.deliverySites.setSavingErrors(null),
                        ])
                    ),
                    catchError((error) => {
                        console.error('updateDeliverySiteEpic', error);

                        return of(actions.deliverySites.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.deliverySites.setIsSaving(false))
            )
        )
    );
};

const createDeliverySite = (data: UpdateDeliverySitePayload) => {
    console.log('updating delivery site', data);
    return axios.put<DeliverySiteModel>(apiEndpoints.deliverySite(data.id), data);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { UpdateUserPayload } from '@/store/slices/staff/users.slice';

export const updateUserEpic: PayloadEpic<UpdateUserPayload, UserModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.users.updateUser.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.users.setIsSaving(true)),
                updateUser(payload).pipe(
                    switchMap(({ data }) => from([actions.users.upsertOne(data)])),
                    catchError((error) => {
                        console.error('updatingUserEpic', error);

                        return of(actions.users.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.users.setIsSaving(false))
            )
        )
    );
};

const updateUser = (data: UpdateUserPayload) => {
    return axios.put<UserModel>(`${apiEndpoints.users()}${data.id}/`, data);
};

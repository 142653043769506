import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { PublicInvoiceModel } from '@/models';
import { UpdateInvoiceContractPayload } from '@/store/slices/staff/invoices.slice';

export const updateInvoiceContractEpic: PayloadEpic<UpdateInvoiceContractPayload, PublicInvoiceModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.staffInvoices.updateContract.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.staffInvoices.setIsSaving(true)),
                updateInvoiceContract(payload).pipe(
                    switchMap(({ data }) => from([actions.staffInvoices.setSavingErrors(null)])),
                    catchError((error) => {
                        console.error('createDeliverySiteEpic', error);

                        return of(actions.staffInvoices.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.staffInvoices.setIsSaving(false))
            )
        )
    );
};

const updateInvoiceContract = (data: UpdateInvoiceContractPayload) => {
    return axios.post<PublicInvoiceModel>(apiEndpoints.staffInvoiceChangeContract(data.invoice), data);
};

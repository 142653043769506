import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { RouteStopModel } from '@/models/route-stop.model';

export interface FetchAllRouteStopsByRoutePayload {
    page?: number;
    page_size?: number;
    route__id: EntityId;
}

export interface CreateRouteStopPayload {
    archived_at: Date | null;
    stop_time: string;
    meal_time: string;
    delivery_window_start: string;
    delivery_window_end: string;
    route: EntityId;
    site: EntityId;
}

export interface UpdateRouteStopPayload extends CreateRouteStopPayload {
    id: RouteStopModel['id'];
}

export interface DeleteRouteStopPayload {
    routeStopId: EntityId;
}

export interface FetchRouteStopByIdPayload {
    routeStopId: EntityId;
}

const routeStopsAdapter = createEntityAdapter<RouteStopModel>({
    selectId: (routeStop) => routeStop.id,
});

const initialState = routeStopsAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
});

export type RouteStopsState = typeof initialState;

// Will change routes when API is done
namespace asyncActions {
    export const fetchAll = createAction<FetchAllRouteStopsByRoutePayload>('routeStops/async/fetchAll');
    export const create = createAction<CreateRouteStopPayload>('routeStops/async/create');
    export const update = createAction<UpdateRouteStopPayload>('routeStops/async/update');
    export const deleteRouteStop = createAction<DeleteRouteStopPayload>('routeStops/async/delete');
    export const fetchById = createAction<FetchRouteStopByIdPayload>('routeStops/async/fetchById');
}

const slice = createSlice({
    name: 'routeStops' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setAll: (state, action: PayloadAction<RouteStopModel[]>) => {
            routeStopsAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        removeRouteStop: (state, action: PayloadAction<EntityId>) => {
            const id = action.payload;
            console.log('ID FOR DELETE');

            routeStopsAdapter.removeOne(state, id);
            state.totalCount--;
        },
        addOne: (state, action: PayloadAction<RouteStopModel>) => {
            routeStopsAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<RouteStopModel>) => {
            routeStopsAdapter.upsertOne(state, action.payload);
        },
    },
});

const routeStopsAdapterSelectors = routeStopsAdapter.getSelectors((state: RootState) => state.routeStops);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.routeStops.isLoading;
    export const isSaving = (state: RootState) => state.routeStops.isSaving;
    export const savingErrors = (state: RootState) => state.routeStops.savingErrors;
    export const totalCount = (state: RootState) => state.routeStops.totalCount;
}

export const routeStopsSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...routeStopsAdapterSelectors,
        ...sliceSelectors,
    },
};

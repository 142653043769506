import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/root-state';
import { InventoryAllergenModel } from '@/models';
import { FetchInventoryAllergensPayload } from '@/store/slices/payload-types/asset-payload-types';

const inventoryAllergensAdapter = createEntityAdapter<InventoryAllergenModel>({
    selectId: (allergen) => allergen.id,
});

const initialState = inventoryAllergensAdapter.getInitialState({
    isLoading: false,
});

export type InventoryAllergensState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchInventoryAllergensPayload>('inventoryAllergens/async/fetchAll');
}

const slice = createSlice({
    name: 'inventoryAllergens' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAll: (state, action: PayloadAction<InventoryAllergenModel[]>) => {
            inventoryAllergensAdapter.setAll(state, action.payload);
        },
    },
});

const inventoryAllergensAdapterSelectors = inventoryAllergensAdapter.getSelectors((state: RootState) => state.inventoryAllergens);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.inventoryAllergens.isLoading;
}

export const inventoryAllergensSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...inventoryAllergensAdapterSelectors,
        ...sliceSelectors,
    },
};

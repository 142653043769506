import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { PricingProfileModel } from '@/models';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { FetchAllPricingProfilesPayload } from '@/store/slices/staff/pricing-profiles.slice';

export const fetchAllPricingProfilesEpic: PayloadEpic<void, PricingProfileModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.pricingProfiles.setIsLoading(true)),
                fetchPricingProfiles(payload).pipe(
                    switchMap(({ data }) => from([actions.pricingProfiles.setAll(data.results), actions.pricingProfiles.setTotalCount(data.count)])),
                    catchError((error) => {
                        console.error('fetchAllPricingProfilesEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.pricingProfiles.setIsLoading(false))
            )
        )
    );
};

const fetchPricingProfiles = (payload: FetchAllPricingProfilesPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<ListResponse<PricingProfileModel>>(`${apiEndpoints.pricingProfiles()}?${searchParams}`);
};

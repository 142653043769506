import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { RefreshMenuCompliancePayload } from '@/store/slices/staff/serving-menus.slice';

export const refreshMenuComplianceEpic: PayloadEpic<RefreshMenuCompliancePayload, ServingMenuModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.refreshCompliance.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.servingMenus.setIsRefreshingCompliance(payload.id)),
                refreshMenuCompliance(payload).pipe(
                    switchMap(({ data }) => from([actions.servingMenus.upsertOne(data)])),
                    catchError((error) => {
                        console.error('refreshingMenuComplianceEpic', error);
                        return of(actions.servingMenus.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.servingMenus.setIsRefreshingCompliance(null))
            )
        )
    );
};

const refreshMenuCompliance = (data: RefreshMenuCompliancePayload) => {
    return axios.post<ServingMenuModel>(apiEndpoints.servingMenuCompliance(data.id), data);
};

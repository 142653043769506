import { profileSlice } from './public/profile.slice';
import { usersSlice } from '@/store/slices/staff/users.slice';
import { contractsSlice } from '@/store/slices/staff/contracts.slice';
import { publicContractsSlice } from '@/store/slices/public/contracts.slice';
import { pricingProfilesSlice } from '@/store/slices/staff/pricing-profiles.slice';
import { publicInvoicesSlice } from '@/store/slices/public/invoices.slice';
import { staffInvoicesSlice } from '@/store/slices/staff/invoices.slice';
import { productsSlice } from '@/store/slices/staff/products.slice';
import { assetsSlice } from '@/store/slices/staff/assets.slice';
import { cartsSlice } from '@/store/slices/public/carts.slice';
import { ordersSlice } from '@/store/slices/public/orders.slice';
import { vendorsSlice } from '@/store/slices/staff/vendors.slice';
import { inventoryAllergensSlice } from '@/store/slices/staff/inventory-allergens.slice';
import { assetLotsSlice } from '@/store/slices/staff/asset-lots.slice';
import { mealSlotsSlice } from '@/store/slices/staff/meal-slots.slice';
import { warehouseSlice } from '@/store/slices/staff/warehouses.slice';
import { auditLogsSlice } from '@/store/slices/staff/audit-log.slice';
import { lotAdjustmentSlice } from '@/store/slices/staff/lot-adjustment.slice';
import { servingMenusSlice } from '@/store/slices/staff/serving-menus.slice';
import { deliverySitesSlice } from '@/store/slices/staff/delivery-sites.slice';
import { warehouseRoutesSlice } from '@/store/slices/staff/warehouse-routes.slice';
import { routeStopsSlice } from './staff/route-stops.slice';
import { dispatchRoutesSlice } from './staff/dispatch-routes.slice';
import { driverRoutesSlice } from './driver/driver-routes.slice';
import { manufacturersSlice } from './staff/manufacturers.slice';
import { driversSlice } from './staff/drivers.slice';
import { slimAssetsSlice } from './staff/slim-assets.slice';
import { menuTemplatesSlice } from './staff/menu-template.slice';

export const actions = {
    profile: profileSlice.actions,
    carts: cartsSlice.actions,
    users: usersSlice.actions,
    drivers: driversSlice.actions,
    contracts: contractsSlice.actions,
    publicContracts: publicContractsSlice.actions,
    pricingProfiles: pricingProfilesSlice.actions,
    publicInvoices: publicInvoicesSlice.actions,
    staffInvoices: staffInvoicesSlice.actions,
    products: productsSlice.actions,
    assets: assetsSlice.actions,
    slimAssets: slimAssetsSlice.actions,
    orders: ordersSlice.actions,
    vendors: vendorsSlice.actions,
    manufacturers: manufacturersSlice.actions,
    inventoryAllergens: inventoryAllergensSlice.actions,
    assetLots: assetLotsSlice.actions,
    mealSlots: mealSlotsSlice.actions,
    warehouses: warehouseSlice.actions,
    auditLogs: auditLogsSlice.actions,
    lotAdjustments: lotAdjustmentSlice.actions,
    servingMenus: servingMenusSlice.actions,
    menuTemplates: menuTemplatesSlice.actions,
    warehouseRoutes: warehouseRoutesSlice.actions,
    dispatchRoutes: dispatchRoutesSlice.actions,
    routeStops: routeStopsSlice.actions,
    deliverySites: deliverySitesSlice.actions,
    driverRoutes: driverRoutesSlice.actions,
} as const;

export const asyncActions = {
    profile: profileSlice.asyncActions,
    carts: cartsSlice.asyncActions,
    users: usersSlice.asyncActions,
    drivers: driversSlice.asyncActions,
    contracts: contractsSlice.asyncActions,
    publicContracts: publicContractsSlice.asyncActions,
    pricingProfiles: pricingProfilesSlice.asyncActions,
    publicInvoices: publicInvoicesSlice.asyncActions,
    staffInvoices: staffInvoicesSlice.asyncActions,
    products: productsSlice.asyncActions,
    assets: assetsSlice.asyncActions,
    slimAssets: slimAssetsSlice.asyncActions,
    orders: ordersSlice.asyncActions,
    vendors: vendorsSlice.asyncActions,
    manufacturers: manufacturersSlice.asyncActions,
    inventoryAllergens: inventoryAllergensSlice.asyncActions,
    assetLots: assetLotsSlice.asyncActions,
    mealSlots: mealSlotsSlice.asyncActions,
    warehouses: warehouseSlice.asyncActions,
    auditLogs: auditLogsSlice.asyncActions,
    lotAdjustments: lotAdjustmentSlice.asyncActions,
    servingMenus: servingMenusSlice.asyncActions,
    menuTemplates: menuTemplatesSlice.asyncActions,
    warehouseRoutes: warehouseRoutesSlice.asyncActions,
    dispatchRoutes: dispatchRoutesSlice.asyncActions,
    routeStops: routeStopsSlice.asyncActions,
    deliverySites: deliverySitesSlice.asyncActions,
    driverRoutes: driverRoutesSlice.asyncActions,
} as const;

export const selectors = {
    profile: profileSlice.selectors,
    carts: cartsSlice.selectors,
    users: usersSlice.selectors,
    drivers: driversSlice.selectors,
    contracts: contractsSlice.selectors,
    publicContracts: publicContractsSlice.selectors,
    pricingProfiles: pricingProfilesSlice.selectors,
    publicInvoices: publicInvoicesSlice.selectors,
    staffInvoices: staffInvoicesSlice.selectors,
    products: productsSlice.selectors,
    orders: ordersSlice.selectors,
    assets: assetsSlice.selectors,
    slimAssets: slimAssetsSlice.selectors,
    vendors: vendorsSlice.selectors,
    manufacturers: manufacturersSlice.selectors,
    inventoryAllergens: inventoryAllergensSlice.selectors,
    assetLots: assetLotsSlice.selectors,
    mealSlots: mealSlotsSlice.selectors,
    warehouses: warehouseSlice.selectors,
    auditLogs: auditLogsSlice.selectors,
    lotAdjustments: lotAdjustmentSlice.selectors,
    servingMenus: servingMenusSlice.selectors,
    menuTemplates: menuTemplatesSlice.selectors,
    warehouseRoutes: warehouseRoutesSlice.selectors,
    dispatchRoutes: dispatchRoutesSlice.selectors,
    routeStops: routeStopsSlice.selectors,
    deliverySites: deliverySitesSlice.selectors,
    driverRoutes: driverRoutesSlice.selectors,
} as const;

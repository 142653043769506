import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { UserModel } from '@/models';
import { EntityId } from '@reduxjs/toolkit';
import { FetchDriverByIdPayload } from '@/store/slices/staff/drivers.slice';

export const fetchDriverByIdEpic: PayloadEpic<FetchDriverByIdPayload, UserModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.drivers.fetchById.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.drivers.setIsLoading(true)),
                fetchDriverById(payload.driverId).pipe(
                    map(({ data }) => actions.drivers.upsertOne(data)),
                    catchError((error) => {
                        console.error('fetchDriverByIdEpic', error);
                        return EMPTY;
                    })
                ),
                of(actions.drivers.setIsLoading(false))
            )
        )
    );
};

const fetchDriverById = (driverId: EntityId) => {
    return axios.get<UserModel>(apiEndpoints.driver(driverId));
};

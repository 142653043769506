import { combineEpics } from 'redux-observable';
import { createPricingRuleEpic } from './create-pricing-rule.epic';
import { fetchAllPricingRulesByProfileIdEpic } from './fetch-all-pricing-rules-by-profile-id.epic';
import { reorderPricingRulesEpic } from './reorder-pricing-rules.epic';
import { deletePricingRuleEpic } from '@/store/epics/staff/pricing-rules/delete-pricing-rule.epic';

export const combinedPricingRulesEpics = combineEpics(
    createPricingRuleEpic,
    fetchAllPricingRulesByProfileIdEpic,
    reorderPricingRulesEpic,
    deletePricingRuleEpic,
);

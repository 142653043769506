import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { AssetModel } from '@/models';
import { CreateMealAssetPayload } from '@/store/slices/payload-types/asset-payload-types';

export const createMealAssetEpic: PayloadEpic<CreateMealAssetPayload, AssetModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.assets.createMeal.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.assets.setIsCreating(true)),
                createMeal(payload).pipe(
                    switchMap(({ data }) => [actions.assets.addOne(data), actions.assets.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createMealAssetEpic', error);

                        return of(actions.assets.setSavingErrors(error?.response?.data || {}));
                    })
                ),
                of(actions.assets.setIsCreating(false))
            )
        )
    );
};

const createMeal = (payload) => {
    return axios.post<AssetModel>(apiEndpoints.mealAssets(), payload);
};

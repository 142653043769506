import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { ServingMenuModel } from '@/models';

export interface FetchAllServingMenusPayload {
    page?: number;
    page_size?: number;
    meal_slot_id?: EntityId;
    meal_slot__warehouse__uid?: EntityId;
    organizations__uid?: Nullable<EntityId>;
    date_before?: string;
    date_after?: string;
    meal_slot__id?: EntityId;
}

export interface CreateServingMenuPayload {
    serving_date: string | Date;
    meal_slot: number;
    meal_assets: Array<any>;
    override_packing_tote: string;
    override_qty_per_tote: string;
}

export interface RemoveServingMenuPayload {
    id: EntityId;
}

export interface RefreshMenuCompliancePayload {
    id: EntityId;
}

export interface UpdateServingMenuPayload extends CreateServingMenuPayload {
    id: ServingMenuModel['id'];
}

export interface FetchServingMenuByIdPayload {
    menuId: EntityId;
}

const servingMenusAdapter = createEntityAdapter<ServingMenuModel>({
    selectId: (servingMenu) => servingMenu.id,
});

const initialState = servingMenusAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSaving: false,
    savingErrors: null as Nullable<unknown>,
    complianceRefreshingMenuId: null as Nullable<EntityId>,
});

export type ServingMenusState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllServingMenusPayload>('servingMenus/async/fetchAll');
    export const create = createAction<CreateServingMenuPayload>('servingMenus/async/create');
    export const update = createAction<UpdateServingMenuPayload>('servingMenus/async/update');
    export const fetchById = createAction<FetchServingMenuByIdPayload>('servingMenus/async/fetchById');
    export const remove = createAction<RemoveServingMenuPayload>('servingMenus/async/remove');
    export const refreshCompliance = createAction<RefreshMenuCompliancePayload>('servingMenus/async/refreshCompliance');
}

const slice = createSlice({
    name: 'servingMenus' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setIsRefreshingCompliance: (state, action: PayloadAction<EntityId | null>) => {
            state.complianceRefreshingMenuId = action.payload;
        },
        setAll: (state, action: PayloadAction<ServingMenuModel[]>) => {
            servingMenusAdapter.setAll(state, action.payload);
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        addOne: (state, action: PayloadAction<ServingMenuModel>) => {
            servingMenusAdapter.addOne(state, action.payload);
            state.totalCount++;
        },
        upsertOne: (state, action: PayloadAction<ServingMenuModel>) => {
            servingMenusAdapter.upsertOne(state, action.payload);
        },
        removeOne: (state, action: PayloadAction<EntityId>) => {
            servingMenusAdapter.removeOne(state, action.payload);
        },
    },
});

const servingMenusAdapterSelectors = servingMenusAdapter.getSelectors((state: RootState) => state.servingMenus);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.servingMenus.isLoading;
    export const isSaving = (state: RootState) => state.servingMenus.isSaving;
    export const savingErrors = (state: RootState) => state.servingMenus.savingErrors;
    export const totalCount = (state: RootState) => state.servingMenus.totalCount;
    export const complianceRefreshingMenuId = (state: RootState) => state.servingMenus.complianceRefreshingMenuId;
}

export const servingMenusSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...servingMenusAdapterSelectors,
        ...sliceSelectors,
    },
};

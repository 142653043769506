import duckBuilder from '@/shared/ducks/duck.js'
import { useFetch } from 'use-http'
import { useDispatch } from 'react-redux'
import deepmerge from 'deepmerge'
import { useSnackbar } from 'notistack'

export const demo_state = () => ({
    page: 3,
    email: 'demo-registration@foodforgoodmealsolutions.com',
    first_name: 'TestDan',
    last_name: 'TheTestGuy',
    organization: {
        title: 'VeryGoodTest Company',
        phone: '+18009998888',
        website_url: 'https://thecompanysite.com',
        type: 'distributor',
        estimated_size: 'small',
        billing_address: {
            line_one: '111 NoCompany Way',
            line_two: 'Suite 111',
            city: 'Notacity',
            state: 'DC',
            zip_code: '11111',
        },
        shipping_addresses: [
            {
                contact_name: 'TestDan',
                contact_phone: '+17775557777',
                line_one: '222 NoCompany Way',
                line_two: 'Apartment 222',
                city: 'Notacity',
                state: 'DC',
                zip_code: '22222',
            },
        ],
    },

    password: 'ffg4U123',
    password_confirm: 'ffg4U123',
    phone: '+18003334444',
})

const getPageForErrors = (errors) => {
    if (
        errors?.organization?.title ||
        errors?.organization?.phone ||
        errors?.organization?.website_url ||
        errors?.organization?.distributor
    ) {
        return 0
    }

    if (errors?.billing_address) {
        return 1
    }

    return errors?.shipping_addresses ? 2 : 3
}

export const DEFAULT_STATE = {
    email: '',
    first_name: '',
    last_name: '',
    organization: {
        title: '',
        phone: '',
        website_url: '',
        type: 'npo',
        estimated_size: '',
        billing_address: {
            line_one: '',
            line_two: '',
            city: '',
            state: '',
            zip_code: '',
        },
        shipping_addresses: [
            {
                contact_name: '',
                contact_phone: '',
                line_one: '',
                line_two: '',
                city: '',
                state: '',
                zip_code: '',
            },
        ],
    },

    password: '',
    password_confirm: '',
    phone: '',
}

export const duck = duckBuilder(
    // Namespace, don't forget to register with combineReducers in index.ts
    'registration',
    {
        SUBMITTING: (state) => ({ ...state, _submitting: true }),
        DONE: (state) => ({ ...state, _submitting: false }),
        SET_DATA: (state, action) => {
            const newData = deepmerge(state, action.data)
            // deepmerge will append shipping addresses instead of updating them
            // so we only want the latest shipping address
            const addrs = newData.organization?.shipping_addresses || []
            if (addrs.length > 1)
                newData.organization.shipping_addresses = [
                    addrs[addrs.length - 1],
                ]
            return newData
        },
        SET_ERRORS: (state, action) => {
            console.log({ state, action })
            const page = getPageForErrors(action.data)
            return { ...state, page, errors: action.data }
        },
        RESET: () => DEFAULT_STATE,
    },
    DEFAULT_STATE,
)

export function useRegistration() {
    const fetch = useFetch('/api/accounts/register/')
    const dispatch = useDispatch()
    const formData = duck.useDuckSelector()
    const login = useFetch('/api/accounts/login/')
    const snackbar = useSnackbar()

    const post = async (addData) => {
        dispatch(duck.actions.SUBMITTING())
        const data = deepmerge(formData, addData)
        const errors = await fetch.post(data)
        console.log(fetch.response)
        if (fetch.response.ok && (fetch.response.status === 200 || fetch.response.status === 201)) {
            await login.post({ login: data.email, password: data.password })
            dispatch(duck.actions.DONE())
            dispatch(duck.actions.RESET())
            /*
            // TODO:
            // someday we should investigate why the following is insufficient to log a user in:
            // i assume it's related to the sessionid cookie (loaded in App.js)
            
            await profile.get();
            history.push('/products')
            */
            // For now, we will use window.location to force a full page reload after login
            window.location = '/products'

        } else {
            dispatch(duck.actions.SET_ERRORS(errors))
            dispatch(duck.actions.DONE())

            snackbar.enqueueSnackbar('An error occurred! If you need help, contact support!')
        }
    }
    return { post, fetch }
}

duck.useRegistration = useRegistration

export const { actionTypes, actions, reducer } = duck

export default duck

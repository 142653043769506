import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { PricingProfileModel } from '@/models';
import { UpdatePricingProfilePayload } from '@/store/slices/payload-types/pricing-profile-payload-types';

export const updatePricingProfileEpic: PayloadEpic<UpdatePricingProfilePayload, PricingProfileModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.pricingProfiles.updatePricingProfile.match),
        switchMap(({ payload }) => concat(
            of(actions.pricingProfiles.setIsSaving(true)),
            updatePricingProfileRequest(payload).pipe(
                switchMap(({ data }) => [
                    actions.pricingProfiles.upsertOne(data),
                    actions.pricingProfiles.setSelectedPricingProfile(data),
                ]),
                catchError((error) => {
                    console.error('updatePricingProfileEpic', error);

                    return EMPTY;
                }),
            ),
            of(actions.pricingProfiles.setIsSaving(false)),
        )),
    );
};

const updatePricingProfileRequest = (data: UpdatePricingProfilePayload) => {
    return axios.put<PricingProfileModel>(apiEndpoints.pricingProfile(data.uid), data);
};

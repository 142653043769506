import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { objectToUrlSearchParams } from '@/utils/url-search-params-utils';
import { DriverRouteModel } from '@/models';
import { FetchAllDriverRoutesPayload } from '@/store/slices/driver/driver-routes.slice';

export const fetchAllDriverRoutesEpic: PayloadEpic<FetchAllDriverRoutesPayload, unknown> = (action$) => {
    return action$.pipe(
        filter(asyncActions.driverRoutes.fetchAll.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.driverRoutes.setIsLoading(true)),
                fetchDriverRoutes(payload).pipe(
                    switchMap(({ data }) => from([actions.driverRoutes.setAll(data), actions.driverRoutes.setTotalCount(data?.length || 0)])),
                    catchError((error) => {
                        console.error('fetchAllDriverRoutesEpic', error);

                        return EMPTY;
                    })
                ),
                of(actions.driverRoutes.setIsLoading(false))
            )
        )
    );
};

export const fetchDriverRoutes = (payload: FetchAllDriverRoutesPayload) => {
    const searchParams = objectToUrlSearchParams(payload);

    return axios.get<DriverRouteModel[]>(`${apiEndpoints.driverRoutes()}?${searchParams}`);
};

import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { CreateRouteStopPayload } from '@/store/slices/staff/route-stops.slice';
import { RouteStopModel } from '@/models';

export const createRouteStopEpic: PayloadEpic<CreateRouteStopPayload, RouteStopModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.routeStops.create.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.routeStops.setIsSaving(true)),
                createRouteStop(payload).pipe(
                    switchMap(({ data }) => [actions.routeStops.addOne(data), actions.routeStops.setSavingErrors(null)]),
                    catchError((error) => {
                        console.error('createRouteStopEpic', error);

                        return of(actions.routeStops.setSavingErrors(error.response.data));
                    })
                ),
                of(actions.routeStops.setIsSaving(false))
            )
        )
    );
};

const createRouteStop = (payload: CreateRouteStopPayload) => {
    return axios.post<RouteStopModel>(apiEndpoints.routeStops(), payload);
};

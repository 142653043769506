import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { EntityId } from '@reduxjs/toolkit';
import { OrderModel } from '@/models';

export const fetchOrderByIdEpic: PayloadEpic<EntityId, OrderModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.orders.fetchOrderById.match),
        switchMap(({ payload }) => concat(
            of(actions.orders.setIsLoadingOrderById(true)),
            fetchOrderById(payload).pipe(
                map(({ data }) => actions.orders.upsertOne(data)),
                catchError((error) => {
                    console.error('fetchOrdersEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.orders.setIsLoadingOrderById(false)),
        )),
    );
};

const fetchOrderById = (orderId: EntityId) => {
    return axios.get<OrderModel>(`${apiEndpoints.orderById(orderId)}`);
};

import { RootState } from '@/store/root-state';
import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssemblyPlanResponseModel, FetchAssetAssemblyPlanError, WarehouseModel } from '@/models';
import {
    AssembleAssetPayload,
    CreateWarehousePayload,
    EditWarehousePayload,
    FetchAllWarehousesPayload,
    FetchAssetAssemblyPlanPayload,
    FetchWarehouseByIdPayload,
    ProcureAssetLotPayload,
} from '@/store/slices/payload-types/warehouse-payload-types';

const warehousesAdapter = createEntityAdapter<WarehouseModel>({
    selectId: (warehouse) => warehouse.uid,
});

type PlanWithDetails = AssemblyPlanResponseModel & Record<'planInfo', FetchAssetAssemblyPlanPayload>;

const initialState = warehousesAdapter.getInitialState({
    isLoading: false,
    isSaving: false,
    isPurchasing: false,
    isFetchingAssetAssemblyPlan: false,
    fetchAssetAssemblyPlanErrors: null as Optional<FetchAssetAssemblyPlanError>,
    totalCount: 0,
    assemblyPlan: null as Optional<PlanWithDetails>,
    savingErrors: null as Nullable<unknown>,
});

export type WarehousesState = typeof initialState;

namespace asyncActions {
    export const fetchAll = createAction<FetchAllWarehousesPayload>('warehouses/async/fetchAll');
    export const fetchById = createAction<FetchWarehouseByIdPayload>('warehouses/async/fetchById');
    export const create = createAction<CreateWarehousePayload>('warehouses/async/create');
    export const edit = createAction<EditWarehousePayload>('warehouses/async/edit');
    export const fetchAssetAssemblyPlan = createAction<FetchAssetAssemblyPlanPayload>('warehouses/async/fetchAssetAssemblyPlan');
    export const assembleAsset = createAction<AssembleAssetPayload>('warehouses/async/assembleAsset');
    export const procureAssetLot = createAction<ProcureAssetLotPayload>('warehouses/async/procureAssetLot');
}

const slice = createSlice({
    name: 'warehouses' as const,
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setIsPurchasing: (state, action: PayloadAction<boolean>) => {
            state.isPurchasing = action.payload;
        },
        setIsFetchingAssetAssemblyPlan: (state, action: PayloadAction<boolean>) => {
            state.isFetchingAssetAssemblyPlan = action.payload;
        },
        setAll: (state, action: PayloadAction<WarehouseModel[]>) => {
            warehousesAdapter.setAll(state, action.payload);
        },
        addOne: (state, action: PayloadAction<WarehouseModel>) => {
            warehousesAdapter.addOne(state, action.payload);
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setFetchAssetAssemblyPlanErrors: (state, action: PayloadAction<Nullable<FetchAssetAssemblyPlanError>>) => {
            state.fetchAssetAssemblyPlanErrors = action.payload;
        },
        setAssemblyPlan: (state, action: PayloadAction<Optional<PlanWithDetails>>) => {
            state.assemblyPlan = action.payload;
        },
        setSavingErrors: (state, action: PayloadAction<unknown>) => {
            state.savingErrors = action.payload;
        },
        upsertOne: (state, action: PayloadAction<WarehouseModel>) => {
            warehousesAdapter.upsertOne(state, action.payload);
        },
    },
});

const warehousesAdapterSelectors = warehousesAdapter.getSelectors((state: RootState) => state.warehouses);

namespace sliceSelectors {
    export const isLoading = (state: RootState) => state.warehouses.isLoading;
    export const isPurchasing = (state: RootState) => state.warehouses.isPurchasing;
    export const isSaving = (state: RootState) => state.warehouses.isSaving;
    export const isFetchingAssetAssemblyPlan = (state: RootState) => state.warehouses.isFetchingAssetAssemblyPlan;
    export const fetchAssetAssemblyPlanErrors = (state: RootState) => state.warehouses.fetchAssetAssemblyPlanErrors;
    export const totalCount = (state: RootState) => state.warehouses.totalCount;
    export const assemblyPlan = (state: RootState) => state.warehouses.assemblyPlan;
    export const savingErrors = (state: RootState) => state.warehouses.savingErrors;
}

export const warehouseSlice = {
    ...slice,
    asyncActions,
    selectors: {
        ...warehousesAdapterSelectors,
        ...sliceSelectors,
    },
};

import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { WarehouseModel } from '@/models';
import { FetchWarehouseByIdPayload } from '@/store/slices/payload-types/warehouse-payload-types';

export const fetchWarehouseByIdEpic: PayloadEpic<FetchWarehouseByIdPayload, WarehouseModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.warehouses.fetchById.match),
        switchMap(({ payload }) =>
            concat(
                of(actions.warehouses.setIsLoading(true)),
                fetchWarehouseById(payload.uid).pipe(
                    map(({ data }) => actions.warehouses.upsertOne(data)),
                    catchError((error) => {
                        console.error('fetchWarehouseByIdEpic', error);
                        return EMPTY;
                    })
                ),
                of(actions.warehouses.setIsLoading(false))
            )
        )
    );
};

const fetchWarehouseById = (warehouseUid: string) => {
    return axios.get<WarehouseModel>(apiEndpoints.warehouse(warehouseUid));
};

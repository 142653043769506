import { PayloadEpic } from '@/store/epics/epic-types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { actions, asyncActions } from '@/store/slices';
import { concat, of } from 'rxjs';
import axios from 'axios-observable';
import { apiEndpoints } from '@/store/constants';
import { MenuTemplateModel } from '@/models/menu-template.model';
import { CreateMenuTemplatePayload } from '@/store/slices/staff/menu-template.slice';

export const createMenuTemplateEpic: PayloadEpic<CreateMenuTemplatePayload, MenuTemplateModel> = (action$) => {
    return action$.pipe(
        filter(asyncActions.menuTemplates.create.match),
        switchMap(({ payload }) => concat(
            of(actions.menuTemplates.setIsSaving(true)),
            createMenuTemplate(payload).pipe(
                switchMap(({ data }) => [
                    actions.menuTemplates.addOne(data),
                    actions.menuTemplates.setSavingErrors(null),
                ]),
                catchError((error) => {
                    console.error('createMenuTemplateEpic', error);

                    return of(actions.menuTemplates.setSavingErrors(error.response.data));
                }),
            ),
            of(actions.menuTemplates.setIsSaving(false)),
        )),
    );
};

const createMenuTemplate = (payload: CreateMenuTemplatePayload) => {
    return axios.post<MenuTemplateModel>(apiEndpoints.menuTemplates(), payload);
};

import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { CartMetaInfoModel, CartModel, CartOrderModel } from '@/models';
import { PostAddToCartPayload } from '@/store/slices/public/carts.slice';
import { withSelector } from '@/utils/rxjs-utils';

export type PostAddToCartEpicOutput = CartOrderModel[] | CartMetaInfoModel | number;

export const postAddToCartEpic: PayloadEpic<PostAddToCartPayload, PostAddToCartEpicOutput> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.postAddToCart.match),
        switchMap((action) => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
            of(action),
        ])),
        switchMap(([selectedOrgUnit, selectedContract, { payload }]) => concat(
            of(actions.carts.setIsLoading(true)),
            addToCart(selectedOrgUnit, selectedContract, payload).pipe(
                switchMap(({ data }) => from([
                    actions.carts.setCurrentCartOrders(data.orders),
                    actions.carts.setCurrentCartMetaInfo(data),
                    actions.carts.setCheckoutPage(0),
                ])),
                catchError((error) => {
                    console.error('postAddToCartEpic', error);
                    return EMPTY;
                }),
            ),
            of(actions.carts.setIsLoading(false)),
        )),
    );
};

const addToCart = (selectedOrgUnit, selectedContract, payload) => {
    return axios.post<CartModel>(
        apiEndpoints.addToCart(selectedOrgUnit!.uid),
        { products: [payload] },
        { params: { sales_contract: selectedContract?.uid } },
    );
};
